import React, { Component, Fragment } from 'react'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// images 
import post1 from '../../images/newsupdate-thumbnail.png'
import flag from '../../images/flag.svg'
import indiaFlag from '../../images/f-india.png'
import compare from '../../images/compare-div.svg'
import trending from '../../images/trending-thumbnail.png'
import recent from '../../images/recent-svg.svg'
import ball from '../../images/ball.png'
import post2 from '../../images/post-img2.png'
import post3 from '../../images/post-img3.png'
import thumb from '../../images/post-thubmnail.png'

class LiveScores extends Component {
    render() {

        return (
            <Fragment>
                <div class="news-content-area fx-padding">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-lg-9">
                                <div class="news-main-content">
                                    <div class="news-widget">

                                        <div class="row">
                                            <div class="col-lg-12">
                                                <div class="live-sc-top-blk">
                                                    <h2>Ball by Ball Live Cricket Scores</h2>
                                                    <p>Here you can get the live scores of the every international cricket match as well as domestic cricket
                                                    matches including Bangladesh domestic matches. bdcrictime.com brought to you live cricket score
                                            updates with commentary.</p>
                                                    <p>You can get live streaming link to watch the match live here as well. For your any suggestion or
                                            inquiry you are feel free to contact with us. Contact email: contact@bdcricteam.com.</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-lg-12">
                                                <div class="single-fixture-blk">
                                                    <div class="fx-top-blk">
                                                        <h4>Ban<span>gladesh</span> VS Ind<span>ia</span></h4>
                                                        <div class="fx-top-right-info">
                                                            <span>1st ODI</span>
                                                            <span>6-DEC-2019</span>
                                                            <span>4:00 PM</span>
                                                        </div>
                                                    </div>
                                                    <div class="fx-inner-part">
                                                        <div class="fx-inner-top-info">
                                                            <span>Live</span>
                                                        </div>
                                                        <div class="fx-inner-main-part">
                                                            <div class="single-team-info">
                                                                <img class="fx-flag-img" src={flag} alt="" />
                                                                <h3><img src={ball} alt="" /> ban<span>gladesh</span></h3>
                                                                <p>300 / 5 /<br /> <span class="over-texts">(50 OVERS)</span></p>
                                                            </div>
                                                            <div class="vs">
                                                                <h3>-VS-</h3>
                                                            </div>
                                                            <div class="single-team-info rt-blk">
                                                                <img class="fx-flag-img" src={indiaFlag} alt="" />
                                                                <h3>IND<span>ia</span><img src={ball} alt="" /> </h3>
                                                                <p>290 / 5 /<br /> <span class="over-texts">(50 OVERS)</span></p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <p class="para-des">India needs 11 more runs to win from 8 balls with 5 wickets in hand</p>
                                                    <div class="fx-bottom-blk">
                                                        <a href="">View match details</a>
                                                    </div>
                                                </div>
                                                <div class="single-fixture-blk">
                                                    <div class="fx-top-blk">
                                                        <h4>Bangladesh VS India</h4>
                                                        <div class="fx-top-right-info">
                                                            <span>1st ODI</span>
                                                            <span>6-DEC-2019</span>
                                                            <span>4:00 PM</span>
                                                        </div>
                                                    </div>
                                                    <div class="fx-inner-part">
                                                        <div class="fx-inner-top-info up-c">
                                                            <span>Upcoming</span>
                                                        </div>
                                                        <div class="fx-inner-main-part">
                                                            <div class="single-team-info">
                                                                <img class="fx-flag-img" src={flag} alt="" />
                                                                <h3><img src={ball} alt="" /> ban<span>gladesh</span></h3>
                                                                <p>300 / 5 /<br /> (50 OVERS)</p>
                                                            </div>
                                                            <div class="vs">
                                                                <h3>-VS-</h3>
                                                            </div>
                                                            <div class="single-team-info rt-blk">
                                                                <img class="fx-flag-img" src={indiaFlag} alt="" />
                                                                <h3>IND<span>ia</span><img src={ball} alt="" /> </h3>
                                                                <p>290 / 5 /<br /> (48.4 OVERS)</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <p class="para-des">India needs 11 more runs to win from 8 balls with 5 wickets in hand</p>
                                                    <div class="fx-bottom-blk">
                                                        <a href="">View match details</a>
                                                    </div>
                                                </div>
                                                <div class="single-fixture-blk">
                                                    <div class="fx-top-blk">
                                                        <h4>Bangladesh VS India</h4>
                                                        <div class="fx-top-right-info">
                                                            <span>1st ODI</span>
                                                            <span>6-DEC-2019</span>
                                                            <span>4:00 PM</span>
                                                        </div>
                                                    </div>
                                                    <div class="fx-inner-part">
                                                        <div class="fx-inner-top-info up-c">
                                                            <span>Upcoming</span>
                                                        </div>
                                                        <div class="fx-inner-main-part">
                                                            <div class="single-team-info">
                                                                <img class="fx-flag-img" src={flag} alt="" />
                                                                <h3><img src={ball} alt="" /> ban<span>gladesh</span></h3>
                                                                <p>300 / 5 /<br /> (50 OVERS)</p>
                                                            </div>
                                                            <div class="vs">
                                                                <h3>-VS-</h3>
                                                            </div>
                                                            <div class="single-team-info rt-blk">
                                                                <img class="fx-flag-img" src={indiaFlag} alt="" />
                                                                <h3>IND<span>ia</span><img src={ball} alt="" /> </h3>
                                                                <p>290 / 5 /<br /> (48.4 OVERS)</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <p class="para-des">India needs 11 more runs to win from 8 balls with 5 wickets in hand</p>
                                                    <div class="fx-bottom-blk">
                                                        <a href="">View match details</a>
                                                    </div>
                                                </div>
                                                <div class="single-fixture-blk">
                                                    <div class="fx-top-blk">
                                                        <h4>Bangladesh VS India</h4>
                                                        <div class="fx-top-right-info">
                                                            <span>1st ODI</span>
                                                            <span>6-DEC-2019</span>
                                                            <span>4:00 PM</span>
                                                        </div>
                                                    </div>
                                                    <div class="fx-inner-part">
                                                        <div class="fx-inner-top-info up-c">
                                                            <span>Upcoming</span>
                                                        </div>
                                                        <div class="fx-inner-main-part">
                                                            <div class="single-team-info">
                                                                <img class="fx-flag-img" src={flag} alt="" />
                                                                <h3><img src={ball} alt="" /> ban<span>gladesh</span></h3>
                                                                <p>300 / 5 /<br /> (50 OVERS)</p>
                                                            </div>
                                                            <div class="vs">
                                                                <h3>-VS-</h3>
                                                            </div>
                                                            <div class="single-team-info rt-blk">
                                                                <img class="fx-flag-img" src={indiaFlag} alt="" />
                                                                <h3>IND<span>ia</span><img src={ball} alt="" /> </h3>
                                                                <p>290 / 5 /<br /> (48.4 OVERS)</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <p class="para-des">India needs 11 more runs to win from 8 balls with 5 wickets in hand</p>
                                                    <div class="fx-bottom-blk">
                                                        <a href="">View match details</a>
                                                    </div>
                                                </div>
                                                <div class="single-fixture-blk">
                                                    <div class="fx-top-blk">
                                                        <h4>Bangladesh VS India</h4>
                                                        <div class="fx-top-right-info">
                                                            <span>1st ODI</span>
                                                            <span>6-DEC-2019</span>
                                                            <span>4:00 PM</span>
                                                        </div>
                                                    </div>
                                                    <div class="fx-inner-part">
                                                        <div class="fx-inner-top-info up-c">
                                                            <span>Upcoming</span>
                                                        </div>
                                                        <div class="fx-inner-main-part">
                                                            <div class="single-team-info">
                                                                <img class="fx-flag-img" src={flag} alt="" />
                                                                <h3><img src={ball} alt="" /> ban<span>gladesh</span></h3>
                                                                <p>300 / 5 /<br /> (50 OVERS)</p>
                                                            </div>
                                                            <div class="vs">
                                                                <h3>-VS-</h3>
                                                            </div>
                                                            <div class="single-team-info rt-blk">
                                                                <img class="fx-flag-img" src={indiaFlag} alt="" />
                                                                <h3>IND<span>ia</span><img src={ball} alt="" /> </h3>
                                                                <p>290 / 5 /<br /> (48.4 OVERS)</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <p class="para-des">India needs 11 more runs to win from 8 balls with 5 wickets in hand</p>
                                                    <div class="fx-bottom-blk">
                                                        <a href="">View match details</a>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>

                                    </div>







                                </div>
                            </div>
                            <div class="col-lg-3">
                                <div class="sidebar-widget-wrapper">
                                    <div class="sidebar-widget trend-widget">
                                        <h4 class="heading-title">Latest News</h4>

                                        <nav>
                                            <ul>
                                                <li>
                                                    <div class="img"><a href="#"><img src={trending} alt="image" /></a></div>
                                                    <div class="content"><a href="#">
                                                        <p>Lorem Ipsum is simply dummy text to print ...</p>
                                                    </a></div>
                                                </li>
                                                <li>
                                                    <div class="img"><a href="#"><img src={trending} alt="image" /></a></div>
                                                    <div class="content"><a href="#">
                                                        <p>Lorem Ipsum is simply dummy text to print ...</p>
                                                    </a></div>
                                                </li>
                                                <li>
                                                    <div class="img"><a href="#"><img src={trending} alt="image" /></a></div>
                                                    <div class="content"><a href="#">
                                                        <p>Lorem Ipsum is simply dummy text to print ...</p>
                                                    </a></div>
                                                </li>
                                                <li>
                                                    <div class="img"><a href="#"><img src={trending} alt="image" /></a></div>
                                                    <div class="content"><a href="#">
                                                        <p>Lorem Ipsum is simply dummy text to print ...</p>
                                                    </a></div>
                                                </li>
                                                <li>
                                                    <div class="img"><a href="#"><img src={trending} alt="image" /></a></div>
                                                    <div class="content"><a href="#">
                                                        <p>Lorem Ipsum is simply dummy text to print ...</p>
                                                    </a></div>
                                                </li>
                                            </ul>
                                        </nav>
                                        <a href="" class="ld-more-btn">Load More</a>
                                    </div>

                                    <div class="sidebar-widget popular-widget">
                                        <h4 class="heading-title">Most Popular</h4>

                                        <div>
                                            <div class="nav nav-tabs" id="nav-tab" role="tablist">
                                                <a class="nav-item nav-link active" data-toggle="tab" href="#nav-7">Today</a>
                                                <a class="nav-item nav-link" data-toggle="tab" href="#nav-8">Last 7 Days</a>
                                                <a class="nav-item nav-link" data-toggle="tab" href="#nav-9">Last 30 Days</a>
                                            </div>
                                        </div>
                                        <div class="tab-content" id="nav-tabContent">
                                            <div class="tab-pane fade show active" id="nav-7">
                                                <nav>
                                                    <ul>
                                                        <li>
                                                            <span>1</span>
                                                            <a href="#">
                                                                <p>Lorem Ipsum is simply dummy ..</p>
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <span>2</span>
                                                            <a href="#">
                                                                <p>Lorem int and typesetting int and typesetting int and typesetting int and typesetting...</p>
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <span>3</span>
                                                            <a href="#">
                                                                <p>Lorem int and typesetting int and typesetting int and typesetting int and typesetting...</p>
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <span>4</span>
                                                            <a href="#">
                                                                <p>Lorem int and typesetting int and typesetting int and typesetting int and typesetting...</p>
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <span>5</span>
                                                            <a href="#">
                                                                <p>Lorem int and typesetting int and typesetting int and typesetting int and typesetting...</p>
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </nav>

                                            </div>
                                            <div class="tab-pane fade" id="nav-8">

                                            </div>
                                            <div class="tab-pane fade" id="nav-9">


                                            </div>
                                        </div>



                                    </div>
                                    <div class="sidebar-widget rank-widget">

                                        <h4 class="heading-title">ICC TEAM RANKING</h4>

                                        <div>
                                            <div class="nav nav-tabs" id="nav-tab" role="tablist">
                                                <a class="nav-item nav-link active" data-toggle="tab" href="#nav-4">ODI</a>
                                                <a class="nav-item nav-link" data-toggle="tab" href="#nav-5">T20</a>
                                                <a class="nav-item nav-link" data-toggle="tab" href="#nav-6">TEST</a>
                                            </div>
                                        </div>
                                        <div class="tab-content" id="nav-tabContent">
                                            <div class="tab-pane fade show active" id="nav-4">
                                                <nav>
                                                    <ul>
                                                        <li>
                                                            <ol>
                                                                <li>
                                                                    <p>Pos</p>
                                                                </li>
                                                                <li>
                                                                    <p>Team</p>
                                                                </li>
                                                                <li>
                                                                    <p>Rating</p>
                                                                </li>
                                                            </ol>

                                                        </li>
                                                        <li>
                                                            <ol>
                                                                <li>
                                                                    <p>1</p>
                                                                </li>
                                                                <li>
                                                                    <p>Pakistan</p>
                                                                </li>
                                                                <li>
                                                                    <p>270</p>
                                                                </li>
                                                            </ol>

                                                        </li>
                                                        <li>
                                                            <ol>
                                                                <li>
                                                                    <p>1</p>
                                                                </li>
                                                                <li>
                                                                    <p>Pakistan</p>
                                                                </li>
                                                                <li>
                                                                    <p>270</p>
                                                                </li>
                                                            </ol>

                                                        </li>
                                                        <li>
                                                            <ol>
                                                                <li>
                                                                    <p>1</p>
                                                                </li>
                                                                <li>
                                                                    <p>Pakistan</p>
                                                                </li>
                                                                <li>
                                                                    <p>270</p>
                                                                </li>
                                                            </ol>

                                                        </li>
                                                        <li>
                                                            <ol>
                                                                <li>
                                                                    <p>1</p>
                                                                </li>
                                                                <li>
                                                                    <p>Pakistan</p>
                                                                </li>
                                                                <li>
                                                                    <p>270</p>
                                                                </li>
                                                            </ol>

                                                        </li>
                                                        <li>
                                                            <ol>
                                                                <li>
                                                                    <p>1</p>
                                                                </li>
                                                                <li>
                                                                    <p>Pakistan</p>
                                                                </li>
                                                                <li>
                                                                    <p>270</p>
                                                                </li>
                                                            </ol>

                                                        </li>
                                                        <li>
                                                            <ol>
                                                                <li>
                                                                    <p>1</p>
                                                                </li>
                                                                <li>
                                                                    <p>Pakistan</p>
                                                                </li>
                                                                <li>
                                                                    <p>270</p>
                                                                </li>
                                                            </ol>

                                                        </li>
                                                        <li>
                                                            <ol>
                                                                <li>
                                                                    <p>1</p>
                                                                </li>
                                                                <li>
                                                                    <p>Pakistan</p>
                                                                </li>
                                                                <li>
                                                                    <p>270</p>
                                                                </li>
                                                            </ol>

                                                        </li>
                                                        <li><a href="#">watch full ranking</a></li>
                                                    </ul>
                                                </nav>

                                            </div>
                                            <div class="tab-pane fade" id="nav-5">

                                            </div>
                                            <div class="tab-pane fade" id="nav-6">


                                            </div>
                                        </div>







                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}
export default LiveScores