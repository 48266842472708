import React, { Component, Fragment } from 'react'
import { Link } from 'react-router-dom'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// images 
import flag from '../../images/flag.svg'
import player from '../../images/player-img-m.png'
import bg from '../../images/team-header-banner.png'
import logo from '../../images/ev-logo.png'
import post2 from '../../images/post-img2.png'
import facebook from '../../images/facebook.svg'
import dribble from '../../images/dribble.svg'
import twitter from '../../images/twitter.svg'
import insta from '../../images/insta.svg'
import post1 from '../../images/newsupdate-thumbnail.png'
import tiger from '../../images/tiger-img.svg'
import ball from '../../images/ball-main.svg'
import bet from '../../images/bat-mian.svg'

class TeamPlayer extends Component {
    render() {

        return (
            <Fragment>

                <div class="inner-page-hero-area  fx-padding">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-lg-12">
                                <div class="inner-page-hero-content" style={{ background: `url(${bg})no-repeat center center / cover` }}>
                                    <div class="hero-page-inner-top-texts">
                                        <div class="left-hr-flag">
                                            <img src={flag} alt="" />
                                        </div>
                                        <div class="hr-rt-dets">
                                            <h2>Bangladesh</h2>
                                            <div class="social-link-list">
                                                <Link to="/"><img src={dribble} alt="" /></Link>
                                                <Link to="/"><img src={facebook} alt="" /></Link>
                                                <Link to="/"><img src={twitter} alt="" /></Link>
                                                <Link to="/"><img src={insta} alt="" /></Link>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="bottom-buttons-list">
                                        <Link to="/">TEST <span>9</span></Link>
                                        <Link to="/">TEST <span>9</span></Link>
                                        <Link to="/">TEST <span>9</span></Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="news-content-area fx-padding side-blk">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-lg-9">
                                <div class="page-inner-content">
                                    <div class="team-menu">
                                        <ul>
                                            <li><a href="">News</a></li>
                                            <li><a href="team-fixture.html">Fixture</a></li>
                                            <li><a href="">Result</a></li>
                                            <li><a class="active-menu" href="">Players</a></li>
                                            <li><a href="">Gallery</a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-9">



                                <div class="news-main-content">



                                    <div class="news-widget">

                                        <div class="row">
                                            <div class="col-lg-12">
                                                <div class="player-search-bar">
                                                    <input type="text" placeholder="Search Player..." />
                                                    <button><i class="fa fa-search"></i></button>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-lg-3 col-md-4 col-sm-6">
                                                <div class="single-player-blk">
                                                    <div class="palyer-blk-top-blk">
                                                        <div class="main-player-img">
                                                            <img src={player} alt="" />
                                                        </div>
                                                        <span><img src={ball} alt="" /></span>
                                                        <span><img src={bet} alt="" /></span>
                                                    </div>
                                                    <div class="player-all-info">
                                                        <div class="player-title">
                                                            <h3>Shakib Al Hasan</h3>
                                                            <p>Batting: LHB | Bowling: LB</p>
                                                        </div>
                                                        <div class="palyber-main-info">
                                                            <table class="table">
                                                                <tbody>
                                                                    <tr>
                                                                        <td>Innings:</td>
                                                                        <td class="text-right">250</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Runs:</td>
                                                                        <td class="text-right">25000</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Wickets:</td>
                                                                        <td class="text-right">2000</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Strike Rate:</td>
                                                                        <td class="text-right">300</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Econ. Rate:</td>
                                                                        <td class="text-right">300</td>
                                                                    </tr>

                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-3  col-md-4 col-sm-6">
                                                <div class="single-player-blk">
                                                    <div class="palyer-blk-top-blk">
                                                        <div class="main-player-img">
                                                            <img src={player} alt="" />
                                                        </div>
                                                        <span><img src={ball} alt="" /></span>
                                                        <span><img src={bet} alt="" /></span>
                                                    </div>
                                                    <div class="player-all-info">
                                                        <div class="player-title">
                                                            <h3>Shakib Al Hasan</h3>
                                                            <p>Batting: LHB | Bowling: LB</p>
                                                        </div>
                                                        <div class="palyber-main-info">
                                                            <table class="table">
                                                                <tbody>
                                                                    <tr>
                                                                        <td>Innings:</td>
                                                                        <td class="text-right">250</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Runs:</td>
                                                                        <td class="text-right">25000</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Wickets:</td>
                                                                        <td class="text-right">2000</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Strike Rate:</td>
                                                                        <td class="text-right">300</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Econ. Rate:</td>
                                                                        <td class="text-right">300</td>
                                                                    </tr>

                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-3 col-md-4 col-sm-6">
                                                <div class="single-player-blk">
                                                    <div class="palyer-blk-top-blk">
                                                        <div class="main-player-img">
                                                            <img src={player} alt="" />
                                                        </div>
                                                        <span><img src={ball} alt="" /></span>
                                                        <span><img src={bet} alt="" /></span>
                                                    </div>
                                                    <div class="player-all-info">
                                                        <div class="player-title">
                                                            <h3>Shakib Al Hasan</h3>
                                                            <p>Batting: LHB | Bowling: LB</p>
                                                        </div>
                                                        <div class="palyber-main-info">
                                                            <table class="table">
                                                                <tbody>
                                                                    <tr>
                                                                        <td>Innings:</td>
                                                                        <td class="text-right">250</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Runs:</td>
                                                                        <td class="text-right">25000</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Wickets:</td>
                                                                        <td class="text-right">2000</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Strike Rate:</td>
                                                                        <td class="text-right">300</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Econ. Rate:</td>
                                                                        <td class="text-right">300</td>
                                                                    </tr>

                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-3  col-md-4 col-sm-6">
                                                <div class="single-player-blk">
                                                    <div class="palyer-blk-top-blk">
                                                        <div class="main-player-img">
                                                            <img src={player} alt="" />
                                                        </div>
                                                        <span><img src={ball} alt="" /></span>
                                                        <span><img src={bet} alt="" /></span>
                                                    </div>
                                                    <div class="player-all-info">
                                                        <div class="player-title">
                                                            <h3>Shakib Al Hasan</h3>
                                                            <p>Batting: LHB | Bowling: LB</p>
                                                        </div>
                                                        <div class="palyber-main-info">
                                                            <table class="table">
                                                                <tbody>
                                                                    <tr>
                                                                        <td>Innings:</td>
                                                                        <td class="text-right">250</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Runs:</td>
                                                                        <td class="text-right">25000</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Wickets:</td>
                                                                        <td class="text-right">2000</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Strike Rate:</td>
                                                                        <td class="text-right">300</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Econ. Rate:</td>
                                                                        <td class="text-right">300</td>
                                                                    </tr>

                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-3  col-md-4 col-sm-6">
                                                <div class="single-player-blk">
                                                    <div class="palyer-blk-top-blk">
                                                        <div class="main-player-img">
                                                            <img src={player} alt="" />
                                                        </div>
                                                        <span><img src={ball} alt="" /></span>
                                                        <span><img src={bet} alt="" /></span>
                                                    </div>
                                                    <div class="player-all-info">
                                                        <div class="player-title">
                                                            <h3>Shakib Al Hasan</h3>
                                                            <p>Batting: LHB | Bowling: LB</p>
                                                        </div>
                                                        <div class="palyber-main-info">
                                                            <table class="table">
                                                                <tbody>
                                                                    <tr>
                                                                        <td>Innings:</td>
                                                                        <td class="text-right">250</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Runs:</td>
                                                                        <td class="text-right">25000</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Wickets:</td>
                                                                        <td class="text-right">2000</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Strike Rate:</td>
                                                                        <td class="text-right">300</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Econ. Rate:</td>
                                                                        <td class="text-right">300</td>
                                                                    </tr>

                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-3  col-md-4 col-sm-6">
                                                <div class="single-player-blk">
                                                    <div class="palyer-blk-top-blk">
                                                        <div class="main-player-img">
                                                            <img src={player} alt="" />
                                                        </div>
                                                        <span><img src={ball} alt="" /></span>
                                                        <span><img src={bet} alt="" /></span>
                                                    </div>
                                                    <div class="player-all-info">
                                                        <div class="player-title">
                                                            <h3>Shakib Al Hasan</h3>
                                                            <p>Batting: LHB | Bowling: LB</p>
                                                        </div>
                                                        <div class="palyber-main-info">
                                                            <table class="table">
                                                                <tbody>
                                                                    <tr>
                                                                        <td>Innings:</td>
                                                                        <td class="text-right">250</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Runs:</td>
                                                                        <td class="text-right">25000</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Wickets:</td>
                                                                        <td class="text-right">2000</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Strike Rate:</td>
                                                                        <td class="text-right">300</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Econ. Rate:</td>
                                                                        <td class="text-right">300</td>
                                                                    </tr>

                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-3  col-md-4 col-sm-6">
                                                <div class="single-player-blk">
                                                    <div class="palyer-blk-top-blk">
                                                        <div class="main-player-img">
                                                            <img src={player} alt="" />
                                                        </div>
                                                        <span><img src={ball} alt="" /></span>
                                                        <span><img src={bet} alt="" /></span>
                                                    </div>
                                                    <div class="player-all-info">
                                                        <div class="player-title">
                                                            <h3>Shakib Al Hasan</h3>
                                                            <p>Batting: LHB | Bowling: LB</p>
                                                        </div>
                                                        <div class="palyber-main-info">
                                                            <table class="table">
                                                                <tbody>
                                                                    <tr>
                                                                        <td>Innings:</td>
                                                                        <td class="text-right">250</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Runs:</td>
                                                                        <td class="text-right">25000</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Wickets:</td>
                                                                        <td class="text-right">2000</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Strike Rate:</td>
                                                                        <td class="text-right">300</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Econ. Rate:</td>
                                                                        <td class="text-right">300</td>
                                                                    </tr>

                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-3  col-md-4 col-sm-6">
                                                <div class="single-player-blk">
                                                    <div class="palyer-blk-top-blk">
                                                        <div class="main-player-img">
                                                            <img src={player} alt="" />
                                                        </div>
                                                        <span><img src={ball} alt="" /></span>
                                                        <span><img src={bet} alt="" /></span>
                                                    </div>
                                                    <div class="player-all-info">
                                                        <div class="player-title">
                                                            <h3>Shakib Al Hasan</h3>
                                                            <p>Batting: LHB | Bowling: LB</p>
                                                        </div>
                                                        <div class="palyber-main-info">
                                                            <table class="table">
                                                                <tbody>
                                                                    <tr>
                                                                        <td>Innings:</td>
                                                                        <td class="text-right">250</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Runs:</td>
                                                                        <td class="text-right">25000</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Wickets:</td>
                                                                        <td class="text-right">2000</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Strike Rate:</td>
                                                                        <td class="text-right">300</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Econ. Rate:</td>
                                                                        <td class="text-right">300</td>
                                                                    </tr>

                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-3  col-md-4 col-sm-6">
                                                <div class="single-player-blk">
                                                    <div class="palyer-blk-top-blk">
                                                        <div class="main-player-img">
                                                            <img src={player} alt="" />
                                                        </div>
                                                        <span><img src={ball} alt="" /></span>
                                                        <span><img src={bet} alt="" /></span>
                                                    </div>
                                                    <div class="player-all-info">
                                                        <div class="player-title">
                                                            <h3>Shakib Al Hasan</h3>
                                                            <p>Batting: LHB | Bowling: LB</p>
                                                        </div>
                                                        <div class="palyber-main-info">
                                                            <table class="table">
                                                                <tbody>
                                                                    <tr>
                                                                        <td>Innings:</td>
                                                                        <td class="text-right">250</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Runs:</td>
                                                                        <td class="text-right">25000</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Wickets:</td>
                                                                        <td class="text-right">2000</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Strike Rate:</td>
                                                                        <td class="text-right">300</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Econ. Rate:</td>
                                                                        <td class="text-right">300</td>
                                                                    </tr>

                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-3  col-md-4 col-sm-6">
                                                <div class="single-player-blk">
                                                    <div class="palyer-blk-top-blk">
                                                        <div class="main-player-img">
                                                            <img src={player} alt="" />
                                                        </div>
                                                        <span><img src={ball} alt="" /></span>
                                                        <span><img src={bet} alt="" /></span>
                                                    </div>
                                                    <div class="player-all-info">
                                                        <div class="player-title">
                                                            <h3>Shakib Al Hasan</h3>
                                                            <p>Batting: LHB | Bowling: LB</p>
                                                        </div>
                                                        <div class="palyber-main-info">
                                                            <table class="table">
                                                                <tbody>
                                                                    <tr>
                                                                        <td>Innings:</td>
                                                                        <td class="text-right">250</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Runs:</td>
                                                                        <td class="text-right">25000</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Wickets:</td>
                                                                        <td class="text-right">2000</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Strike Rate:</td>
                                                                        <td class="text-right">300</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Econ. Rate:</td>
                                                                        <td class="text-right">300</td>
                                                                    </tr>

                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-3  col-md-4 col-sm-6">
                                                <div class="single-player-blk">
                                                    <div class="palyer-blk-top-blk">
                                                        <div class="main-player-img">
                                                            <img src={player} alt="" />
                                                        </div>
                                                        <span><img src={ball} alt="" /></span>
                                                        <span><img src={bet} alt="" /></span>
                                                    </div>
                                                    <div class="player-all-info">
                                                        <div class="player-title">
                                                            <h3>Shakib Al Hasan</h3>
                                                            <p>Batting: LHB | Bowling: LB</p>
                                                        </div>
                                                        <div class="palyber-main-info">
                                                            <table class="table">
                                                                <tbody>
                                                                    <tr>
                                                                        <td>Innings:</td>
                                                                        <td class="text-right">250</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Runs:</td>
                                                                        <td class="text-right">25000</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Wickets:</td>
                                                                        <td class="text-right">2000</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Strike Rate:</td>
                                                                        <td class="text-right">300</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Econ. Rate:</td>
                                                                        <td class="text-right">300</td>
                                                                    </tr>

                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-3  col-md-4 col-sm-6">
                                                <div class="single-player-blk">
                                                    <div class="palyer-blk-top-blk">
                                                        <div class="main-player-img">
                                                            <img src={player} alt="" />
                                                        </div>
                                                        <span><img src={ball} alt="" /></span>
                                                        <span><img src={bet} alt="" /></span>
                                                    </div>
                                                    <div class="player-all-info">
                                                        <div class="player-title">
                                                            <h3>Shakib Al Hasan</h3>
                                                            <p>Batting: LHB | Bowling: LB</p>
                                                        </div>
                                                        <div class="palyber-main-info">
                                                            <table class="table">
                                                                <tbody>
                                                                    <tr>
                                                                        <td>Innings:</td>
                                                                        <td class="text-right">250</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Runs:</td>
                                                                        <td class="text-right">25000</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Wickets:</td>
                                                                        <td class="text-right">2000</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Strike Rate:</td>
                                                                        <td class="text-right">300</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Econ. Rate:</td>
                                                                        <td class="text-right">300</td>
                                                                    </tr>

                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-3  col-md-4 col-sm-6">
                                                <div class="single-player-blk">
                                                    <div class="palyer-blk-top-blk">
                                                        <div class="main-player-img">
                                                            <img src={player} alt="" />
                                                        </div>
                                                        <span><img src={ball} alt="" /></span>
                                                        <span><img src={bet} alt="" /></span>
                                                    </div>
                                                    <div class="player-all-info">
                                                        <div class="player-title">
                                                            <h3>Shakib Al Hasan</h3>
                                                            <p>Batting: LHB | Bowling: LB</p>
                                                        </div>
                                                        <div class="palyber-main-info">
                                                            <table class="table">
                                                                <tbody>
                                                                    <tr>
                                                                        <td>Innings:</td>
                                                                        <td class="text-right">250</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Runs:</td>
                                                                        <td class="text-right">25000</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Wickets:</td>
                                                                        <td class="text-right">2000</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Strike Rate:</td>
                                                                        <td class="text-right">300</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Econ. Rate:</td>
                                                                        <td class="text-right">300</td>
                                                                    </tr>

                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-3  col-md-4 col-sm-6">
                                                <div class="single-player-blk">
                                                    <div class="palyer-blk-top-blk">
                                                        <div class="main-player-img">
                                                            <img src={player} alt="" />
                                                        </div>
                                                        <span><img src={ball} alt="" /></span>
                                                        <span><img src={bet} alt="" /></span>
                                                    </div>
                                                    <div class="player-all-info">
                                                        <div class="player-title">
                                                            <h3>Shakib Al Hasan</h3>
                                                            <p>Batting: LHB | Bowling: LB</p>
                                                        </div>
                                                        <div class="palyber-main-info">
                                                            <table class="table">
                                                                <tbody>
                                                                    <tr>
                                                                        <td>Innings:</td>
                                                                        <td class="text-right">250</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Runs:</td>
                                                                        <td class="text-right">25000</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Wickets:</td>
                                                                        <td class="text-right">2000</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Strike Rate:</td>
                                                                        <td class="text-right">300</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Econ. Rate:</td>
                                                                        <td class="text-right">300</td>
                                                                    </tr>

                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-3  col-md-4 col-sm-6">
                                                <div class="single-player-blk">
                                                    <div class="palyer-blk-top-blk">
                                                        <div class="main-player-img">
                                                            <img src={player} alt="" />
                                                        </div>
                                                        <span><img src={ball} alt="" /></span>
                                                        <span><img src={bet} alt="" /></span>
                                                    </div>
                                                    <div class="player-all-info">
                                                        <div class="player-title">
                                                            <h3>Shakib Al Hasan</h3>
                                                            <p>Batting: LHB | Bowling: LB</p>
                                                        </div>
                                                        <div class="palyber-main-info">
                                                            <table class="table">
                                                                <tbody>
                                                                    <tr>
                                                                        <td>Innings:</td>
                                                                        <td class="text-right">250</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Runs:</td>
                                                                        <td class="text-right">25000</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Wickets:</td>
                                                                        <td class="text-right">2000</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Strike Rate:</td>
                                                                        <td class="text-right">300</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Econ. Rate:</td>
                                                                        <td class="text-right">300</td>
                                                                    </tr>

                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-3 col-md-4 col-sm-6">
                                                <div class="single-player-blk">
                                                    <div class="palyer-blk-top-blk">
                                                        <div class="main-player-img">
                                                            <img src={player} alt="" />
                                                        </div>
                                                        <span><img src={ball} alt="" /></span>
                                                        <span><img src={bet} alt="" /></span>
                                                    </div>
                                                    <div class="player-all-info">
                                                        <div class="player-title">
                                                            <h3>Shakib Al Hasan</h3>
                                                            <p>Batting: LHB | Bowling: LB</p>
                                                        </div>
                                                        <div class="palyber-main-info">
                                                            <table class="table">
                                                                <tbody>
                                                                    <tr>
                                                                        <td>Innings:</td>
                                                                        <td class="text-right">250</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Runs:</td>
                                                                        <td class="text-right">25000</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Wickets:</td>
                                                                        <td class="text-right">2000</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Strike Rate:</td>
                                                                        <td class="text-right">300</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Econ. Rate:</td>
                                                                        <td class="text-right">300</td>
                                                                    </tr>

                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-3  col-md-4 col-sm-6">
                                                <div class="single-player-blk">
                                                    <div class="palyer-blk-top-blk">
                                                        <div class="main-player-img">
                                                            <img src={player} alt="" />
                                                        </div>
                                                        <span><img src={ball} alt="" /></span>
                                                        <span><img src={bet} alt="" /></span>
                                                    </div>
                                                    <div class="player-all-info">
                                                        <div class="player-title">
                                                            <h3>Shakib Al Hasan</h3>
                                                            <p>Batting: LHB | Bowling: LB</p>
                                                        </div>
                                                        <div class="palyber-main-info">
                                                            <table class="table">
                                                                <tbody>
                                                                    <tr>
                                                                        <td>Innings:</td>
                                                                        <td class="text-right">250</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Runs:</td>
                                                                        <td class="text-right">25000</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Wickets:</td>
                                                                        <td class="text-right">2000</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Strike Rate:</td>
                                                                        <td class="text-right">300</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Econ. Rate:</td>
                                                                        <td class="text-right">300</td>
                                                                    </tr>

                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-3  col-md-4 col-sm-6">
                                                <div class="single-player-blk">
                                                    <div class="palyer-blk-top-blk">
                                                        <div class="main-player-img">
                                                            <img src={player} alt="" />
                                                        </div>
                                                        <span><img src={ball} alt="" /></span>
                                                        <span><img src={bet} alt="" /></span>
                                                    </div>
                                                    <div class="player-all-info">
                                                        <div class="player-title">
                                                            <h3>Shakib Al Hasan</h3>
                                                            <p>Batting: LHB | Bowling: LB</p>
                                                        </div>
                                                        <div class="palyber-main-info">
                                                            <table class="table">
                                                                <tbody>
                                                                    <tr>
                                                                        <td>Innings:</td>
                                                                        <td class="text-right">250</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Runs:</td>
                                                                        <td class="text-right">25000</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Wickets:</td>
                                                                        <td class="text-right">2000</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Strike Rate:</td>
                                                                        <td class="text-right">300</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Econ. Rate:</td>
                                                                        <td class="text-right">300</td>
                                                                    </tr>

                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-3  col-md-4 col-sm-6">
                                                <div class="single-player-blk">
                                                    <div class="palyer-blk-top-blk">
                                                        <div class="main-player-img">
                                                            <img src={player} alt="" />
                                                        </div>
                                                        <span><img src={ball} alt="" /></span>
                                                        <span><img src={bet} alt="" /></span>
                                                    </div>
                                                    <div class="player-all-info">
                                                        <div class="player-title">
                                                            <h3>Shakib Al Hasan</h3>
                                                            <p>Batting: LHB | Bowling: LB</p>
                                                        </div>
                                                        <div class="palyber-main-info">
                                                            <table class="table">
                                                                <tbody>
                                                                    <tr>
                                                                        <td>Innings:</td>
                                                                        <td class="text-right">250</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Runs:</td>
                                                                        <td class="text-right">25000</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Wickets:</td>
                                                                        <td class="text-right">2000</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Strike Rate:</td>
                                                                        <td class="text-right">300</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Econ. Rate:</td>
                                                                        <td class="text-right">300</td>
                                                                    </tr>

                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-3  col-md-4 col-sm-6">
                                                <div class="single-player-blk">
                                                    <div class="palyer-blk-top-blk">
                                                        <div class="main-player-img">
                                                            <img src={player} alt="" />
                                                        </div>
                                                        <span><img src={ball} alt="" /></span>
                                                        <span><img src={bet} alt="" /></span>
                                                    </div>
                                                    <div class="player-all-info">
                                                        <div class="player-title">
                                                            <h3>Shakib Al Hasan</h3>
                                                            <p>Batting: LHB | Bowling: LB</p>
                                                        </div>
                                                        <div class="palyber-main-info">
                                                            <table class="table">
                                                                <tbody>
                                                                    <tr>
                                                                        <td>Innings:</td>
                                                                        <td class="text-right">250</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Runs:</td>
                                                                        <td class="text-right">25000</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Wickets:</td>
                                                                        <td class="text-right">2000</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Strike Rate:</td>
                                                                        <td class="text-right">300</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Econ. Rate:</td>
                                                                        <td class="text-right">300</td>
                                                                    </tr>

                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="seemore-btn-inner">
                                            <a href="" class="ld-more-btn">Load More</a>
                                        </div>

                                    </div>







                                </div>
                            </div>
                            <div class="col-lg-3">
                                <div class="sidebar-widget-wrapper plt">


                                    <div class="sidebar-widget rank-widget fct-wd">
                                        <h4 class="heading-title">Quick Facts</h4>

                                        <div class="facts-info">
                                            <h5>Association</h5>
                                            <div class="inner-facts-texts">
                                                <img src="assets/img/tiger-img.svg" alt="" />
                                                <p>Bangladesh Cricket Board</p>
                                            </div>
                                            <div class="nk-name">
                                                <h5>Nickname</h5>
                                                <p>The Tigers</p>
                                            </div>
                                            <div class="captine">
                                                <h5>Captain</h5>
                                                <div class="cpt-list-items">
                                                    <p><span>Test</span>Mominul Haque</p>
                                                    <p><span>ODI</span>Mashrafe Bin Mortaza</p>
                                                    <p><span>T20</span>Mahmudullah</p>
                                                    <p><span>Coach</span>Russell Domingo</p>
                                                </div>
                                            </div>
                                            <div class="nk-name">
                                                <h5>History</h5>
                                                <p>Test Status 2020</p>
                                            </div>
                                            <div class="nk-name">
                                                <h5>Matches</h5>
                                                <div class="match-table">
                                                    <table class="table">
                                                        <thead>
                                                            <tr>
                                                                <th><span class="gp"></span></th>
                                                                <th>P</th>
                                                                <th>W</th>
                                                                <th>L</th>
                                                                <th>T</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td><span class="gp">Mark</span></td>
                                                                <td>117</td>
                                                                <td>13</td>
                                                                <td>88</td>
                                                                <td>16</td>
                                                            </tr>
                                                            <tr>
                                                                <td><span class="gp">Mark</span></td>
                                                                <td>373</td>
                                                                <td>125</td>
                                                                <td>241</td>
                                                                <td>0</td>
                                                            </tr>
                                                            <tr>
                                                                <td><span class="gp">Mark</span></td>
                                                                <td>94</td>
                                                                <td>30</td>
                                                                <td>62</td>
                                                                <td>0</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                    <div class="sidebar-widget trend-widget">
                                        <h4 class="heading-title">Trending Now</h4>

                                        <nav>
                                            <ul>
                                                <li>
                                                    <div class="img"><a href="#"><img src="assets/img/trending thumbnail.png" alt="image" /></a></div>
                                                    <div class="content"><a href="#">
                                                        <p>Lorem Ipsum is simply dummy text to print ...</p>
                                                    </a></div>
                                                </li>
                                                <li>
                                                    <div class="img"><a href="#"><img src="assets/img/trending thumbnail.png" alt="image" /></a></div>
                                                    <div class="content"><a href="#">
                                                        <p>Lorem Ipsum is simply dummy text to print ...</p>
                                                    </a></div>
                                                </li>
                                                <li>
                                                    <div class="img"><a href="#"><img src="assets/img/trending thumbnail.png" alt="image" /></a></div>
                                                    <div class="content"><a href="#">
                                                        <p>Lorem Ipsum is simply dummy text to print ...</p>
                                                    </a></div>
                                                </li>
                                                <li>
                                                    <div class="img"><a href="#"><img src="assets/img/trending thumbnail.png" alt="image" /></a></div>
                                                    <div class="content"><a href="#">
                                                        <p>Lorem Ipsum is simply dummy text to print ...</p>
                                                    </a></div>
                                                </li>
                                                <li>
                                                    <div class="img"><a href="#"><img src="assets/img/trending thumbnail.png" alt="image" /></a></div>
                                                    <div class="content"><a href="#">
                                                        <p>Lorem Ipsum is simply dummy text to print ...</p>
                                                    </a></div>
                                                </li>
                                            </ul>
                                        </nav>
                                        <a href="" class="ld-more-btn">Load More</a>
                                    </div>

                                    <div class="sidebar-widget popular-widget">
                                        <h4 class="heading-title">Most Popular</h4>

                                        <div>
                                            <div class="nav nav-tabs" id="nav-tab" role="tablist">
                                                <a class="nav-item nav-link active" data-toggle="tab" href="#nav-7">Today</a>
                                                <a class="nav-item nav-link" data-toggle="tab" href="#nav-8">Last 7 Days</a>
                                                <a class="nav-item nav-link" data-toggle="tab" href="#nav-9">Last 30 Days</a>
                                            </div>
                                        </div>
                                        <div class="tab-content" id="nav-tabContent">
                                            <div class="tab-pane fade show active" id="nav-7">
                                                <nav>
                                                    <ul>
                                                        <li>
                                                            <span>1</span>
                                                            <a href="#">
                                                                <p>Lorem Ipsum is simply dummy ..</p>
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <span>2</span>
                                                            <a href="#">
                                                                <p>Lorem int and typesetting int and typesetting int and typesetting int and typesetting...</p>
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <span>3</span>
                                                            <a href="#">
                                                                <p>Lorem int and typesetting int and typesetting int and typesetting int and typesetting...</p>
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <span>4</span>
                                                            <a href="#">
                                                                <p>Lorem int and typesetting int and typesetting int and typesetting int and typesetting...</p>
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <span>5</span>
                                                            <a href="#">
                                                                <p>Lorem int and typesetting int and typesetting int and typesetting int and typesetting...</p>
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </nav>

                                            </div>
                                            <div class="tab-pane fade" id="nav-8">

                                            </div>
                                            <div class="tab-pane fade" id="nav-9">


                                            </div>
                                        </div>



                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}
export default TeamPlayer