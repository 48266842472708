import React, { Component, Fragment } from 'react'
import { NavLink } from 'react-router-dom'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// images 
import indiaFlag from '../../images/f-india.png'
import trending from '../../images/trending-thumbnail.png'
import ball from '../../images/ball.png'
import ball2 from '../../images/ball.svg'
import bg from '../../images/l-info-img.jpg'
import goldenBall from '../../images/ball-golden.svg'
import batMian from '../../images/bat-mian.svg'

class LineUps extends Component {
    render() {

        return (
            <Fragment>
                <div class="news-content-area fx-padding">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-lg-9">
                                <div class="lins-up-inner-blk">
                                    <div class="ln-top-main">
                                        <div class="ln-top-des-blk">
                                            <div class="row">
                                                <div class="col-md-7">
                                                    <div class="lntop-left-des">
                                                        <h2>India vs West Indies</h2>
                                                        <div class="ln-des-info">
                                                            <span>1st ODI</span>
                                                            <span>6-DEC-2019</span>
                                                            <span>4:00 PM</span>
                                                        </div>
                                                        <p>M. A. Chidambaram Stadium, Chennai, India</p>
                                                    </div>
                                                </div>
                                                <div class="col-md-5">
                                                    <div class="fx-inner-top-info">
                                                        <span>Live</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="line-ups-inner-all-info" style={{ background: `url(${bg})no-repeat center center / cover` }}>
                                        <div class="row">
                                            <div class="col-md-8">
                                                <div class="line-up-inner-left-blk">
                                                    <div class="line-up-lft">
                                                        <div class="line-up-left-blk-single-row">
                                                            <img src={indiaFlag} alt="" />
                                                            <h3>India <img src={goldenBall} alt="" /> <img src={batMian} alt="" /></h3>
                                                        </div>
                                                        <div class="line-up-rt-blk">
                                                            <h3>287/8 (50)</h3>
                                                        </div>
                                                    </div>
                                                    <div class="line-up-lft">
                                                        <div class="line-up-left-blk-single-row">
                                                            <img src={indiaFlag} alt="" />
                                                            <h3>India <img src={ball} alt="" /> <img src={ball2} alt="" /></h3>
                                                        </div>
                                                        <div class="line-up-rt-blk">
                                                            <h3>287/8 (50)</h3>
                                                        </div>
                                                    </div>
                                                    <p>WI require 138 runs with 9 wickets & 140 balls remaining</p>
                                                </div>
                                            </div>
                                            <div class="col-md-4">
                                                <div class="line-info-right-table">
                                                    <table class="table">

                                                        <tbody>
                                                            <tr>
                                                                <td>Current Run Rate</td>
                                                                <td class="text-right">5.39</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Required Run Rate</td>
                                                                <td class="text-right">6.47</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Last 5 Over</td>
                                                                <td class="text-right">45/0 (RR: 9.00)</td>
                                                            </tr>
                                                            <tr>
                                                                <td>At this stage India were</td>
                                                                <td class="text-right">160/4</td>
                                                            </tr>

                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-3">
                                <div class="sidebar-widget-wrapper">



                                    <div class="sidebar-widget trend-widget">
                                        <h4 class="heading-title">Latest News</h4>

                                        <nav>
                                            <ul>
                                                <li>
                                                    <div class="img"><NavLink to="/"><img src={trending} alt="image" /></NavLink></div>
                                                    <div class="content"><NavLink to="/">
                                                        <p>Lorem Ipsum is simply dummy text to print ...</p>
                                                    </NavLink></div>
                                                </li>
                                                <li>
                                                    <div class="img"><NavLink to="/"><img src={trending} alt="image" /></NavLink></div>
                                                    <div class="content"><NavLink to="/">
                                                        <p>Lorem Ipsum is simply dummy text to print ...</p>
                                                    </NavLink></div>
                                                </li>
                                                <li>
                                                    <div class="img"><NavLink to="/"><img src={trending} alt="image" /></NavLink></div>
                                                    <div class="content"><NavLink to="/">
                                                        <p>Lorem Ipsum is simply dummy text to print ...</p>
                                                    </NavLink></div>
                                                </li>
                                                <li>
                                                    <div class="img"><NavLink to="/"><img src={trending} alt="image" /></NavLink></div>
                                                    <div class="content"><NavLink to="/">
                                                        <p>Lorem Ipsum is simply dummy text to print ...</p>
                                                    </NavLink></div>
                                                </li>
                                                <li>
                                                    <div class="img"><NavLink to="/"><img src={trending} alt="image" /></NavLink></div>
                                                    <div class="content"><NavLink to="/">
                                                        <p>Lorem Ipsum is simply dummy text to print ...</p>
                                                    </NavLink></div>
                                                </li>
                                            </ul>
                                        </nav>
                                        <NavLink to="/" class="ld-more-btn">Load More</NavLink>
                                    </div>

                                    <div class="sidebar-widget popular-widget">
                                        <h4 class="heading-title">Most Popular</h4>

                                        <div>
                                            <div class="nav nav-tabs" id="nav-tab" role="tablist">
                                                <NavLink class="nav-item nav-NavLink active" data-toggle="tab" to="/nav-7">Today</NavLink>
                                                <NavLink class="nav-item nav-NavLink" data-toggle="tab" to="/nav-8">Last 7 Days</NavLink>
                                                <NavLink class="nav-item nav-NavLink" data-toggle="tab" to="/nav-9">Last 30 Days</NavLink>
                                            </div>
                                        </div>
                                        <div class="tab-content" id="nav-tabContent">
                                            <div class="tab-pane fade show active" id="nav-7">
                                                <nav>
                                                    <ul>
                                                        <li>
                                                            <span>1</span>
                                                            <NavLink to="/">
                                                                <p>Lorem Ipsum is simply dummy ..</p>
                                                            </NavLink>
                                                        </li>
                                                        <li>
                                                            <span>2</span>
                                                            <NavLink to="/">
                                                                <p>Lorem int and typesetting int and typesetting int and typesetting int and typesetting...</p>
                                                            </NavLink>
                                                        </li>
                                                        <li>
                                                            <span>3</span>
                                                            <NavLink to="/">
                                                                <p>Lorem int and typesetting int and typesetting int and typesetting int and typesetting...</p>
                                                            </NavLink>
                                                        </li>
                                                        <li>
                                                            <span>4</span>
                                                            <NavLink to="/">
                                                                <p>Lorem int and typesetting int and typesetting int and typesetting int and typesetting...</p>
                                                            </NavLink>
                                                        </li>
                                                        <li>
                                                            <span>5</span>
                                                            <NavLink to="/">
                                                                <p>Lorem int and typesetting int and typesetting int and typesetting int and typesetting...</p>
                                                            </NavLink>
                                                        </li>
                                                    </ul>
                                                </nav>

                                            </div>
                                            <div class="tab-pane fade" id="nav-8">

                                            </div>
                                            <div class="tab-pane fade" id="nav-9">


                                            </div>
                                        </div>



                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}
export default LineUps