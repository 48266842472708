import React, { Component, Fragment } from 'react'
import { NavLink } from 'react-router-dom'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// images 
import trending from '../../images/trending-thumbnail.png'
import profile from '../../images/pl-thumb.jpg'


class Profile extends Component {
    render() {

        return (
            <Fragment>
                   <div class="news-content-area fx-padding">
        <div class="container-fluid">
            <div class="row">
                <div class="col-lg-9">

                    <div class="profile-inner-blk">
                        <div class="row">
                            <div class="col-lg-4">
                                <div class="profiles-main-tm-blk">
                                    <h4>Shakib Al Hasan</h4>
                                    <div class="main-pl-tm-img">
                                        <img src={profile} alt=""/>
                                    </div>
                                    <div class="player-info-table">
                                        <table class="table">
                                            <tbody>
                                                <tr>
                                                    <td>Nationality</td>
                                                    <td>Bangladeshi</td>
                                                </tr>
                                                <tr>
                                                    <td>Role</td>
                                                    <td>All Rounder</td>
                                                </tr>
                                                <tr>
                                                    <td>Born</td>
                                                    <td>24th March, 1987</td>
                                                </tr>
                                                <tr>
                                                    <td>Age</td>
                                                    <td>32 years, 10 months, 9 days</td>
                                                </tr>
                                                <tr>
                                                    <td>Batting Style</td>
                                                    <td>LHB</td>
                                                </tr>
                                                <tr>
                                                    <td>Bowling Style</td>
                                                    <td>Slow left-arm orthodox</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-8">
                                <div class="prof-tab">
                                    <div class="tabContainer">
                                        <nav>
                                            <div class="nav nav-link-wrap bdr-btm-three rds" id="nav-tab" role="tablist">
                                               

                                                <a class="nav-item nav-link active" id="tbTwo-tab" data-toggle="tab" href="#tbTwo" role="tab" aria-controls="nav-profile" aria-selected="false">Batting</a>

                                                <a class="nav-item nav-link" id="tbThree-tab" data-toggle="tab" href="#tbThree" role="tab" aria-controls="nav-contact" aria-selected="false">Bowling</a>

                                                
                                            </div>
                                        </nav>
                                        <div class="tab-content" id="nav-tabContent">

                                        
                                            <div class="tab-pane fade show active" id="tbTwo" role="tabpanel" aria-labelledby="tbTwo-tab">
                                                <div class="tb-content-wrap">
                                                    <div class="tabContainer-inner-1">
                                                        <nav>
                                                            <div class="nav nav-link-wrap inner-1" id="nav-tab" role="tablist">
                                                                <a class="nav-item nav-link active" id="tb-line-1-tab" data-toggle="tab" href="#tb-card-1" role="tab" aria-controls="nav-home" aria-selected="true">TEST

                                                                   
                                                                </a>
                                                                <a class="nav-item nav-link" id="tb-line-2-tab" data-toggle="tab" href="#tb-card-2" role="tab" aria-controls="nav-profile" aria-selected="false">ODI
                                                                   
                                                                </a>
                                                                <a class="nav-item nav-link" id="tb-line-3-tab" data-toggle="tab" href="#tb-card-3" role="tab" aria-controls="nav-profile" aria-selected="false">T20I
                                                                    
                                                                </a>
                                                                <a class="nav-item nav-link" id="tb-line-4-tab" data-toggle="tab" href="#tb-card-4" role="tab" aria-controls="nav-profile" aria-selected="false">T20
                                                                </a>
                                                                <a class="nav-item nav-link" id="tb-line-5-tab" data-toggle="tab" href="#tb-card-5" role="tab" aria-controls="nav-profile" aria-selected="false">List A
                                                                </a>
                                                                <a class="nav-item nav-link" id="tb-line-6-tab" data-toggle="tab" href="#tb-card-6" role="tab" aria-controls="nav-profile" aria-selected="false">1st Class
                                                                </a>
                                                            </div>
                                                        </nav>
                                                        <div class="tab-content" id="nav-tabContent">
                                                            <div class="tab-pane fade show active" id="tb-card-1" role="tabpanel" aria-labelledby="tb-inner-1-tab">
                                                                 
                                                                 <div class="prf-table-inner">
                                                                    <div class="pl-table-info-all">
                                                                        <table class="table">
                                                                            <tbody>
                                                                                <tr>
                                                                                    <td>
                                                                                        Matches
                                                                                    </td>
                                                                                    <td>
                                                                                        42
                                                                                    </td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td>
                                                                                        Innings
                                                                                    </td>
                                                                                    <td>
                                                                                        78
                                                                                    </td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td>
                                                                                        No
                                                                                    </td>
                                                                                    <td>
                                                                                        7
                                                                                    </td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td>
                                                                                        Runs
                                                                                    </td>
                                                                                    <td>
                                                                                        2823
                                                                                    </td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td>
                                                                                        High Score
                                                                                    </td>
                                                                                    <td>
                                                                                        144
                                                                                    </td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td>
                                                                                        average
                                                                                    </td>
                                                                                    <td>
                                                                                        4617
                                                                                    </td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td>
                                                                                        Strike Rate
                                                                                    </td>
                                                                                    <td>
                                                                                        61.14
                                                                                    </td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td>
                                                                                        100s
                                                                                    </td>
                                                                                    <td>
                                                                                        3
                                                                                    </td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td>
                                                                                        50s
                                                                                    </td>
                                                                                    <td>
                                                                                        9
                                                                                    </td>
                                                                                </tr>
                                                                                
                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                                 </div>
                                                                 
                                                            </div>
                                                            <div class="tab-pane fade" id="tb-card-2" role="tabpanel" aria-labelledby="tb-inner-2-tab">
                                                                 
                                                                 <div class="prf-table-inner">
                                                                    
                                                                    <div class="pl-table-info-all">
                                                                        <table class="table">
                                                                            <tbody>
                                                                                <tr>
                                                                                    <td>
                                                                                        Matches
                                                                                    </td>
                                                                                    <td>
                                                                                        42
                                                                                    </td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td>
                                                                                        Innings
                                                                                    </td>
                                                                                    <td>
                                                                                        78
                                                                                    </td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td>
                                                                                        No
                                                                                    </td>
                                                                                    <td>
                                                                                        7
                                                                                    </td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td>
                                                                                        Runs
                                                                                    </td>
                                                                                    <td>
                                                                                        2823
                                                                                    </td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td>
                                                                                        High Score
                                                                                    </td>
                                                                                    <td>
                                                                                        144
                                                                                    </td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td>
                                                                                        average
                                                                                    </td>
                                                                                    <td>
                                                                                        4617
                                                                                    </td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td>
                                                                                        Strike Rate
                                                                                    </td>
                                                                                    <td>
                                                                                        61.14
                                                                                    </td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td>
                                                                                        100s
                                                                                    </td>
                                                                                    <td>
                                                                                        3
                                                                                    </td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td>
                                                                                        50s
                                                                                    </td>
                                                                                    <td>
                                                                                        9
                                                                                    </td>
                                                                                </tr>
                                                                                
                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                                 </div>
                                                                 
                                                            </div>
                                                            <div class="tab-pane fade" id="tb-card-3" role="tabpanel" aria-labelledby="tb-inner-3-tab">
                                                                 
                                                                 <div class="prf-table-inner">
                                                                     
                                                                    <div class="pl-table-info-all">
                                                                        <table class="table">
                                                                            <tbody>
                                                                                <tr>
                                                                                    <td>
                                                                                        Matches
                                                                                    </td>
                                                                                    <td>
                                                                                        42
                                                                                    </td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td>
                                                                                        Innings
                                                                                    </td>
                                                                                    <td>
                                                                                        78
                                                                                    </td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td>
                                                                                        No
                                                                                    </td>
                                                                                    <td>
                                                                                        7
                                                                                    </td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td>
                                                                                        Runs
                                                                                    </td>
                                                                                    <td>
                                                                                        2823
                                                                                    </td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td>
                                                                                        High Score
                                                                                    </td>
                                                                                    <td>
                                                                                        144
                                                                                    </td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td>
                                                                                        average
                                                                                    </td>
                                                                                    <td>
                                                                                        4617
                                                                                    </td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td>
                                                                                        Strike Rate
                                                                                    </td>
                                                                                    <td>
                                                                                        61.14
                                                                                    </td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td>
                                                                                        100s
                                                                                    </td>
                                                                                    <td>
                                                                                        3
                                                                                    </td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td>
                                                                                        50s
                                                                                    </td>
                                                                                    <td>
                                                                                        9
                                                                                    </td>
                                                                                </tr>
                                                                                
                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                                 </div>
                                                                 
                                                            </div>
                                                            <div class="tab-pane fade" id="tb-card-4" role="tabpanel" aria-labelledby="tb-inner-4-tab">
                                                                 
                                                                 <div class="prf-table-inner">
                                                                    
                                                                    <div class="pl-table-info-all">
                                                                        <table class="table">
                                                                            <tbody>
                                                                                <tr>
                                                                                    <td>
                                                                                        Matches
                                                                                    </td>
                                                                                    <td>
                                                                                        42
                                                                                    </td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td>
                                                                                        Innings
                                                                                    </td>
                                                                                    <td>
                                                                                        78
                                                                                    </td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td>
                                                                                        No
                                                                                    </td>
                                                                                    <td>
                                                                                        7
                                                                                    </td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td>
                                                                                        Runs
                                                                                    </td>
                                                                                    <td>
                                                                                        2823
                                                                                    </td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td>
                                                                                        High Score
                                                                                    </td>
                                                                                    <td>
                                                                                        144
                                                                                    </td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td>
                                                                                        average
                                                                                    </td>
                                                                                    <td>
                                                                                        4617
                                                                                    </td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td>
                                                                                        Strike Rate
                                                                                    </td>
                                                                                    <td>
                                                                                        61.14
                                                                                    </td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td>
                                                                                        100s
                                                                                    </td>
                                                                                    <td>
                                                                                        3
                                                                                    </td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td>
                                                                                        50s
                                                                                    </td>
                                                                                    <td>
                                                                                        9
                                                                                    </td>
                                                                                </tr>
                                                                                
                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                                 </div>
                                                                 
                                                            </div>
                                                            <div class="tab-pane fade" id="tb-card-5" role="tabpanel" aria-labelledby="tb-inner-5-tab">
                                                                 
                                                                 <div class="prf-table-inner">
                                                                     
                                                                    <div class="pl-table-info-all">
                                                                        <table class="table">
                                                                            <tbody>
                                                                                <tr>
                                                                                    <td>
                                                                                        Matches
                                                                                    </td>
                                                                                    <td>
                                                                                        42
                                                                                    </td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td>
                                                                                        Innings
                                                                                    </td>
                                                                                    <td>
                                                                                        78
                                                                                    </td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td>
                                                                                        No
                                                                                    </td>
                                                                                    <td>
                                                                                        7
                                                                                    </td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td>
                                                                                        Runs
                                                                                    </td>
                                                                                    <td>
                                                                                        2823
                                                                                    </td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td>
                                                                                        High Score
                                                                                    </td>
                                                                                    <td>
                                                                                        144
                                                                                    </td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td>
                                                                                        average
                                                                                    </td>
                                                                                    <td>
                                                                                        4617
                                                                                    </td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td>
                                                                                        Strike Rate
                                                                                    </td>
                                                                                    <td>
                                                                                        61.14
                                                                                    </td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td>
                                                                                        100s
                                                                                    </td>
                                                                                    <td>
                                                                                        3
                                                                                    </td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td>
                                                                                        50s
                                                                                    </td>
                                                                                    <td>
                                                                                        9
                                                                                    </td>
                                                                                </tr>
                                                                                
                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                                 </div>
                                                                 
                                                            </div>
                                                            <div class="tab-pane fade" id="tbe-card-6" role="tabpanel" aria-labelledby="tb-inner-6-tab">
                                                                 
                                                                 <div class="prf-table-inner">
                                                                    
                                                                    <div class="pl-table-info-all">
                                                                        <table class="table">
                                                                            <tbody>
                                                                                <tr>
                                                                                    <td>
                                                                                        Matches
                                                                                    </td>
                                                                                    <td>
                                                                                        42
                                                                                    </td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td>
                                                                                        Innings
                                                                                    </td>
                                                                                    <td>
                                                                                        78
                                                                                    </td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td>
                                                                                        No
                                                                                    </td>
                                                                                    <td>
                                                                                        7
                                                                                    </td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td>
                                                                                        Runs
                                                                                    </td>
                                                                                    <td>
                                                                                        2823
                                                                                    </td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td>
                                                                                        High Score
                                                                                    </td>
                                                                                    <td>
                                                                                        144
                                                                                    </td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td>
                                                                                        average
                                                                                    </td>
                                                                                    <td>
                                                                                        4617
                                                                                    </td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td>
                                                                                        Strike Rate
                                                                                    </td>
                                                                                    <td>
                                                                                        61.14
                                                                                    </td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td>
                                                                                        100s
                                                                                    </td>
                                                                                    <td>
                                                                                        3
                                                                                    </td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td>
                                                                                        50s
                                                                                    </td>
                                                                                    <td>
                                                                                        9
                                                                                    </td>
                                                                                </tr>
                                                                                
                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                                 </div>
                                                                 
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="tab-pane fade" id="tbThree" role="tabpanel" aria-labelledby="tbThree-tab">
                                                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Assumenda porro recusandae maiores velit labore fugiat, animi asperiores, sunt dolorum magnam inventore, reiciendis laboriosam ullam. Delectus expedita beatae dignissimos maiores, enim iste repudiandae dolore at, laboriosam fuga quas esse itaque quibusdam non. Natus voluptate optio animi fugit officia cum hic voluptatibus, repudiandae voluptas? Delectus veritatis repellat doloremque voluptatum, est nesciunt. Aut dolorem consectetur natus laudantium laborum reprehenderit, totam, iusto illo, magni vero numquam, facilis iure quaerat eligendi. Inventore ut delectus consequuntur iure dolorem perspiciatis reprehenderit vero voluptates possimus nostrum autem nulla, debitis blanditiis, deserunt doloremque amet animi. Hic at magni ullam consequatur rem quis totam voluptas accusantium soluta dolore, tempore rerum laboriosam, facere voluptatem est enim, iste maxime! Quaerat adipisci, dolore mollitia. Sit, temporibus officia aut quis eos quas provident quia porro sapiente laborum nisi, cum delectus dolorum iste harum, vero ipsa labore odio, modi. Nulla perferendis dolore deserunt voluptas sed.
                                            </div>

                                        </div>
                                    </div>
                                
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-12">
                                <div class="palyer-all-des">
                                    <p>Shakib Al Hasan is without a doubt the biggest name to have emerged from Bangladesh cricket circles. Being a genuine all-rounder, Shakib is a vital cog in Bangladesh's line-up. He has been a consistent performer for his country, over the years. Shakib first came into notice when he played cricket for several villages near his hometown of Magura. He spent time honing his skills at a government run sports facility and was picked for the Under-19 squad for the tri-series featuring England and Sri Lanka. He scored a 86-ball century and picked up three wickets in the final to help the team defeat Sri Lanka. This earned him selection for the series against Zimbabwe.</p>
                                    <p>He cracked his first ODI ton against Canada in the tri-series played in the West Indies, just before the 2007 World Cup. He scored a composed fifty and bowled 10 tight overs for 44 runs without a wicket as Bangladesh secured a big win over India. His consistent performances enhanced his reputation. He went on to score a century against Pakistan, later in the year.</p>
                                    <p>In the Test arena, Shakib continued in the same vein as he had done in the ODIs. His major breakthrough came in the Test against New Zealand in Chittagong in 2008. He picked up 7/36, which till date is the best figures by a Bangladeshi bowler. Shakib also contributed with a fifty in the second innings to set New Zealand a challenging 317. However, he got no support from the other bowlers and New Zealand won the match.</p>
                                    <p>Shakib was ranked No. 1 among the all-rounders by the ICC thanks to his consistency. He took over the captaincy from Mohammad Ashraful and led from the front. He picked up five wickets and went on to score an unbeaten 96 in the second Test in Grenada against a depleted West Indies side. Bangladesh eventually won the Test series 3-0.</p>
                                    <p>Shakib's stint with Worcestershire made him even better. He took 35 wickets in a single season, including his best figures of 7/32 against Middlesex to help the County get promoted to Division 1 of the championship. Along the way, he was the leading wicket-taker and run-scorer when Bangladesh achieved a whitewash over New Zealand in 2010.</p>
                                    <p>Despite leading Bangladesh to wins against England, Ireland and Netherlands in the 2011 World Cup, the team crashed out and many people blamed Shakib. He put aside all the criticism and performed well for Kolkata in the 2011 IPL.</p>
                                    <p>Shakib was sacked as captain before the tour of West Indies in 2011. However, he yet again proved his worth as he was Bangladesh's leading wicket-taker in both the ODI and Test series in the West Indies. His bowling was backed up by 168 runs he scored in two Tests. He scored 79 from two innings in the ODIs. Bangladesh lost both series. Later in the year, Shakib was Bangladesh's top run-scorer and wicket-taker in the home Test series against Pakistan in December 2011. In the second Test of the series, he became Bangladesh's first player to score a century and take five wickets in an innings. After the series, he was ranked No. 1 in the ICC's ranking of Test allrounders.
                                    </p>
                                    <p>The Bangladesh Cricket Board launched Bangladesh Premier League in 2012, a Twenty20 tournament to be held in February that year. The BCB made Shakib the 'Icon Player' for Khulna Royal Bengals. Under his captaincy, Shakib's team progressed to the semi-finals of the competition, where they were beaten by Dhaka Gladiators. In 10 matches, he scored 280 runs and took 15 wickets, and he was his team's leading wicket-taker and later he was named Man of the Tournament.</p>
                                    <p>In the 2012 Asia Cup, Shakib scored 237 runs, including three fifties and also took six wickets. Bangladesh progressed to the final of the tournament for the first time, where they lost to Pakistan by two runs. Shakib's performance earned him... <a href="">Read More</a></p>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div class="col-lg-3">
                    <div class="sidebar-widget-wrapper">
                        <div class="sidebar-widget trend-widget">
                            <h4 class="heading-title">Latest News</h4>

                            <nav>
                                <ul>
                                    <li>
                                        <div class="img"><a href="#"><img src={trending} alt="image"/></a></div>
                                        <div class="content"><a href="#">
                                                <p>Lorem Ipsum is simply dummy text to print ...</p>
                                            </a></div>
                                    </li>
                                    <li>
                                        <div class="img"><a href="#"><img src={trending} alt="image"/></a></div>
                                        <div class="content"><a href="#">
                                                <p>Lorem Ipsum is simply dummy text to print ...</p>
                                            </a></div>
                                    </li>
                                    <li>
                                        <div class="img"><a href="#"><img src={trending} alt="image"/></a></div>
                                        <div class="content"><a href="#">
                                                <p>Lorem Ipsum is simply dummy text to print ...</p>
                                            </a></div>
                                    </li>
                                    <li>
                                        <div class="img"><a href="#"><img src={trending} alt="image"/></a></div>
                                        <div class="content"><a href="#">
                                                <p>Lorem Ipsum is simply dummy text to print ...</p>
                                            </a></div>
                                    </li>
                                    <li>
                                        <div class="img"><a href="#"><img src={trending} alt="image"/></a></div>
                                        <div class="content"><a href="#">
                                                <p>Lorem Ipsum is simply dummy text to print ...</p>
                                            </a></div>
                                    </li>
                                </ul>
                            </nav>
                            <a href="" class="ld-more-btn">Load More</a>
                        </div>

                        <div class="sidebar-widget popular-widget">
                            <h4 class="heading-title">Most Popular</h4>

                            <div>
                                <div class="nav nav-tabs" id="nav-tab" role="tablist">
                                    <a class="nav-item nav-link active" data-toggle="tab" href="#nav-7">Today</a>
                                    <a class="nav-item nav-link" data-toggle="tab" href="#nav-8">Last 7 Days</a>
                                    <a class="nav-item nav-link" data-toggle="tab" href="#nav-9">Last 30 Days</a>
                                </div>
                            </div>
                            <div class="tab-content" id="nav-tabContent">
                                <div class="tab-pane fade show active" id="nav-7">
                                    <nav>
                                        <ul>
                                            <li>
                                                <span>1</span>
                                                <a href="#">
                                                    <p>Lorem Ipsum is simply dummy ..</p>
                                                </a>
                                            </li>
                                            <li>
                                                <span>2</span>
                                                <a href="#">
                                                    <p>Lorem int and typesetting int and typesetting int and typesetting int and typesetting...</p>
                                                </a>
                                            </li>
                                            <li>
                                                <span>3</span>
                                                <a href="#">
                                                    <p>Lorem int and typesetting int and typesetting int and typesetting int and typesetting...</p>
                                                </a>
                                            </li>
                                            <li>
                                                <span>4</span>
                                                <a href="#">
                                                    <p>Lorem int and typesetting int and typesetting int and typesetting int and typesetting...</p>
                                                </a>
                                            </li>
                                            <li>
                                                <span>5</span>
                                                <a href="#">
                                                    <p>Lorem int and typesetting int and typesetting int and typesetting int and typesetting...</p>
                                                </a>
                                            </li>
                                        </ul>
                                    </nav>

                                </div>
                                <div class="tab-pane fade" id="nav-8">

                                </div>
                                <div class="tab-pane fade" id="nav-9">


                                </div>
                            </div>



                        </div>
                        <div class="sidebar-widget rank-widget">

                            <h4 class="heading-title">ICC TEAM RANKING</h4>

                            <div>
                                <div class="nav nav-tabs" id="nav-tab" role="tablist">
                                    <a class="nav-item nav-link active" data-toggle="tab" href="#nav-4">ODI</a>
                                    <a class="nav-item nav-link" data-toggle="tab" href="#nav-5">T20</a>
                                    <a class="nav-item nav-link" data-toggle="tab" href="#nav-6">TEST</a>
                                </div>
                            </div>
                            <div class="tab-content" id="nav-tabContent">
                                <div class="tab-pane fade show active" id="nav-4">
                                    <nav>
                                        <ul>
                                            <li>
                                                <ol>
                                                    <li>
                                                        <p>Pos</p>
                                                    </li>
                                                    <li>
                                                        <p>Team</p>
                                                    </li>
                                                    <li>
                                                        <p>Rating</p>
                                                    </li>
                                                </ol>

                                            </li>
                                            <li>
                                                <ol>
                                                    <li>
                                                        <p>1</p>
                                                    </li>
                                                    <li>
                                                        <p>Pakistan</p>
                                                    </li>
                                                    <li>
                                                        <p>270</p>
                                                    </li>
                                                </ol>

                                            </li>
                                            <li>
                                                <ol>
                                                    <li>
                                                        <p>1</p>
                                                    </li>
                                                    <li>
                                                        <p>Pakistan</p>
                                                    </li>
                                                    <li>
                                                        <p>270</p>
                                                    </li>
                                                </ol>

                                            </li>
                                            <li>
                                                <ol>
                                                    <li>
                                                        <p>1</p>
                                                    </li>
                                                    <li>
                                                        <p>Pakistan</p>
                                                    </li>
                                                    <li>
                                                        <p>270</p>
                                                    </li>
                                                </ol>

                                            </li>
                                            <li>
                                                <ol>
                                                    <li>
                                                        <p>1</p>
                                                    </li>
                                                    <li>
                                                        <p>Pakistan</p>
                                                    </li>
                                                    <li>
                                                        <p>270</p>
                                                    </li>
                                                </ol>

                                            </li>
                                            <li>
                                                <ol>
                                                    <li>
                                                        <p>1</p>
                                                    </li>
                                                    <li>
                                                        <p>Pakistan</p>
                                                    </li>
                                                    <li>
                                                        <p>270</p>
                                                    </li>
                                                </ol>

                                            </li>
                                            <li>
                                                <ol>
                                                    <li>
                                                        <p>1</p>
                                                    </li>
                                                    <li>
                                                        <p>Pakistan</p>
                                                    </li>
                                                    <li>
                                                        <p>270</p>
                                                    </li>
                                                </ol>

                                            </li>
                                            <li>
                                                <ol>
                                                    <li>
                                                        <p>1</p>
                                                    </li>
                                                    <li>
                                                        <p>Pakistan</p>
                                                    </li>
                                                    <li>
                                                        <p>270</p>
                                                    </li>
                                                </ol>

                                            </li>
                                            <li><a href="#">watch full ranking</a></li>
                                        </ul>
                                    </nav>

                                </div>
                                <div class="tab-pane fade" id="nav-5">

                                </div>
                                <div class="tab-pane fade" id="nav-6">


                                </div>
                            </div>







                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
            </Fragment>
        )
    }
}
export default Profile