import React, { Component, Fragment } from 'react'
import { NavLink } from 'react-router-dom'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// images 
import trending from '../../images/trending-thumbnail.png'
import flag from '../../images/flag-round.png'


class Seris extends Component {
    render() {

        return (
            <Fragment>

                <div class="news-content-area fx-padding ranking-page">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-lg-9">
                                <div class="serise-inner-blk">
                                    <div class="src-top-menu">
                                        <ul>
                                            <li><a class="acttive-serise-menu" href="">Current</a></li>
                                            <li><a href="">Upcoming</a></li>
                                            <li><a href="">Completed</a></li>
                                        </ul>
                                    </div>
                                    <div class="scr-signle-item">
                                        <h3>International</h3>
                                        <div class="row">
                                            <div class="col-md-6">
                                                <div class="single-item-scr-blk">
                                                    <h4>ICC Men's Cricket World Cup League 2 <span>14 Aug - 22 Jan, 2022</span></h4>
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="single-item-scr-blk">
                                                    <h4>ICC Men's Cricket World Cup League 2 <span>14 Aug - 22 Jan, 2022</span></h4>
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="single-item-scr-blk">
                                                    <h4>ICC Men's Cricket World Cup League 2 <span>14 Aug - 22 Jan, 2022</span></h4>
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="single-item-scr-blk">
                                                    <h4>ICC Men's Cricket World Cup League 2 <span>14 Aug - 22 Jan, 2022</span></h4>
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="single-item-scr-blk">
                                                    <h4>ICC Men's Cricket World Cup League 2 <span>14 Aug - 22 Jan, 2022</span></h4>
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="single-item-scr-blk">
                                                    <h4>ICC Men's Cricket World Cup League 2 <span>14 Aug - 22 Jan, 2022</span></h4>
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="single-item-scr-blk">
                                                    <h4>ICC Men's Cricket World Cup League 2 <span>14 Aug - 22 Jan, 2022</span></h4>
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="single-item-scr-blk">
                                                    <h4>ICC Men's Cricket World Cup League 2 <span>14 Aug - 22 Jan, 2022</span></h4>
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="single-item-scr-blk">
                                                    <h4>ICC Men's Cricket World Cup League 2 <span>14 Aug - 22 Jan, 2022</span></h4>
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="single-item-scr-blk">
                                                    <h4>ICC Men's Cricket World Cup League 2 <span>14 Aug - 22 Jan, 2022</span></h4>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="scr-signle-item">
                                        <h3>Domestic</h3>
                                        <div class="row">
                                            <div class="col-md-6">
                                                <div class="single-item-scr-blk">
                                                    <h4>ICC Men's Cricket World Cup League 2 <span>14 Aug - 22 Jan, 2022</span></h4>
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="single-item-scr-blk">
                                                    <h4>ICC Men's Cricket World Cup League 2 <span>14 Aug - 22 Jan, 2022</span></h4>
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="single-item-scr-blk">
                                                    <h4>ICC Men's Cricket World Cup League 2 <span>14 Aug - 22 Jan, 2022</span></h4>
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="single-item-scr-blk">
                                                    <h4>ICC Men's Cricket World Cup League 2 <span>14 Aug - 22 Jan, 2022</span></h4>
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="single-item-scr-blk">
                                                    <h4>ICC Men's Cricket World Cup League 2 <span>14 Aug - 22 Jan, 2022</span></h4>
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="single-item-scr-blk">
                                                    <h4>ICC Men's Cricket World Cup League 2 <span>14 Aug - 22 Jan, 2022</span></h4>
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="single-item-scr-blk">
                                                    <h4>ICC Men's Cricket World Cup League 2 <span>14 Aug - 22 Jan, 2022</span></h4>
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="single-item-scr-blk">
                                                    <h4>ICC Men's Cricket World Cup League 2 <span>14 Aug - 22 Jan, 2022</span></h4>
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="single-item-scr-blk">
                                                    <h4>ICC Men's Cricket World Cup League 2 <span>14 Aug - 22 Jan, 2022</span></h4>
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="single-item-scr-blk">
                                                    <h4>ICC Men's Cricket World Cup League 2 <span>14 Aug - 22 Jan, 2022</span></h4>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-3">
                                <div class="sidebar-widget-wrapper">



                                    <div class="sidebar-widget trend-widget">
                                        <h4 class="heading-title">Latest News</h4>

                                        <nav>
                                            <ul>
                                                <li>
                                                    <div class="img"><NavLink to="/"><img src={trending} alt="image" /></NavLink></div>
                                                    <div class="content"><NavLink to="/">
                                                        <p>Lorem Ipsum is simply dummy text to print ...</p>
                                                    </NavLink></div>
                                                </li>
                                                <li>
                                                    <div class="img"><NavLink to="/"><img src={trending} alt="image" /></NavLink></div>
                                                    <div class="content"><NavLink to="/">
                                                        <p>Lorem Ipsum is simply dummy text to print ...</p>
                                                    </NavLink></div>
                                                </li>
                                                <li>
                                                    <div class="img"><NavLink to="/"><img src={trending} alt="image" /></NavLink></div>
                                                    <div class="content"><NavLink to="/">
                                                        <p>Lorem Ipsum is simply dummy text to print ...</p>
                                                    </NavLink></div>
                                                </li>
                                                <li>
                                                    <div class="img"><NavLink to="/"><img src={trending} alt="image" /></NavLink></div>
                                                    <div class="content"><NavLink to="/">
                                                        <p>Lorem Ipsum is simply dummy text to print ...</p>
                                                    </NavLink></div>
                                                </li>
                                                <li>
                                                    <div class="img"><NavLink to="/"><img src={trending} alt="image" /></NavLink></div>
                                                    <div class="content"><NavLink to="/">
                                                        <p>Lorem Ipsum is simply dummy text to print ...</p>
                                                    </NavLink></div>
                                                </li>
                                            </ul>
                                        </nav>
                                        <NavLink to="/" class="ld-more-btn">Load More</NavLink>
                                    </div>

                                    <div class="sidebar-widget popular-widget">
                                        <h4 class="heading-title">Most Popular</h4>

                                        <div>
                                            <div class="nav nav-tabs" id="nav-tab" role="tablist">
                                                <NavLink class="nav-item nav-NavLink active" data-toggle="tab" to="/nav-7">Today</NavLink>
                                                <NavLink class="nav-item nav-NavLink" data-toggle="tab" to="/nav-8">Last 7 Days</NavLink>
                                                <NavLink class="nav-item nav-NavLink" data-toggle="tab" to="/nav-9">Last 30 Days</NavLink>
                                            </div>
                                        </div>
                                        <div class="tab-content" id="nav-tabContent">
                                            <div class="tab-pane fade show active" id="nav-7">
                                                <nav>
                                                    <ul>
                                                        <li>
                                                            <span>1</span>
                                                            <NavLink to="/">
                                                                <p>Lorem Ipsum is simply dummy ..</p>
                                                            </NavLink>
                                                        </li>
                                                        <li>
                                                            <span>2</span>
                                                            <NavLink to="/">
                                                                <p>Lorem int and typesetting int and typesetting int and typesetting int and typesetting...</p>
                                                            </NavLink>
                                                        </li>
                                                        <li>
                                                            <span>3</span>
                                                            <NavLink to="/">
                                                                <p>Lorem int and typesetting int and typesetting int and typesetting int and typesetting...</p>
                                                            </NavLink>
                                                        </li>
                                                        <li>
                                                            <span>4</span>
                                                            <NavLink to="/">
                                                                <p>Lorem int and typesetting int and typesetting int and typesetting int and typesetting...</p>
                                                            </NavLink>
                                                        </li>
                                                        <li>
                                                            <span>5</span>
                                                            <NavLink to="/">
                                                                <p>Lorem int and typesetting int and typesetting int and typesetting int and typesetting...</p>
                                                            </NavLink>
                                                        </li>
                                                    </ul>
                                                </nav>

                                            </div>
                                            <div class="tab-pane fade" id="nav-8">

                                            </div>
                                            <div class="tab-pane fade" id="nav-9">


                                            </div>
                                        </div>



                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}
export default Seris