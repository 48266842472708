import React, { Component, Fragment } from 'react'
import { Link, NavLink } from 'react-router-dom'
import Slider from 'react-slick'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// images 
import post1 from '../../images/newsupdate-thumbnail.png'
import country from '../../images/Bangladesh.svg'
import compare from '../../images/compare-div.svg'
import trending from '../../images/trending-thumbnail.png'
import recent from '../../images/recent-svg.svg'
import player from '../../images/player-img.png'
import post2 from '../../images/post-img2.png'
import post3 from '../../images/post-img3.png'
import thumb from '../../images/post-thubmnail.png'
import avatar from '../../images/avater.png'
let settings = {
    dots: false,
    centerMode: true,
    arrows: true,
    centerPadding: '50px',
    slidesToShow: 5,
    className: "live-match-slider",
    responsive: [
        {
            breakpoint: 1600,
            settings: {
                centerMode: true,
                centerPadding: '50px',
                slidesToShow: 5
            }
        },
        {
            breakpoint: 1400,
            settings: {
                centerMode: true,
                centerPadding: '50px',
                slidesToShow: 3
            }
        },
        {
            breakpoint: 992,
            settings: {
                centerMode: true,
                centerPadding: '50px',
                slidesToShow: 2
            }
        },
        {
            breakpoint: 768,
            settings: {
                centerMode: true,
                centerPadding: '50px',
                slidesToShow: 1
            }
        }
    ]
};
let settings2 = {
    dots: false,
    arrows: true,
    slidesToShow: 3,
    className: "post-slilder-main row",
    responsive: [
        {
            breakpoint: 1600,
            settings: {
                slidesToShow: 3
            }
        },
        {
            breakpoint: 1400,
            settings: {
                slidesToShow: 3
            }
        },
        {
            breakpoint: 992,
            settings: {
                slidesToShow: 2
            }
        },
        {
            breakpoint: 768,
            settings: {
                slidesToShow: 1
            }
        }
    ]
};
class PostDetails extends Component {
    render() {

        return (
            <Fragment>

                <div class="live-match-area">
                    <Slider {...settings}>

                        <div class="item">
                            <div class="live-match-item">
                                <div class="title">
                                    <h5>WORLD CUP MATCH 14</h5>
                                </div>
                                <div class="compare-country">
                                    <span class="country-logo"><img src={country} alt="country img" /></span>
                                    <span class="country-vs">
                                        <p>VS</p>
                                    </span>
                                    <span class="country-logo"><img src={country} alt="country img" /></span>
                                </div>
                                <div class="compare-run">
                                    <div class="run">
                                        <h4>BAN 300 / 5 <span>50 OVERS</span></h4>
                                    </div>
                                    <div class="div"> <img src={compare} alt="svg" /> </div>
                                    <div class="run">
                                        <h4>BAN 300 / 5 <span>50 OVERS</span> </h4>
                                    </div>

                                </div>
                                <div class="compare-match">
                                    <p>Starts in 4 hours</p>
                                </div>
                            </div>
                        </div>
                        <div class="item">
                            <div class="live-match-item">
                                <div class="title">
                                    <h5>WORLD CUP MATCH 14</h5>
                                </div>
                                <div class="compare-country">
                                    <span class="country-logo"><img src={country} alt="country img" /></span>
                                    <span class="country-vs">
                                        <p>VS</p>
                                    </span>
                                    <span class="country-logo"><img src={country} alt="country img" /></span>
                                </div>
                                <div class="compare-run">
                                    <div class="run">
                                        <h4>BAN 300 / 5 <span>50 OVERS</span></h4>
                                    </div>
                                    <div class="div"> <img src={compare} alt="svg" /> </div>
                                    <div class="run">
                                        <h4>BAN 300 / 5 <span>50 OVERS</span> </h4>
                                    </div>

                                </div>
                                <div class="compare-match">
                                    <p>Starts in 4 hours</p>
                                </div>
                            </div>
                        </div>
                        <div class="item">
                            <div class="live-match-item">
                                <div class="title">
                                    <h5>WORLD CUP MATCH 14</h5>
                                </div>
                                <div class="compare-country">
                                    <span class="country-logo"><img src={country} alt="country img" /></span>
                                    <span class="country-vs">
                                        <p>VS</p>
                                    </span>
                                    <span class="country-logo"><img src={country} alt="country img" /></span>
                                </div>
                                <div class="compare-run">
                                    <div class="run">
                                        <h4>BAN 300 / 5 <span>50 OVERS</span></h4>
                                    </div>
                                    <div class="div"> <img src={compare} alt="svg" /> </div>
                                    <div class="run">
                                        <h4>BAN 300 / 5 <span>50 OVERS</span> </h4>
                                    </div>

                                </div>
                                <div class="compare-match">
                                    <p>Starts in 4 hours</p>
                                </div>
                            </div>
                        </div>
                        <div class="item">
                            <div class="live-match-item">
                                <div class="title">
                                    <h5>WORLD CUP MATCH 14</h5>
                                </div>
                                <div class="compare-country">
                                    <span class="country-logo"><img src={country} alt="country img" /></span>
                                    <span class="country-vs">
                                        <p>VS</p>
                                    </span>
                                    <span class="country-logo"><img src={country} alt="country img" /></span>
                                </div>
                                <div class="compare-run">
                                    <div class="run">
                                        <h4>BAN 300 / 5 <span>50 OVERS</span></h4>
                                    </div>
                                    <div class="div"> <img src={compare} alt="svg" /> </div>
                                    <div class="run">
                                        <h4>BAN 300 / 5 <span>50 OVERS</span> </h4>
                                    </div>

                                </div>
                                <div class="compare-match">
                                    <p>Starts in 4 hours</p>
                                </div>
                            </div>
                        </div>
                        <div class="item">
                            <div class="live-match-item">
                                <div class="title">
                                    <h5>WORLD CUP MATCH 14</h5>
                                </div>
                                <div class="compare-country">
                                    <span class="country-logo"><img src={country} alt="country img" /></span>
                                    <span class="country-vs">
                                        <p>VS</p>
                                    </span>
                                    <span class="country-logo"><img src={country} alt="country img" /></span>
                                </div>
                                <div class="compare-run">
                                    <div class="run">
                                        <h4>BAN 300 / 5 <span>50 OVERS</span></h4>
                                    </div>
                                    <div class="div"> <img src={compare} alt="svg" /> </div>
                                    <div class="run">
                                        <h4>BAN 300 / 5 <span>50 OVERS</span> </h4>
                                    </div>

                                </div>
                                <div class="compare-match">
                                    <p>Starts in 4 hours</p>
                                </div>
                            </div>
                        </div>
                        <div class="item">
                            <div class="live-match-item">
                                <div class="title">
                                    <h5>WORLD CUP MATCH 14</h5>
                                </div>
                                <div class="compare-country">
                                    <span class="country-logo"><img src={country} alt="country img" /></span>
                                    <span class="country-vs">
                                        <p>VS</p>
                                    </span>
                                    <span class="country-logo"><img src={country} alt="country img" /></span>
                                </div>
                                <div class="compare-run">
                                    <div class="run">
                                        <h4>BAN 300 / 5 <span>50 OVERS</span></h4>
                                    </div>
                                    <div class="div"> <img src={compare} alt="svg" /> </div>
                                    <div class="run">
                                        <h4>BAN 300 / 5 <span>50 OVERS</span> </h4>
                                    </div>

                                </div>
                                <div class="compare-match">
                                    <p>Starts in 4 hours</p>
                                </div>
                            </div>
                        </div>
                        <div class="item">
                            <div class="live-match-item">
                                <div class="title">
                                    <h5>WORLD CUP MATCH 14</h5>
                                </div>
                                <div class="compare-country">
                                    <span class="country-logo"><img src={country} alt="country img" /></span>
                                    <span class="country-vs">
                                        <p>VS</p>
                                    </span>
                                    <span class="country-logo"><img src={country} alt="country img" /></span>
                                </div>
                                <div class="compare-run">
                                    <div class="run">
                                        <h4>BAN 300 / 5 <span>50 OVERS</span></h4>
                                    </div>
                                    <div class="div"> <img src={compare} alt="svg" /> </div>
                                    <div class="run">
                                        <h4>BAN 300 / 5 <span>50 OVERS</span> </h4>
                                    </div>

                                </div>
                                <div class="compare-match">
                                    <p>Starts in 4 hours</p>
                                </div>
                            </div>
                        </div>
                        <div class="item">
                            <div class="live-match-item">
                                <div class="title">
                                    <h5>WORLD CUP MATCH 14</h5>
                                </div>
                                <div class="compare-country">
                                    <span class="country-logo"><img src={country} alt="country img" /></span>
                                    <span class="country-vs">
                                        <p>VS</p>
                                    </span>
                                    <span class="country-logo"><img src={country} alt="country img" /></span>
                                </div>
                                <div class="compare-run">
                                    <div class="run">
                                        <h4>BAN 300 / 5 <span>50 OVERS</span></h4>
                                    </div>
                                    <div class="div"> <img src={compare} alt="svg" /> </div>
                                    <div class="run">
                                        <h4>BAN 300 / 5 <span>50 OVERS</span> </h4>
                                    </div>

                                </div>
                                <div class="compare-match">
                                    <p>Starts in 4 hours</p>
                                </div>
                            </div>
                        </div>
                        <div class="item">
                            <div class="live-match-item">
                                <div class="title">
                                    <h5>WORLD CUP MATCH 14</h5>
                                </div>
                                <div class="compare-country">
                                    <span class="country-logo"><img src={country} alt="country img" /></span>
                                    <span class="country-vs">
                                        <p>VS</p>
                                    </span>
                                    <span class="country-logo"><img src={country} alt="country img" /></span>
                                </div>
                                <div class="compare-run">
                                    <div class="run">
                                        <h4>BAN 300 / 5 <span>50 OVERS</span></h4>
                                    </div>
                                    <div class="div"> <img src={compare} alt="svg" /> </div>
                                    <div class="run">
                                        <h4>BAN 300 / 5 <span>50 OVERS</span> </h4>
                                    </div>

                                </div>
                                <div class="compare-match">
                                    <p>Starts in 4 hours</p>
                                </div>
                            </div>
                        </div>
                        <div class="item">
                            <div class="live-match-item">
                                <div class="title">
                                    <h5>WORLD CUP MATCH 14</h5>
                                </div>
                                <div class="compare-country">
                                    <span class="country-logo"><img src={country} alt="country img" /></span>
                                    <span class="country-vs">
                                        <p>VS</p>
                                    </span>
                                    <span class="country-logo"><img src={country} alt="country img" /></span>
                                </div>
                                <div class="compare-run">
                                    <div class="run">
                                        <h4>BAN 300 / 5 <span>50 OVERS</span></h4>
                                    </div>
                                    <div class="div"> <img src={compare} alt="svg" /> </div>
                                    <div class="run">
                                        <h4>BAN 300 / 5 <span>50 OVERS</span> </h4>
                                    </div>

                                </div>
                                <div class="compare-match">
                                    <p>Starts in 4 hours</p>
                                </div>
                            </div>
                        </div>
                        <div class="item">
                            <div class="live-match-item">
                                <div class="title">
                                    <h5>WORLD CUP MATCH 14</h5>
                                </div>
                                <div class="compare-country">
                                    <span class="country-logo"><img src={country} alt="country img" /></span>
                                    <span class="country-vs">
                                        <p>VS</p>
                                    </span>
                                    <span class="country-logo"><img src={country} alt="country img" /></span>
                                </div>
                                <div class="compare-run">
                                    <div class="run">
                                        <h4>BAN 300 / 5 <span>50 OVERS</span></h4>
                                    </div>
                                    <div class="div"> <img src={compare} alt="svg" /> </div>
                                    <div class="run">
                                        <h4>BAN 300 / 5 <span>50 OVERS</span> </h4>
                                    </div>

                                </div>
                                <div class="compare-match">
                                    <p>Starts in 4 hours</p>
                                </div>
                            </div>
                        </div>
                    </Slider>
                </div>

                <div class="news-content-area fx-padding">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-lg-3 order-2 order-lg-1">
                                <div class="sidebar-widget-wrapper">
                                    <div class="sidebar-widget trend-widget">
                                        <h4 class="heading-title">Trending Now</h4>

                                        <nav>
                                            <ul>
                                                <li>
                                                    <div class="img"><a href="#"><img src={trending} alt="image" /></a></div>
                                                    <div class="content"><a href="#">
                                                        <p>Lorem Ipsum is simply dummy text to print ...</p>
                                                    </a></div>
                                                </li>
                                                <li>
                                                    <div class="img"><a href="#"><img src={trending} alt="image" /></a></div>
                                                    <div class="content"><a href="#">
                                                        <p>Lorem Ipsum is simply dummy text to print ...</p>
                                                    </a></div>
                                                </li>
                                            </ul>
                                        </nav>
                                    </div>
                                    <div class="sidebar-widget recend-widget">
                                        <h4 class="heading-title">Recent Series</h4>
                                        <nav>
                                            <ul>
                                                <li><span><img src={recent} alt="" /></span><a href="#">
                                                    <p>India v Bangladesh</p>
                                                </a></li>
                                                <li><span><img src={recent} alt="" /></span><a href="#">
                                                    <p>India v Bangladesh</p>
                                                </a></li>
                                                <li><span><img src={recent} alt="" /></span><a href="#">
                                                    <p>India v Bangladesh</p>
                                                </a></li>
                                                <li><span><img src={recent} alt="" /></span><a href="#">
                                                    <p>India v Bangladesh</p>
                                                </a></li>
                                            </ul>
                                        </nav>
                                    </div>
                                    <div class="sidebar-widget player-widget">
                                        <h4 class="heading-title">Players Data</h4>

                                        <nav>
                                            <div class="top-search-bar">
                                                <button type="submit"><i class="fa fa-search"></i></button>
                                                <input type="text" placeholder="Search player" />
                                            </div>
                                            <ul>
                                                <li><span><img src={player} alt="" /></span><a href="#">
                                                    <p>Shakib Al Hasan</p>
                                                </a></li>
                                                <li><span><img src={player} alt="" /></span><a href="#">
                                                    <p>Shakib Al Hasan</p>
                                                </a></li>
                                                <li><span><img src={player} alt="" /></span><a href="#">
                                                    <p>Shakib Al Hasan</p>
                                                </a></li>
                                            </ul>
                                        </nav>
                                    </div>

                                </div>
                            </div>
                            <div class="col-lg-6 order-1 order-lg-2">
                                <div class="news-main-content">
                                    <div class="post-content-inner-blk">
                                        <div class="sigle-post-wrp">
                                            <div class="single-post-top-blk">
                                                <h3>LOREM IPSUM DOLOR SIT AMET CONSE LOREM
                                                IPSUM DOLOR SIT AMET CONSE LOREM IPSUM
                                        DOLOR SIT AMET</h3>
                                                <p>LOREM IPSUM DOLOR SIT AMET CONSE LOREM IPSUM DOLOR SIT
                                        AMET CONSE LOREM IPSUM DOLOR SIT AMET</p>
                                            </div>
                                            <div class="single-post-tm">
                                                <img src={thumb} alt="" />
                                                <p>Image Caption Lorem ipsum dolor sit amet consectetur adipisicing elit</p>
                                            </div>
                                            <div class="post-writer-blk">
                                                <div class="post-writer-info">
                                                    <div class="row">
                                                        <div class="col-md-5">
                                                            <div class="pst-w-left-blk">
                                                                <img src={avatar} alt="" />
                                                                <div class="post-auth-info">
                                                                    <h3>Jason Davis
                                                            <span>Editor</span></h3>
                                                                    <p>26 November 2019</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-md-7">
                                                            <div class="pst-w-right-blk">
                                                                <p>Posted - November 27, 2019 6:22 PM</p>
                                                                <p>Updated - November 27, 2019 6:23 PM</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="post-main-content-all">

                                                    <div class="row">
                                                        <div class="col-lg-12">


                                                            <div class="hlt-text">
                                                                <h4>Highlights</h4>
                                                                <div class="hlt-text-list">
                                                                    <li>Lorem Ipsum is dolore agna
                                                                    aliqua. Ut enim ad minimve
                                                            niamuis nostrud</li>
                                                                    <li>Lorem Ipsum is dolore agna
                                                                    aliqua. Ut enim ad minimve
                                                            niamuis nostrud</li>
                                                                    <li>Lorem Ipsum is dolore agna
                                                                    aliqua. Ut enim ad minimve
                                                            niamuis nostrud</li>
                                                                </div>
                                                            </div>
                                                Dolore magna aliqua. Ut enim ad minim veniamuis nostrud. Duis aute irure dolor in reprehenderit in voluptate velit esse pariatur Lorem ipsum dolor sit amet etur adipisicing elit, sed do tempor ncididunt ut labore et dolore magna aliqua. Uteni ad minim veniamuis nostrud. Duis aute irure dolor in reprehenderit in voluptate velit esse pariatur Lorem ipsum dolor sit
                                                amet, consectetur adipisicing elit, sed do tempor incididunt ut labore etolore tempor incididunt ut labore et dolore.
                                                Dolore magna aliqua. Ut enim ad minim veniamuis nostrud. Duis aute irure
                                                dolor in reprehenderit in voluptate velit esse pariatur Lorem ipsum dolor sit
                                                amet, consectetur adipisicing elit, sed do tempor incididunt ut labore et
                                                dolore magna aliqua. Ut enim ad minim veniamuis nostrud. Duis aute irure
                                                dolor in reprehenderit in voluptate velit esse pariatur Lorem ipsum dolor sit
                                                amet, consectetur adipisicing elit, sed do tempor incididunt ut labore et
                                                dolore magna aliqua. Ut enim ad minim veniamuis nostrud. Duis aute irure
                                                dolor in reprehenderit in voluptate velit esse pariatur Lorem ipsum dolor sit
                                                amet, consectetur adipisicing elit, sed do tempor incididunt ut labore et
                                                dolore magna aliqua. Ut enim ad minim veniamuis nostrud. Duis aute irure
                                                dolor in reprehenderit in voluptate velit esse pariatur Lorem ipsum reprehe
                                                nderit in voluptate velit esse pariatur Loredolor sit reprehenderit.
                                            </div>
                                                    </div>
                                                    <div class="row">
                                                        <div class="col-lg-12">
                                                            <div class="quted-text">
                                                                <img class="qt-img" src="assets/img/qt-left.svg" alt="" />
                                                                <img class="qt-img qt-rt" src="assets/img/qt-right.svg" alt="" />
                                                                <p>consectetur adipisicing elit, sed do tempor incididunt ut
                                                                labore et dolore magna aliqua. Ut enim ad minim eiamuis
                                                                nostrud. Duis aute irure dolor in reprehenderit in oluptate
                                                        velit esse pariatur Lorem ipsum dolor.</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <div class="col-lg-12">
                                                            <p>consectetur adipisicing elit, sed do tempor incididunt ut labore et dolore
                                                            magna aliqua. Ut enim ad minim veniamuis nostrud. Duis aute irure dolor in
                                                            reprehenderit in voluptate velit esse pariatur Lorem ipsum dolor sit amet,
                                                            consectetur adipisicing elit, sed do tempor incididunt ut labore et dolore
                                                            magna aliqua. Ut enim ad minim veniamuis nostrud. Duis aute irure dolor in
                                                            reprehenderit in voluptate velit esse pariatur Lorem ipsum dolor sit amet,
                                                            consectetur adipisicing elit, sed do tempor incididunt ut labore et dolore
                                                            magna aliqua. Ut enim ad minim veniamuis nostrud. Duis aute irure dolor in
                                                            reprehenderit in voluptate velit esse pariatur Lorem ipsum dolor sit amet,
                                                            consectetur adipisicing elit, sed do tempor incididunt ut labore et dolore
                                                    magna aliqua. Ut enim ad </p>

                                                            <p>minim veniamuis nostrud. Duis aute irure dolor in reprehenderit in voluptate
                                                            velit esse pariatur Lorem ipsum dolor sit amet, cnsectetur adipisicing elit,
                                                            sed do tempor incididunt ut labore et dolore magna aliqua. Ut enim ad inim
                                                            veniamuis nostrud. Duis aute irure dolor in reprehenderit in voluptate velit
                                                            esse pariatur Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
                                                    tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim</p>
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <div class="col-lg-12">
                                                            <div class="share-btn">
                                                                <a href=""><img src="assets/img/fb_share.svg" alt="" /></a>
                                                                <a href=""><img src="assets/img/twitte.svg" alt="" /></a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <div class="col-lg-12">
                                                            <div class="tags-btn">
                                                                <span>Tags</span>
                                                                <a href="">Lorem ipsum</a>
                                                                <a href="">Lorem ipsum</a>
                                                                <a href="">Lorem ipsum</a>
                                                                <a href="">Lorem ipsum</a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <div class="col-lg-12">
                                                            <div class="comment-wrp">
                                                                <div class="comment-box">
                                                                    <form action="">
                                                                        <textarea name="" id="" cols="30" rows="10" placeholder="type your comment here..."></textarea>
                                                                        <div class="bottom-action-btns">
                                                                            to post a comment
                                                                <a href="">Login</a><br />
                                                                                or
                                                                <button type="submit">Post as anonymous</button>
                                                                        </div>
                                                                    </form>
                                                                </div>
                                                                <div class="comment-main-blk">
                                                                    <h3><img src="assets/img/srt.svg" alt="" />Sort by New</h3>
                                                                    <div class="comment-text-blk">
                                                                        <div class="cm-user">
                                                                            <img src={avatar} alt="" />
                                                                            <div class="cm-info">
                                                                                <h4>Users Name <span>20 mar 2020</span></h4>
                                                                            </div>
                                                                        </div>
                                                                        <p>Dolore magna aliqua. Ut enim ad minim veniamuis nostrud. Duis aute irure dolor in reprehenderit in voluptate velit esse pariatur Lorem ipsum dolor sit amet etur adipisicing elit, sed do tempor ncididunt ut labore et dolore magna aliqua. Uteni ad minim veniamuis nostrud.</p>
                                                                        <div class="comment-actions">
                                                                            <a href="">Delete</a>
                                                                            <a href="">Reply</a>
                                                                            <a href="" class="t-up"><i class="fas fa-thumbs-up"></i> <span>10</span></a>
                                                                            <a href="" class="t-dn"><i class="fas fa-thumbs-down"></i> <span>10</span></a>

                                                                        </div>
                                                                    </div>
                                                                    <div class="comment-text-blk">
                                                                        <div class="cm-user">
                                                                            <img src={avatar} alt="" />
                                                                            <div class="cm-info">
                                                                                <h4>Users Name <span>20 mar 2020</span></h4>
                                                                            </div>
                                                                        </div>
                                                                        <p>Dolore magna aliqua. Ut enim ad minim veniamuis nostrud. Duis aute irure dolor in reprehenderit in voluptate velit esse pariatur Lorem ipsum dolor sit amet etur adipisicing elit, sed do tempor ncididunt ut labore et dolore magna aliqua. Uteni ad minim veniamuis nostrud.</p>
                                                                        <div class="comment-actions">
                                                                            <a href="">Reply</a>
                                                                            <a href="" class="t-up"><i class="fas fa-thumbs-up"></i> <span>10</span></a>
                                                                            <a href="" class="t-dn"><i class="fas fa-thumbs-down"></i> <span>10</span></a>

                                                                        </div>

                                                                        <div class="comment-box">
                                                                            <form action="">
                                                                                <div class="text-area-blk">
                                                                                    <textarea name="" id="" cols="30" rows="10" placeholder="type your comment here..."></textarea>
                                                                                </div>
                                                                                <div class="bottom-action-btns">
                                                                                    to post a comment
                                                                        <a href="">Login</a><br />
                                                                                            or
                                                                        <button type="submit">Post as anonymous</button>
                                                                                </div>
                                                                            </form>
                                                                        </div>
                                                                    </div>
                                                                    <div class="comment-text-blk">
                                                                        <div class="cm-user">
                                                                            <img src={avatar} alt="" />
                                                                            <div class="cm-info">
                                                                                <h4>Users Name <span>20 mar 2020</span></h4>
                                                                            </div>
                                                                        </div>
                                                                        <p>Dolore magna aliqua. Ut enim ad minim veniamuis nostrud. Duis aute irure dolor in reprehenderit in voluptate velit esse pariatur Lorem ipsum dolor sit amet etur adipisicing elit, sed do tempor ncididunt ut labore et dolore magna aliqua. Uteni ad minim veniamuis nostrud.</p>
                                                                        <div class="comment-actions">
                                                                            <a href="">Reply</a>
                                                                            <a href="" class="t-up"><i class="fas fa-thumbs-up"></i> <span>10</span></a>
                                                                            <a href="" class="t-dn"><i class="fas fa-thumbs-down"></i> <span>10</span></a>

                                                                        </div>

                                                                        <div class="comment-box">

                                                                            <div class="text-area-blk">
                                                                                <div class="text-area-blk-signle-blk mt-blk">
                                                                                    <div class="cm-user">
                                                                                        <img src={avatar} alt="" />
                                                                                        <div class="cm-info">
                                                                                            <h4>Users Name <span>20 mar 2020</span></h4>
                                                                                        </div>
                                                                                    </div>
                                                                                    <p>Dolore magna aliqua. Ut enim ad minim veniamuis nostrud. Duis aute irure dolor in reprehenderit in voluptate velit esse pariatur Lorem ipsum dolor sit amet etur adipisicing elit, sed do tempor ncididunt ut labore et dolore magna aliqua. Uteni ad minim veniamuis nostrud.</p>
                                                                                    <div class="comment-actions">
                                                                                        <a href="">Reply</a>
                                                                                        <a href="" class="t-up"><i class="fas fa-thumbs-up"></i> <span>10</span></a>
                                                                                        <a href="" class="t-dn"><i class="fas fa-thumbs-down"></i> <span>10</span></a>

                                                                                    </div>
                                                                                </div>
                                                                                <div class="text-area-blk-signle-blk">
                                                                                    <div class="cm-user">
                                                                                        <img src={avatar} alt="" />
                                                                                        <div class="cm-info">
                                                                                            <h4>Users Name <span>20 mar 2020</span></h4>
                                                                                        </div>
                                                                                    </div>
                                                                                    <p>Dolore magna aliqua. Ut enim ad minim veniamuis nostrud. Duis aute irure dolor in reprehenderit in voluptate velit esse pariatur Lorem ipsum dolor sit amet etur adipisicing elit, sed do tempor ncididunt ut labore et dolore magna aliqua. Uteni ad minim veniamuis nostrud.</p>
                                                                                    <div class="comment-actions">
                                                                                        <a href="">Reply</a>
                                                                                        <a href="" class="t-up"><i class="fas fa-thumbs-up"></i> <span>10</span></a>
                                                                                        <a href="" class="t-dn"><i class="fas fa-thumbs-down"></i> <span>10</span></a>

                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                        </div>
                                                                    </div>
                                                                    <div class="comment-text-blk">
                                                                        <div class="cm-user">
                                                                            <img src={avatar} alt="" />
                                                                            <div class="cm-info">
                                                                                <h4>Users Name <span>20 mar 2020</span></h4>
                                                                            </div>
                                                                        </div>
                                                                        <p>Dolore magna aliqua. Ut enim ad minim veniamuis nostrud. Duis aute irure dolor in reprehenderit in voluptate velit esse pariatur Lorem ipsum dolor sit amet etur adipisicing elit, sed do tempor ncididunt ut labore et dolore magna aliqua. Uteni ad minim veniamuis nostrud.</p>
                                                                        <div class="comment-actions">
                                                                            <a href="">Reply</a>
                                                                            <a href="" class="t-up"><i class="fas fa-thumbs-up"></i> <span>10</span></a>
                                                                            <a href="" class="t-dn"><i class="fas fa-thumbs-down"></i> <span>10</span></a>

                                                                        </div>

                                                                        <div class="comment-box">

                                                                            <div class="text-area-blk">
                                                                                <div class="text-area-blk-signle-blk mt-blk">
                                                                                    <div class="cm-user">
                                                                                        <img src={avatar} alt="" />
                                                                                        <div class="cm-info">
                                                                                            <h4>Users Name <span>20 mar 2020</span></h4>
                                                                                        </div>
                                                                                    </div>
                                                                                    <p>Dolore magna aliqua. Ut enim ad minim veniamuis nostrud. Duis aute irure dolor in reprehenderit in voluptate velit esse pariatur Lorem ipsum dolor sit amet etur adipisicing elit, sed do tempor ncididunt ut labore et dolore magna aliqua. Uteni ad minim veniamuis nostrud.</p>
                                                                                    <div class="comment-actions">
                                                                                        <a href="">Reply</a>
                                                                                        <a href="" class="t-up"><i class="fas fa-thumbs-up"></i> <span>10</span></a>
                                                                                        <a href="" class="t-dn"><i class="fas fa-thumbs-down"></i> <span>10</span></a>

                                                                                    </div>
                                                                                </div>

                                                                            </div>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="ld-more-btn cmnt">
                                                                <a href="">Lode More <i class="fal fa-angle-down"></i></a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                    <div class="news-widget pb-0">
                                        <div class="title">
                                            <div class="left">
                                                <h6>Related News</h6>
                                            </div>

                                        </div>

                                        <div class="row">
                                            <div class="col-lg-12">
                                                <Slider {...settings2}>
                                                    <div className="col-12">
                                                        <div class="post4">
                                                            <div class="img"><a href="#"><img src={post3} alt="img" /></a></div>
                                                            <div class="content">
                                                                <a href="#">
                                                                    <h6>LOREM IPSUM DOLOR SIT AMET</h6>
                                                                </a>
                                                                <span>10 NOV 2019</span>
                                                            </div>

                                                        </div>
                                                    </div>
                                                    <div className="col-12">
                                                        <div class="post4">
                                                            <div class="img"><a href="#"><img src={post3} alt="img" /></a></div>
                                                            <div class="content">
                                                                <a href="#">
                                                                    <h6>LOREM IPSUM DOLOR SIT AMET</h6>
                                                                </a>
                                                                <span>10 NOV 2019</span>
                                                            </div>

                                                        </div>
                                                    </div>
                                                    <div className="col-12">
                                                        <div class="post4">
                                                            <div class="img"><a href="#"><img src={post3} alt="img" /></a></div>
                                                            <div class="content">
                                                                <a href="#">
                                                                    <h6>LOREM IPSUM DOLOR SIT AMET</h6>
                                                                </a>
                                                                <span>10 NOV 2019</span>
                                                            </div>

                                                        </div>
                                                    </div>
                                                    <div className="col-12">
                                                        <div class="post4">
                                                            <div class="img"><a href="#"><img src={post3} alt="img" /></a></div>
                                                            <div class="content">
                                                                <a href="#">
                                                                    <h6>LOREM IPSUM DOLOR SIT AMET</h6>
                                                                </a>
                                                                <span>10 NOV 2019</span>
                                                            </div>

                                                        </div>
                                                    </div>
                                                    <div className="col-12">
                                                        <div class="post4">
                                                            <div class="img"><a href="#"><img src={post3} alt="img" /></a></div>
                                                            <div class="content">
                                                                <a href="#">
                                                                    <h6>LOREM IPSUM DOLOR SIT AMET</h6>
                                                                </a>
                                                                <span>10 NOV 2019</span>
                                                            </div>

                                                        </div>
                                                    </div>
                                                    <div className="col-12">
                                                        <div class="post4">
                                                            <div class="img"><a href="#"><img src={post3} alt="img" /></a></div>
                                                            <div class="content">
                                                                <a href="#">
                                                                    <h6>LOREM IPSUM DOLOR SIT AMET</h6>
                                                                </a>
                                                                <span>10 NOV 2019</span>
                                                            </div>

                                                        </div>
                                                    </div>
                                                    <div className="col-12">
                                                        <div class="post4">
                                                            <div class="img"><a href="#"><img src={post3} alt="img" /></a></div>
                                                            <div class="content">
                                                                <a href="#">
                                                                    <h6>LOREM IPSUM DOLOR SIT AMET</h6>
                                                                </a>
                                                                <span>10 NOV 2019</span>
                                                            </div>

                                                        </div>
                                                    </div>
                                                    <div className="col-12">
                                                        <div class="post4">
                                                            <div class="img"><a href="#"><img src={post3} alt="img" /></a></div>
                                                            <div class="content">
                                                                <a href="#">
                                                                    <h6>LOREM IPSUM DOLOR SIT AMET</h6>
                                                                </a>
                                                                <span>10 NOV 2019</span>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </Slider>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-3 order-3 order-lg-3">
                                <div class="sidebar-widget-wrapper">


                                    <div class="sidebar-widget rank-widget">
                                        <h4 class="heading-title">ICC TEAM RANKING</h4>

                                        <div>
                                            <div class="nav nav-tabs" id="nav-tab" role="tablist">
                                                <a class="nav-item nav-link active" data-toggle="tab" href="#nav-4">ODI</a>
                                                <a class="nav-item nav-link" data-toggle="tab" href="#nav-5">T20</a>
                                                <a class="nav-item nav-link" data-toggle="tab" href="#nav-6">TEST</a>
                                            </div>
                                        </div>
                                        <div class="tab-content" id="nav-tabContent">
                                            <div class="tab-pane fade show active" id="nav-4">
                                                <nav>
                                                    <ul>
                                                        <li>
                                                            <ol>
                                                                <li>
                                                                    <p>Pos</p>
                                                                </li>
                                                                <li>
                                                                    <p>Team</p>
                                                                </li>
                                                                <li>
                                                                    <p>Rating</p>
                                                                </li>
                                                            </ol>

                                                        </li>
                                                        <li>
                                                            <ol>
                                                                <li>
                                                                    <p>1</p>
                                                                </li>
                                                                <li>
                                                                    <p>Pakistan</p>
                                                                </li>
                                                                <li>
                                                                    <p>270</p>
                                                                </li>
                                                            </ol>

                                                        </li>
                                                        <li>
                                                            <ol>
                                                                <li>
                                                                    <p>1</p>
                                                                </li>
                                                                <li>
                                                                    <p>Pakistan</p>
                                                                </li>
                                                                <li>
                                                                    <p>270</p>
                                                                </li>
                                                            </ol>

                                                        </li>
                                                        <li>
                                                            <ol>
                                                                <li>
                                                                    <p>1</p>
                                                                </li>
                                                                <li>
                                                                    <p>Pakistan</p>
                                                                </li>
                                                                <li>
                                                                    <p>270</p>
                                                                </li>
                                                            </ol>

                                                        </li>
                                                        <li>
                                                            <ol>
                                                                <li>
                                                                    <p>1</p>
                                                                </li>
                                                                <li>
                                                                    <p>Pakistan</p>
                                                                </li>
                                                                <li>
                                                                    <p>270</p>
                                                                </li>
                                                            </ol>

                                                        </li>
                                                        <li><a href="#">watch full ranking</a></li>
                                                    </ul>
                                                </nav>

                                            </div>
                                            <div class="tab-pane fade" id="nav-5">

                                            </div>
                                            <div class="tab-pane fade" id="nav-6">


                                            </div>
                                        </div>





                                    </div>
                                    <div class="sidebar-widget popular-widget">
                                        <h4 class="heading-title">Most Popular</h4>

                                        <div>
                                            <div class="nav nav-tabs" id="nav-tab" role="tablist">
                                                <a class="nav-item nav-link active" data-toggle="tab" href="#nav-7">Today</a>
                                                <a class="nav-item nav-link" data-toggle="tab" href="#nav-8">Last 7 Days</a>
                                                <a class="nav-item nav-link" data-toggle="tab" href="#nav-9">Last 30 Days</a>
                                            </div>
                                        </div>
                                        <div class="tab-content" id="nav-tabContent">
                                            <div class="tab-pane fade show active" id="nav-7">
                                                <nav>
                                                    <ul>
                                                        <li>
                                                            <span>1</span>
                                                            <a href="#">
                                                                <p>Lorem Ipsum is simply dummy ..</p>
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <span>3</span>
                                                            <a href="#">
                                                                <p>Lorem int and typesetting int and typesetting int and typesetting int and typesetting...</p>
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </nav>

                                            </div>
                                            <div class="tab-pane fade" id="nav-8">

                                            </div>
                                            <div class="tab-pane fade" id="nav-9">


                                            </div>
                                        </div>



                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}
export default PostDetails