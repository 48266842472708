import React, { Component, Fragment } from 'react'
import { NavLink } from 'react-router-dom'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// images 
import trending from '../../images/trending-thumbnail.png'
import flag from '../../images/flag-round.png'


class Ranking extends Component {
    render() {

        return (
            <Fragment>

                <div class="news-content-area fx-padding ranking-page">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-lg-9">
                            <div class="profile-inner-blk">
                       <div class="row">
                           <div class="col-lg-12">
                               <div class="top-title-text">
                                   <h2>ICC RANKINGS - TEST, ODI, T20</h2>
                               </div>
                           </div>
                       </div>
                        <div class="row">
                            
                            <div class="col-lg-12">
                                <div class="prof-tab">
                                   <h3>Men's Ranking</h3>
                                    <div class="tabContainer">
                                        <nav>
                                            <div class="nav nav-link-wrap bdr-btm-three rds" id="nav-tab" role="tablist">
                                               

                                                <a class="nav-item nav-link active" id="tbTwo-tab"  href="">TeamS</a>

                                                <a class="nav-item nav-link" id="tbtwo-tab" href="">Batsmen</a>
                                                
                                                <a class="nav-item nav-link" id="tbfour-tab" href="" >Bowlers</a>
                                                <a class="nav-item nav-link" id="tbfive-tab" href="">All Rounders</a>

                                                
                                            </div>
                                        </nav>
                                        <div class="tab-content" id="nav-tabContent">
                                            <div class="tab-pane fade show active">
                                                <div class="tb-content-wrap">
                                                    <div class="tabContainer-inner-1 rnk">
                                                        <nav>
                                                            <div class="nav nav-link-wrap inner-1" id="nav-tab" role="tablist">
                                                                <a class="nav-item nav-link active" href="">ODI

                                                                   
                                                                </a>
                                                                <a class="nav-item nav-link" href="">TEST
                                                                   
                                                                </a>
                                                                <a class="nav-item nav-link" href="">T20
                                                                    
                                                                </a>
                                                                
                                                            </div>
                                                        </nav>
                                                        <div class="tab-content" id="nav-tabContent">
                                                            <div class="tab-pane fade show active" id="tb-card-1" role="tabpanel" aria-labelledby="tb-inner-1-tab">
                                                                 
                                                                 <div class="prf-table-inner">
                                                                    <div class="pl-table-info-all">
                                                                        <table class="table ranking-table">
                                                                           <thead>
                                                                               <tr>
                                                                                   <th>Pos</th>
                                                                                   <th>Team</th>
                                                                                   <th class="center-blk"><span>Points</span></th>
                                                                                   <th class="center-blk"><span>Rating</span></th>
                                                                               </tr>
                                                                           </thead>
                                                                            <tbody>
                                                                                <tr>
                                                                                    <td>1</td>
                                                                                    <td class="cnt"><img src={flag} alt=""/>England</td>
                                                                                    <td class="center-blk">124</td>
                                                                                    <td class="center-blk">6967</td>
                                                                                    
                                                                                </tr>
                                                                                <tr>
                                                                                    <td>1</td>
                                                                                    <td class="cnt"><img src={flag} alt=""/>England</td>
                                                                                    <td class="center-blk">124</td>
                                                                                    <td class="center-blk">6967</td>
                                                                                    
                                                                                </tr>
                                                                                <tr>
                                                                                    <td>1</td>
                                                                                    <td class="cnt"><img src={flag} alt=""/>England</td>
                                                                                    <td class="center-blk">124</td>
                                                                                    <td class="center-blk">6967</td>
                                                                                    
                                                                                </tr>
                                                                                <tr>
                                                                                    <td>1</td>
                                                                                    <td class="cnt"><img src={flag} alt=""/>England</td>
                                                                                    <td class="center-blk">124</td>
                                                                                    <td class="center-blk">6967</td>
                                                                                    
                                                                                </tr>
                                                                                <tr>
                                                                                    <td>1</td>
                                                                                    <td class="cnt"><img src={flag} alt=""/>England</td>
                                                                                    <td class="center-blk">124</td>
                                                                                    <td class="center-blk">6967</td>
                                                                                    
                                                                                </tr>
                                                                                <tr>
                                                                                    <td>1</td>
                                                                                    <td class="cnt"><img src={flag} alt=""/>England</td>
                                                                                    <td class="center-blk">124</td>
                                                                                    <td class="center-blk">6967</td>
                                                                                    
                                                                                </tr>
                                                                                <tr>
                                                                                    <td>1</td>
                                                                                    <td class="cnt"><img src={flag} alt=""/>England</td>
                                                                                    <td class="center-blk">124</td>
                                                                                    <td class="center-blk">6967</td>
                                                                                    
                                                                                </tr>
                                                                                <tr>
                                                                                    <td>1</td>
                                                                                    <td class="cnt"><img src={flag} alt=""/>England</td>
                                                                                    <td class="center-blk">124</td>
                                                                                    <td class="center-blk">6967</td>
                                                                                    
                                                                                </tr>
                                                                                <tr>
                                                                                    <td>1</td>
                                                                                    <td class="cnt"><img src={flag} alt=""/>England</td>
                                                                                    <td class="center-blk">124</td>
                                                                                    <td class="center-blk">6967</td>
                                                                                    
                                                                                </tr>
                                                                                <tr>
                                                                                    <td>1</td>
                                                                                    <td class="cnt"><img src={flag} alt=""/>England</td>
                                                                                    <td class="center-blk">124</td>
                                                                                    <td class="center-blk">6967</td>
                                                                                    
                                                                                </tr>
                                                                               
                                                                                
                                                                                
                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                                 </div>
                                                                 
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                           

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row mt-50">
                            
                            <div class="col-lg-12">
                                <div class="prof-tab">
                                   <h3>Women's Ranking</h3>
                                    <div class="tabContainer">
                                        <nav>
                                            <div class="nav nav-link-wrap bdr-btm-three rds" id="nav-tab" role="tablist">
                                               

                                                <a class="nav-item nav-link active" id="tbTwo-tab"  href="">TeamS</a>

                                                <a class="nav-item nav-link" id="tbtwo-tab" href="">Batsmen</a>
                                                
                                                <a class="nav-item nav-link" id="tbfour-tab" href="" >Bowlers</a>
                                                <a class="nav-item nav-link" id="tbfive-tab" href="">All Rounders</a>

                                                
                                            </div>
                                        </nav>
                                        <div class="tab-content" id="nav-tabContent">

                                        
                                            <div class="tab-pane fade show active">
                                                <div class="tb-content-wrap">
                                                    <div class="tabContainer-inner-1 rnk">
                                                        <nav>
                                                            <div class="nav nav-link-wrap inner-1" id="nav-tab" role="tablist">
                                                                <a class="nav-item nav-link active" href="">ODI

                                                                   
                                                                </a>
                                                                <a class="nav-item nav-link" href="">TEST
                                                                   
                                                                </a>
                                                                <a class="nav-item nav-link" href="">T20
                                                                    
                                                                </a>
                                                                
                                                            </div>
                                                        </nav>
                                                        <div class="tab-content" id="nav-tabContent">
                                                            <div class="tab-pane fade show active" id="tb-card-1" role="tabpanel" aria-labelledby="tb-inner-1-tab">
                                                                 
                                                                 <div class="prf-table-inner">
                                                                    <div class="pl-table-info-all">
                                                                        <table class="table ranking-table">
                                                                           <thead>
                                                                               <tr>
                                                                                   <th>Pos</th>
                                                                                   <th>Team</th>
                                                                                   <th class="center-blk"><span>Points</span></th>
                                                                                   <th class="center-blk"><span>Rating</span></th>
                                                                               </tr>
                                                                           </thead>
                                                                            <tbody>
                                                                                <tr>
                                                                                    <td>1</td>
                                                                                    <td class="cnt"><img src={flag} alt=""/>England</td>
                                                                                    <td class="center-blk">124</td>
                                                                                    <td class="center-blk">6967</td>
                                                                                    
                                                                                </tr>
                                                                                <tr>
                                                                                    <td>1</td>
                                                                                    <td class="cnt"><img src={flag} alt=""/>England</td>
                                                                                    <td class="center-blk">124</td>
                                                                                    <td class="center-blk">6967</td>
                                                                                    
                                                                                </tr>
                                                                                <tr>
                                                                                    <td>1</td>
                                                                                    <td class="cnt"><img src={flag} alt=""/>England</td>
                                                                                    <td class="center-blk">124</td>
                                                                                    <td class="center-blk">6967</td>
                                                                                    
                                                                                </tr>
                                                                                <tr>
                                                                                    <td>1</td>
                                                                                    <td class="cnt"><img src={flag} alt=""/>England</td>
                                                                                    <td class="center-blk">124</td>
                                                                                    <td class="center-blk">6967</td>
                                                                                    
                                                                                </tr>
                                                                                <tr>
                                                                                    <td>1</td>
                                                                                    <td class="cnt"><img src={flag} alt=""/>England</td>
                                                                                    <td class="center-blk">124</td>
                                                                                    <td class="center-blk">6967</td>
                                                                                    
                                                                                </tr>
                                                                                <tr>
                                                                                    <td>1</td>
                                                                                    <td class="cnt"><img src={flag} alt=""/>England</td>
                                                                                    <td class="center-blk">124</td>
                                                                                    <td class="center-blk">6967</td>
                                                                                    
                                                                                </tr>
                                                                                <tr>
                                                                                    <td>1</td>
                                                                                    <td class="cnt"><img src={flag} alt=""/>England</td>
                                                                                    <td class="center-blk">124</td>
                                                                                    <td class="center-blk">6967</td>
                                                                                    
                                                                                </tr>
                                                                                <tr>
                                                                                    <td>1</td>
                                                                                    <td class="cnt"><img src={flag} alt=""/>England</td>
                                                                                    <td class="center-blk">124</td>
                                                                                    <td class="center-blk">6967</td>
                                                                                    
                                                                                </tr>
                                                                                <tr>
                                                                                    <td>1</td>
                                                                                    <td class="cnt"><img src={flag} alt=""/>England</td>
                                                                                    <td class="center-blk">124</td>
                                                                                    <td class="center-blk">6967</td>
                                                                                    
                                                                                </tr>
                                                                                <tr>
                                                                                    <td>1</td>
                                                                                    <td class="cnt"><img src={flag} alt=""/>England</td>
                                                                                    <td class="center-blk">124</td>
                                                                                    <td class="center-blk">6967</td>
                                                                                    
                                                                                </tr>
                                                                               
                                                                                
                                                                                
                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                                 </div>
                                                                 
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                           

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                            </div>
                            <div class="col-lg-3">
                                <div class="sidebar-widget-wrapper">



                                    <div class="sidebar-widget trend-widget">
                                        <h4 class="heading-title">Latest News</h4>

                                        <nav>
                                            <ul>
                                                <li>
                                                    <div class="img"><NavLink to="/"><img src={trending} alt="image" /></NavLink></div>
                                                    <div class="content"><NavLink to="/">
                                                        <p>Lorem Ipsum is simply dummy text to print ...</p>
                                                    </NavLink></div>
                                                </li>
                                                <li>
                                                    <div class="img"><NavLink to="/"><img src={trending} alt="image" /></NavLink></div>
                                                    <div class="content"><NavLink to="/">
                                                        <p>Lorem Ipsum is simply dummy text to print ...</p>
                                                    </NavLink></div>
                                                </li>
                                                <li>
                                                    <div class="img"><NavLink to="/"><img src={trending} alt="image" /></NavLink></div>
                                                    <div class="content"><NavLink to="/">
                                                        <p>Lorem Ipsum is simply dummy text to print ...</p>
                                                    </NavLink></div>
                                                </li>
                                                <li>
                                                    <div class="img"><NavLink to="/"><img src={trending} alt="image" /></NavLink></div>
                                                    <div class="content"><NavLink to="/">
                                                        <p>Lorem Ipsum is simply dummy text to print ...</p>
                                                    </NavLink></div>
                                                </li>
                                                <li>
                                                    <div class="img"><NavLink to="/"><img src={trending} alt="image" /></NavLink></div>
                                                    <div class="content"><NavLink to="/">
                                                        <p>Lorem Ipsum is simply dummy text to print ...</p>
                                                    </NavLink></div>
                                                </li>
                                            </ul>
                                        </nav>
                                        <NavLink to="/" class="ld-more-btn">Load More</NavLink>
                                    </div>

                                    <div class="sidebar-widget popular-widget">
                                        <h4 class="heading-title">Most Popular</h4>

                                        <div>
                                            <div class="nav nav-tabs" id="nav-tab" role="tablist">
                                                <NavLink class="nav-item nav-NavLink active" data-toggle="tab" to="/nav-7">Today</NavLink>
                                                <NavLink class="nav-item nav-NavLink" data-toggle="tab" to="/nav-8">Last 7 Days</NavLink>
                                                <NavLink class="nav-item nav-NavLink" data-toggle="tab" to="/nav-9">Last 30 Days</NavLink>
                                            </div>
                                        </div>
                                        <div class="tab-content" id="nav-tabContent">
                                            <div class="tab-pane fade show active" id="nav-7">
                                                <nav>
                                                    <ul>
                                                        <li>
                                                            <span>1</span>
                                                            <NavLink to="/">
                                                                <p>Lorem Ipsum is simply dummy ..</p>
                                                            </NavLink>
                                                        </li>
                                                        <li>
                                                            <span>2</span>
                                                            <NavLink to="/">
                                                                <p>Lorem int and typesetting int and typesetting int and typesetting int and typesetting...</p>
                                                            </NavLink>
                                                        </li>
                                                        <li>
                                                            <span>3</span>
                                                            <NavLink to="/">
                                                                <p>Lorem int and typesetting int and typesetting int and typesetting int and typesetting...</p>
                                                            </NavLink>
                                                        </li>
                                                        <li>
                                                            <span>4</span>
                                                            <NavLink to="/">
                                                                <p>Lorem int and typesetting int and typesetting int and typesetting int and typesetting...</p>
                                                            </NavLink>
                                                        </li>
                                                        <li>
                                                            <span>5</span>
                                                            <NavLink to="/">
                                                                <p>Lorem int and typesetting int and typesetting int and typesetting int and typesetting...</p>
                                                            </NavLink>
                                                        </li>
                                                    </ul>
                                                </nav>

                                            </div>
                                            <div class="tab-pane fade" id="nav-8">

                                            </div>
                                            <div class="tab-pane fade" id="nav-9">


                                            </div>
                                        </div>



                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}
export default Ranking