import React, { Component, Fragment } from 'react'
import { NavLink } from 'react-router-dom'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// images 
import trending from '../../images/trending-thumbnail.png'
import goldenBall from '../../images/ball-golden.svg'
import batMian from '../../images/bat-mian.svg'
import ball from '../../images/ball.svg'
import bat from '../../images/bat.svg'
import player from '../../images/player-1.png'
import bg from '../../images/l-info-img.jpg'
class Comentray extends Component {
    render() {

        return (
            <Fragment>

                <div class="news-content-area fx-padding ranking-page">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-lg-9">
                                <div class="lins-up-inner-blk">
                                    <div class="ln-top-main">
                                        <div class="ln-top-des-blk">
                                            <div class="row">
                                                <div class="col-md-7">
                                                    <div class="lntop-left-des">
                                                        <h2>Ind <span class="srt-text">ia</span> vs West <span class="srt-text">Indies</span></h2>
                                                        <div class="ln-des-info">
                                                            <span>1st ODI</span>
                                                            <span>6-DEC-2019</span>
                                                            <span>4:00 PM</span>
                                                        </div>
                                                        <p>M. A. Chidambaram Stadium, Chennai, India</p>
                                                    </div>
                                                </div>
                                                <div class="col-md-5">
                                                    <div class="fx-inner-top-info">
                                                        <span>Live</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="line-ups-inner-all-info" style={{ background: `url(${bg})no-repeat center center / cover` }}>
                                        <div class="row">
                                            <div class="col-md-7 col-lg-7 col-xl-7">
                                                <div class="line-up-inner-left-blk">
                                                    <div class="line-up-lft">
                                                        <div class="line-up-left-blk-single-row">
                                                            <img src="assets/img/f-india.png" alt="" />
                                                            <h3>India <img src={goldenBall} alt="" /> <img src={batMian} alt="" /></h3>
                                                        </div>
                                                        <div class="line-up-rt-blk">
                                                            <h3>287/8 (50)</h3>
                                                        </div>
                                                    </div>
                                                    <div class="line-up-lft">
                                                        <div class="line-up-left-blk-single-row">
                                                            <img src="assets/img/f-india.png" alt="" />
                                                            <h3>India <img src={goldenBall} alt="" /> <img src={ball} alt="" /></h3>
                                                        </div>
                                                        <div class="line-up-rt-blk">
                                                            <h3>287/8 (50)</h3>
                                                        </div>
                                                    </div>
                                                    <p>WI require 138 runs with 9 wickets & 140 balls remaining</p>
                                                </div>
                                            </div>
                                            <div class="col-md-5 col-lg-5 col-xl-5">
                                                <div class="line-info-right-table">
                                                    <table class="table">

                                                        <tbody>
                                                            <tr>
                                                                <td>Current Run Rate</td>
                                                                <td class="text-right">5.39</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Required Run Rate</td>
                                                                <td class="text-right">6.47</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Last 5 Over</td>
                                                                <td class="text-right">45/0 (RR: 9.00)</td>
                                                            </tr>
                                                            <tr>
                                                                <td>At this stage India were</td>
                                                                <td class="text-right">160/4</td>
                                                            </tr>

                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="menu-tab-wrapper ">
                                        <div class="row">
                                            <div class="col-12">
                                                <div class="tabContainer">
                                                    <nav>
                                                        <div class="nav nav-link-wrap bdr-btm-three" id="nav-tab" role="tablist">
                                                            <a class="nav-item nav-link" id="tbOne-tab" data-toggle="tab" href="#tbOne" role="tab" aria-controls="nav-home" aria-selected="true">Live</a>

                                                            <a class="nav-item nav-link active" id="tbTwo-tab" data-toggle="tab" href="#tbTwo" role="tab" aria-controls="nav-profile" aria-selected="false">Scorecard</a>

                                                            <a class="nav-item nav-link" id="tbThree-tab" data-toggle="tab" href="#tbThree" role="tab" aria-controls="nav-contact" aria-selected="false">Commentary</a>

                                                            <a class="nav-item nav-link" id="tbFour-tab" data-toggle="tab" href="#tbFour" role="tab" aria-controls="nav-contact" aria-selected="false">Statistics</a>

                                                            <a class="nav-item nav-link" id="tbFive-tab" data-toggle="tab" href="#tbFive" role="tab" aria-controls="nav-contact" aria-selected="false">Line-Ups</a>

                                                            <a class="nav-item nav-link" id="tbSix-tab" data-toggle="tab" href="#tbSix" role="tab" aria-controls="nav-contact" aria-selected="false">Info</a>
                                                        </div>
                                                    </nav>
                                                    <div class="tab-content" id="nav-tabContent">
                                                        <div class="tab-pane fade" id="tbOne" role="tabpanel" aria-labelledby="tbOne-tab">
                                                            <div class="tb-content-wrap">
                                                                <a href="#" class="bdr-btm-three btm-arrow-shape link-full">India Innings - In Play</a>
                                                                <div class="strike-table-wrap">
                                                                    <table class="table strike-table">
                                                                        <thead>
                                                                            <tr>
                                                                                <th>
                                                                                    Batsmen</th>
                                                                                <th class="text-center">R</th>
                                                                                <th class="text-center">B</th>
                                                                                <th class="text-center">4s</th>
                                                                                <th class="text-center">6s</th>
                                                                                <th class="text-center">SR</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            <tr>
                                                                                <td>
                                                                                    <div class="player-name-img">
                                                                                        <img src={player} alt="" />
                                                                                        <span>Batsman Name</span>
                                                                                        <span class="action-indicator"><img src={bat} alt="" /></span>
                                                                                    </div>
                                                                                </td>
                                                                                <td class="text-center">
                                                                                    <strong>100</strong>
                                                                                </td>
                                                                                <td class="text-center">100</td>
                                                                                <td class="text-center">40</td>
                                                                                <td class="text-center">40</td>
                                                                                <td class="text-center">100.99</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td>
                                                                                    <div class="player-name-img">
                                                                                        <img src={player} alt="" />
                                                                                        <span>Batsman Name</span>
                                                                                    </div>
                                                                                </td>
                                                                                <td class="text-center">
                                                                                    <strong>100</strong>
                                                                                </td>
                                                                                <td class="text-center">100</td>
                                                                                <td class="text-center">40</td>
                                                                                <td class="text-center">40</td>
                                                                                <td class="text-center">100.99</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td><strong>Current Partnership</strong></td>
                                                                                <td colspan="5"><strong>100</strong> (16.5 overs)</td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                                <div class="strike-table-wrap">
                                                                    <table class="table strike-table">
                                                                        <thead>
                                                                            <tr>
                                                                                <th>Bowler</th>
                                                                                <th class="text-center">O</th>
                                                                                <th class="text-center">M</th>
                                                                                <th class="text-center">R</th>
                                                                                <th class="text-center">W</th>
                                                                                <th class="text-center">WD</th>
                                                                                <th class="text-center">NB</th>
                                                                                <th class="text-center">ER</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            <tr>
                                                                                <td>
                                                                                    <div class="player-name-img">
                                                                                        <img src={player} alt="" />
                                                                                        <span>Bowler Name</span>
                                                                                    </div>
                                                                                </td>
                                                                                <td class="text-center">10</td>
                                                                                <td class="text-center">10</td>
                                                                                <td class="text-center">100</td>
                                                                                <td class="text-center"><strong>5</strong></td>
                                                                                <td class="text-center">10</td>
                                                                                <td class="text-center">10</td>
                                                                                <td class="text-center">100.99</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td>
                                                                                    <div class="player-name-img">
                                                                                        <img src={player} alt="" />
                                                                                        <span>Bowler Name</span>
                                                                                        <span class="action-indicator"><img src={ball} alt="" /></span>
                                                                                    </div>
                                                                                </td>
                                                                                <td class="text-center">10</td>
                                                                                <td class="text-center">10</td>
                                                                                <td class="text-center">100</td>
                                                                                <td class="text-center"><strong>5</strong></td>
                                                                                <td class="text-center">10</td>
                                                                                <td class="text-center">10</td>
                                                                                <td class="text-center">100.99</td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </div>

                                                                <div class="similar-blk mb-20">
                                                                    <h3 class="tertiary-title">
                                                                        Recent
                                                        </h3>
                                                                    <div class="over-wrap">
                                                                        <div class="single-over">
                                                                            <span class="ball-of-over">1</span>
                                                                            <span class="ball-of-over">2</span>
                                                                            <span class="ball-of-over">0</span>
                                                                            <span class="ball-of-over green-bg">4</span>
                                                                            <span class="ball-of-over green-bg">6</span>
                                                                            <span class="ball-of-over red-bg">W</span>
                                                                        </div>
                                                                        <div class="single-over">
                                                                            <span class="ball-of-over">1</span>
                                                                            <span class="ball-of-over">2</span>
                                                                            <span class="ball-of-over">0</span>
                                                                            <span class="ball-of-over green-bg">4</span>
                                                                            <span class="ball-of-over green-bg">6</span>
                                                                            <span class="ball-of-over red-bg">W</span>
                                                                        </div>
                                                                        <div class="single-over">
                                                                            <span class="ball-of-over">1</span>
                                                                            <span class="ball-of-over">2</span>
                                                                            <span class="ball-of-over">0</span>
                                                                            <span class="ball-of-over green-bg">4</span>
                                                                            <span class="ball-of-over green-bg">6</span>
                                                                            <span class="ball-of-over red-bg">W</span>
                                                                        </div>
                                                                        <div class="single-over">
                                                                            <span class="ball-of-over">1</span>
                                                                            <span class="ball-of-over">2</span>
                                                                            <span class="ball-of-over">0</span>
                                                                            <span class="ball-of-over green-bg">4</span>
                                                                            <span class="ball-of-over green-bg">6</span>
                                                                            <span class="ball-of-over red-bg">W</span>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div class="similar-blk mb-20">
                                                                    <h3 class="tertiary-title mb-10">
                                                                        Live Commentary
                                                        </h3>
                                                                    <div class="commentry-common-wrap">
                                                                        <div class="single-comentry light-green-bgg">
                                                                            <div class="left-trans-over">
                                                                                44.4 <span class="over-oval green-bg">4</span>
                                                                            </div>
                                                                            <p>S Afridi to N Dickwella, <span class="two-per-bold">Four!</span></p>
                                                                        </div>
                                                                        <div class="single-comentry gray-bgg">
                                                                            <div class="left-trans-over">
                                                                                44.3 <span class="over-oval">0</span>
                                                                            </div>
                                                                            <p>S Afridi to N Dickwella, No Run</p>
                                                                        </div>
                                                                        <div class="single-comentry gray-bgg">
                                                                            <div class="left-trans-over">
                                                                                44.2 <span class="over-oval">2</span>
                                                                            </div>
                                                                            <p>S Afridi to N Dickwella, 2 Runs</p>
                                                                        </div>
                                                                        <div class="single-comentry gray-bgg">
                                                                            <div class="left-trans-over">
                                                                                44.1 <span class="over-oval">1</span>
                                                                            </div>
                                                                            <p>S Afridi to N Dickwella, 1 Run</p>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div class="similar-blk mb-20">
                                                                    <div class="hybried-title mb-10">
                                                                        <div class="h-left-roudn">
                                                                            <span class="end-of-over">End of <br />
                                                                                over</span>
                                                                            <span class="round-ou">
                                                                                <span class="round-in">
                                                                                    46
                                                                    </span>
                                                                            </span>
                                                                        </div>

                                                                        <div class="h-right-content">
                                                                            <div class="top-bar">
                                                                                <p>Sri Lanka 200/3</p>
                                                                                <ul class="h-current-statistics">
                                                                                    <li>Run: 13</li>
                                                                                    <li>Wicket: 1</li>
                                                                                    <li>Extra: 0</li>
                                                                                </ul>
                                                                            </div>
                                                                            <div class="bottom-bar">
                                                                                <div class="btm-bar-left-p">
                                                                                    <p>Batsman Name : 100 (100)</p>
                                                                                    <p>Batsman Name : 100 (100)</p>
                                                                                </div>
                                                                                <div class="btm-bar-right-p">
                                                                                    <p>Shahid Afridi : 2-0-13-1</p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="commentry-common-wrap">
                                                                        <div class="single-comentry light-red-bgg">
                                                                            <div class="left-trans-over">
                                                                                44.6 <span class="over-oval red-bg">4</span>
                                                                            </div>
                                                                            <p>S Afridi to N Dickwella, <span class="two-per-bold">Wicket!</span></p>
                                                                        </div>
                                                                        <div class="single-comentry light-green-bgg">
                                                                            <div class="left-trans-over">
                                                                                44.5 <span class="over-oval green-bg">4</span>
                                                                            </div>
                                                                            <p>S Afridi to N Dickwella,<span class="two-per-bold">Six!</span></p>
                                                                        </div>
                                                                        <div class="single-comentry light-green-bgg">
                                                                            <div class="left-trans-over">
                                                                                44.4 <span class="over-oval">4</span>
                                                                            </div>
                                                                            <p>S Afridi to N Dickwella, <span class="two-per-bold">Four!</span></p>
                                                                        </div>
                                                                        <div class="single-comentry gray-bgg">
                                                                            <div class="left-trans-over">
                                                                                44.3 <span class="over-oval">0</span>
                                                                            </div>
                                                                            <p>S Afridi to N Dickwella, No Run</p>
                                                                        </div>
                                                                        <div class="single-comentry gray-bgg">
                                                                            <div class="left-trans-over">
                                                                                44.2 <span class="over-oval">2</span>
                                                                            </div>
                                                                            <p>S Afridi to N Dickwella, 2 Runs</p>
                                                                        </div>
                                                                        <div class="single-comentry gray-bgg">
                                                                            <div class="left-trans-over">
                                                                                44.1 <span class="over-oval">1</span>
                                                                            </div>
                                                                            <p>S Afridi to N Dickwella, 1 Run</p>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div class="similar-blk">
                                                                    <div class="hybried-title mb-10">
                                                                        <div class="h-left-roudn">
                                                                            <span class="end-of-over">End of <br />
                                                                                    over</span>
                                                                            <span class="round-ou">
                                                                                <span class="round-in">
                                                                                    46
                                                                    </span>
                                                                            </span>
                                                                        </div>

                                                                        <div class="h-right-content">
                                                                            <div class="top-bar">
                                                                                <p>Sri Lanka 200/3</p>
                                                                                <ul class="h-current-statistics">
                                                                                    <li>Run: 13</li>
                                                                                    <li>Wicket: 1</li>
                                                                                    <li>Extra: 0</li>
                                                                                </ul>
                                                                            </div>
                                                                            <div class="bottom-bar">
                                                                                <div class="btm-bar-left-p">
                                                                                    <p>Batsman Name : 100 (100)</p>
                                                                                    <p>Batsman Name : 100 (100)</p>
                                                                                </div>
                                                                                <div class="btm-bar-right-p">
                                                                                    <p>Shahid Afridi : 2-0-13-1</p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="commentry-common-wrap">
                                                                        <div class="single-comentry light-red-bgg">
                                                                            <div class="left-trans-over">
                                                                                44.6 <span class="over-oval red-bg">4</span>
                                                                            </div>
                                                                            <p>S Afridi to N Dickwella, <span class="two-per-bold">Wicket!</span></p>
                                                                        </div>
                                                                        <div class="single-comentry light-green-bgg">
                                                                            <div class="left-trans-over">
                                                                                44.5 <span class="over-oval green-bg">4</span>
                                                                            </div>
                                                                            <p>S Afridi to N Dickwella,<span class="two-per-bold">Six!</span></p>
                                                                        </div>
                                                                        <div class="single-comentry light-green-bgg">
                                                                            <div class="left-trans-over">
                                                                                44.4 <span class="over-oval">4</span>
                                                                            </div>
                                                                            <p>S Afridi to N Dickwella, <span class="two-per-bold">Four!</span></p>
                                                                        </div>
                                                                        <div class="single-comentry gray-bgg">
                                                                            <div class="left-trans-over">
                                                                                44.3 <span class="over-oval">0</span>
                                                                            </div>
                                                                            <p>S Afridi to N Dickwella, No Run</p>
                                                                        </div>
                                                                        <div class="single-comentry gray-bgg">
                                                                            <div class="left-trans-over">
                                                                                44.2 <span class="over-oval">2</span>
                                                                            </div>
                                                                            <p>S Afridi to N Dickwella, 2 Runs</p>
                                                                        </div>
                                                                        <div class="single-comentry gray-bgg">
                                                                            <div class="left-trans-over">
                                                                                44.1 <span class="over-oval">1</span>
                                                                            </div>
                                                                            <p>S Afridi to N Dickwella, 1 Run</p>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div class="middle-btn">
                                                                    <a href="#" class="round-btn">Watch full commentary</a>
                                                                </div>


                                                            </div>
                                                        </div>
                                                        <div class="tab-pane fade show active" id="tbTwo" role="tabpanel" aria-labelledby="tbTwo-tab">
                                                            <div class="tb-content-wrap">
                                                                <div class="tabContainer-inner-1">
                                                                    <nav>
                                                                        <div class="nav nav-link-wrap inner-1" id="nav-tab" role="tablist">
                                                                            <a class="nav-item nav-link active" id="tbOne-line-1-tab" data-toggle="tab" href="#tbOne-card-1" role="tab" aria-controls="nav-home" aria-selected="true">India Innings

                                                                    <span class="extra-border"></span>
                                                                            </a>
                                                                            <a class="nav-item nav-link" id="tbTwo-line-2-tab" data-toggle="tab" href="#tbTwo-card-2" role="tab" aria-controls="nav-profile" aria-selected="false">West Indies Innings
                                                                    <span class="extra-border"></span>
                                                                            </a>
                                                                        </div>
                                                                    </nav>
                                                                    <div class="tab-content" id="nav-tabContent">
                                                                        <div class="tab-pane fade show active" id="tbOne-card-1" role="tabpanel" aria-labelledby="tbOne-inner-1-tab">
                                                                            <div class="tb-content-wrap inner-1">
                                                                                <div class="strike-table-wrap under-scorecard">
                                                                                    <table class="table strike-table">
                                                                                        <thead>
                                                                                            <tr>
                                                                                                <th>
                                                                                                    Batsmen</th>
                                                                                                <th class="d-n"></th>
                                                                                                <th class="text-center">R</th>
                                                                                                <th class="text-center">B</th>
                                                                                                <th class="text-center">4s</th>
                                                                                                <th class="text-center">6s</th>
                                                                                                <th class="text-center">SR</th>
                                                                                            </tr>
                                                                                        </thead>
                                                                                        <tbody>
                                                                                            <tr class="b-btm-none">
                                                                                                <td>
                                                                                                    <div class="player-name-img">
                                                                                                        <img src={player} alt="" />
                                                                                                        <span>Batsman Name</span>

                                                                                                    </div>
                                                                                                </td>

                                                                                                <td class="d-n">c Fielder b Bowler</td>
                                                                                                <td class="text-center">
                                                                                                    <strong>100</strong>
                                                                                                </td>
                                                                                                <td class="text-center">100</td>
                                                                                                <td class="text-center">40</td>
                                                                                                <td class="text-center">40</td>
                                                                                                <td class="text-center">100.99</td>
                                                                                            </tr>
                                                                                            <tr class="b-tp-none">
                                                                                                <td colspan="7">
                                                                                                    <p class="d-block d-sm-none">c Fielder b Bowler</p>
                                                                                                </td>
                                                                                            </tr>

                                                                                            <tr class="b-btm-none">
                                                                                                <td>
                                                                                                    <div class="player-name-img">
                                                                                                        <img src={player} alt="" />
                                                                                                        <span>Batsman Name</span>

                                                                                                    </div>
                                                                                                </td>
                                                                                                <td class="d-n">c Fielder b Bowler</td>
                                                                                                <td class="text-center">
                                                                                                    <strong>100</strong>
                                                                                                </td>
                                                                                                <td class="text-center">100</td>
                                                                                                <td class="text-center">40</td>
                                                                                                <td class="text-center">40</td>
                                                                                                <td class="text-center">100.99</td>
                                                                                            </tr>
                                                                                            <tr class="b-tp-none">
                                                                                                <td colspan="7">
                                                                                                    <p class="d-block d-sm-none">c Fielder b Bowler</p>
                                                                                                </td>
                                                                                            </tr>

                                                                                            <tr class="b-btm-none">
                                                                                                <td>
                                                                                                    <div class="player-name-img">
                                                                                                        <img src={player} alt="" />
                                                                                                        <span>Batsman Name</span>

                                                                                                    </div>
                                                                                                </td>
                                                                                                <td class="d-n">c Fielder b Bowler</td>
                                                                                                <td class="text-center">
                                                                                                    <strong>100</strong>
                                                                                                </td>
                                                                                                <td class="text-center">100</td>
                                                                                                <td class="text-center">40</td>
                                                                                                <td class="text-center">40</td>
                                                                                                <td class="text-center">100.99</td>
                                                                                            </tr>
                                                                                            <tr class="b-tp-none">
                                                                                                <td colspan="7">
                                                                                                    <p class="d-block d-sm-none">c Fielder b Bowler</p>
                                                                                                </td>
                                                                                            </tr>

                                                                                            <tr class="b-btm-none">
                                                                                                <td>
                                                                                                    <div class="player-name-img">
                                                                                                        <img src={player} alt="" />
                                                                                                        <span>Batsman Name</span>

                                                                                                    </div>
                                                                                                </td>
                                                                                                <td class="d-n">c Fielder b Bowler</td>
                                                                                                <td class="text-center">
                                                                                                    <strong>100</strong>
                                                                                                </td>
                                                                                                <td class="text-center">100</td>
                                                                                                <td class="text-center">40</td>
                                                                                                <td class="text-center">40</td>
                                                                                                <td class="text-center">100.99</td>
                                                                                            </tr>
                                                                                            <tr class="b-tp-none">
                                                                                                <td colspan="7">
                                                                                                    <p class="d-block d-sm-none">c Fielder b Bowler</p>
                                                                                                </td>
                                                                                            </tr>





                                                                                            <tr>
                                                                                                <td><strong>Extras</strong></td>
                                                                                                <td colspan="2">(nb 100, w 100, b 100, lb 100)</td>
                                                                                                <td colspan="5"><strong>100</strong></td>
                                                                                            </tr>
                                                                                            <tr class="total-table-count">
                                                                                                <td colspan="2"><strong>Total</strong></td>
                                                                                                <td colspan="5"><strong>400/10</strong> (47.5 Overs, RR: 6.08)</td>
                                                                                            </tr>
                                                                                        </tbody>
                                                                                    </table>
                                                                                </div>

                                                                                <div class="similar-blk mb-20">
                                                                                    <h3 class="tertiary-title">
                                                                                        Fall of Wickets
                                                                        </h3>
                                                                                    <div class="fall-wicket-wrap">
                                                                                        <div class="fall-wicket-wrap-inner">
                                                                                            <div class="fall-item">
                                                                                                <span class="fall-run">1-21</span>
                                                                                                <p>KL Rahul
                                                                                        at 6.2 over</p>
                                                                                            </div>
                                                                                            <div class="fall-item">
                                                                                                <span class="fall-run">1-21</span>
                                                                                                <p>KL Rahul
                                                                                        at 6.2 over</p>
                                                                                            </div>
                                                                                            <div class="fall-item">
                                                                                                <span class="fall-run">1-21</span>
                                                                                                <p>KL Rahul
                                                                                        at 6.2 over</p>
                                                                                            </div>
                                                                                            <div class="fall-item">
                                                                                                <span class="fall-run">1-21</span>
                                                                                                <p>KL Rahul
                                                                                        at 6.2 over</p>
                                                                                            </div>
                                                                                            <div class="fall-item">
                                                                                                <span class="fall-run">1-21</span>
                                                                                                <p>KL Rahul
                                                                                        at 6.2 over</p>
                                                                                            </div>
                                                                                            <div class="fall-item">
                                                                                                <span class="fall-run">1-21</span>
                                                                                                <p>KL Rahul
                                                                                        at 6.2 over</p>
                                                                                            </div>
                                                                                            <div class="fall-item">
                                                                                                <span class="fall-run">1-21</span>
                                                                                                <p>KL Rahul
                                                                                        at 6.2 over</p>
                                                                                            </div>
                                                                                            <div class="fall-item">
                                                                                                <span class="fall-run">1-21</span>
                                                                                                <p>KL Rahul
                                                                                        at 6.2 over</p>
                                                                                            </div>
                                                                                            <div class="fall-item">
                                                                                                <span class="fall-run">1-21</span>
                                                                                                <p>KL Rahul
                                                                                        at 6.2 over</p>
                                                                                            </div>
                                                                                            <div class="fall-item">
                                                                                                <span class="fall-run">1-21</span>
                                                                                                <p>KL Rahul
                                                                                        at 6.2 over</p>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>

                                                                                <div class="strike-table-wrap under-scorecard">
                                                                                    <table class="table strike-table table-responsive-sm">
                                                                                        <thead>
                                                                                            <tr>
                                                                                                <th>Bowler</th>
                                                                                                <th class="text-center">O</th>
                                                                                                <th class="text-center">M</th>
                                                                                                <th class="text-center">R</th>
                                                                                                <th class="text-center">W</th>
                                                                                                <th class="text-center">WD</th>
                                                                                                <th class="text-center">NB</th>
                                                                                                <th class="text-center">ER</th>
                                                                                            </tr>
                                                                                        </thead>
                                                                                        <tbody>
                                                                                            <tr>
                                                                                                <td>
                                                                                                    <div class="player-name-img">
                                                                                                        <img src={player} alt="" />
                                                                                                        <span>Bowler Name</span>
                                                                                                    </div>
                                                                                                </td>
                                                                                                <td class="text-center">10</td>
                                                                                                <td class="text-center">10</td>
                                                                                                <td class="text-center">
                                                                                                    <strong>100</strong>
                                                                                                </td>
                                                                                                <td class="text-center">5</td>
                                                                                                <td class="text-center">10</td>
                                                                                                <td class="text-center">10</td>
                                                                                                <td class="text-center">100.99</td>
                                                                                            </tr>
                                                                                            <tr>
                                                                                                <td>
                                                                                                    <div class="player-name-img">
                                                                                                        <img src={player} alt="" />
                                                                                                        <span>Bowler Name</span>
                                                                                                    </div>
                                                                                                </td>
                                                                                                <td class="text-center">10</td>
                                                                                                <td class="text-center">10</td>
                                                                                                <td class="text-center">
                                                                                                    <strong>100</strong>
                                                                                                </td>
                                                                                                <td class="text-center">5</td>
                                                                                                <td class="text-center">10</td>
                                                                                                <td class="text-center">10</td>
                                                                                                <td class="text-center">100.99</td>
                                                                                            </tr>
                                                                                            <tr>
                                                                                                <td>
                                                                                                    <div class="player-name-img">
                                                                                                        <img src={player} alt="" />
                                                                                                        <span>Bowler Name</span>
                                                                                                    </div>
                                                                                                </td>
                                                                                                <td class="text-center">10</td>
                                                                                                <td class="text-center">10</td>
                                                                                                <td class="text-center">
                                                                                                    <strong>100</strong>
                                                                                                </td>
                                                                                                <td class="text-center">5</td>
                                                                                                <td class="text-center">10</td>
                                                                                                <td class="text-center">10</td>
                                                                                                <td class="text-center">100.99</td>
                                                                                            </tr>
                                                                                            <tr>
                                                                                                <td>
                                                                                                    <div class="player-name-img">
                                                                                                        <img src={player} alt="" />
                                                                                                        <span>Bowler Name</span>
                                                                                                    </div>
                                                                                                </td>
                                                                                                <td class="text-center">10</td>
                                                                                                <td class="text-center">10</td>
                                                                                                <td class="text-center">
                                                                                                    <strong>100</strong>
                                                                                                </td>
                                                                                                <td class="text-center">5</td>
                                                                                                <td class="text-center">10</td>
                                                                                                <td class="text-center">10</td>
                                                                                                <td class="text-center">100.99</td>
                                                                                            </tr>
                                                                                            <tr>
                                                                                                <td>
                                                                                                    <div class="player-name-img">
                                                                                                        <img src={player} alt="" />
                                                                                                        <span>Bowler Name</span>
                                                                                                    </div>
                                                                                                </td>
                                                                                                <td class="text-center">10</td>
                                                                                                <td class="text-center">10</td>
                                                                                                <td class="text-center">
                                                                                                    <strong>100</strong>
                                                                                                </td>
                                                                                                <td class="text-center">5</td>
                                                                                                <td class="text-center">10</td>
                                                                                                <td class="text-center">10</td>
                                                                                                <td class="text-center">100.99</td>
                                                                                            </tr>
                                                                                        </tbody>
                                                                                    </table>
                                                                                </div>

                                                                                <div class="similar-blk mb-20">
                                                                                    <h3 class="tertiary-title">
                                                                                        Partnerships
                                                                        </h3>
                                                                                    <div class="partnarship-f-wrap">
                                                                                        <div class="partnarship-single">
                                                                                            <div class="pt-left d-n">
                                                                                                <div class="player-name-img">
                                                                                                    <img src={player} alt="" />
                                                                                                    <span>Bowler Name <span class="all-bol-and-run">100 <span>(90)</span></span></span>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div class="pt-middle">
                                                                                                <div class="player-dets-mb-wrp">
                                                                                                    <div class="player-dets-mb-wrp-inner">
                                                                                                        <div class="player-dets-mb">
                                                                                                            <img src={player} alt="" />
                                                                                                            <span>Bowler Name <span class="all-bol-and-run">100 <span>(90)</span></span></span>
                                                                                                        </div>
                                                                                                        <div class="player-dets-mb">
                                                                                                            <img src={player} alt="" />
                                                                                                            <span>Bowler Name <span class="all-bol-and-run">100 <span>(90)</span></span></span>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <span class="fall-run style-2">200 (220)</span>
                                                                                            </div>
                                                                                            <div class="pt-left right-reverse  d-n">
                                                                                                <div class="player-name-img">
                                                                                                    <img src={player} alt="" />
                                                                                                    <span>Bowler Name <span class="all-bol-and-run">100 <span>(90)</span></span></span>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div class="partnarship-single">
                                                                                            <div class="pt-left d-n">
                                                                                                <div class="player-name-img">
                                                                                                    <img src={player} alt="" />
                                                                                                    <span>Bowler Name <span class="all-bol-and-run">100 <span>(90)</span></span></span>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div class="pt-middle">
                                                                                                <div class="player-dets-mb-wrp">
                                                                                                    <div class="player-dets-mb-wrp-inner">
                                                                                                        <div class="player-dets-mb">
                                                                                                            <img src={player} alt="" />
                                                                                                            <span>Bowler Name <span class="all-bol-and-run">100 <span>(90)</span></span></span>
                                                                                                        </div>
                                                                                                        <div class="player-dets-mb">
                                                                                                            <img src={player} alt="" />
                                                                                                            <span>Bowler Name <span class="all-bol-and-run">100 <span>(90)</span></span></span>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <span class="fall-run style-2">200 (220)</span>
                                                                                            </div>
                                                                                            <div class="pt-left right-reverse  d-n">
                                                                                                <div class="player-name-img">
                                                                                                    <img src={player} alt="" />
                                                                                                    <span>Bowler Name <span class="all-bol-and-run">100 <span>(90)</span></span></span>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div class="partnarship-single">
                                                                                            <div class="pt-left d-n">
                                                                                                <div class="player-name-img">
                                                                                                    <img src={player} alt="" />
                                                                                                    <span>Bowler Name <span class="all-bol-and-run">100 <span>(90)</span></span></span>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div class="pt-middle">
                                                                                                <div class="player-dets-mb-wrp">
                                                                                                    <div class="player-dets-mb-wrp-inner">
                                                                                                        <div class="player-dets-mb">
                                                                                                            <img src={player} alt="" />
                                                                                                            <span>Bowler Name <span class="all-bol-and-run">100 <span>(90)</span></span></span>
                                                                                                        </div>
                                                                                                        <div class="player-dets-mb">
                                                                                                            <img src={player} alt="" />
                                                                                                            <span>Bowler Name <span class="all-bol-and-run">100 <span>(90)</span></span></span>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <span class="fall-run style-2">200 (220)</span>
                                                                                            </div>
                                                                                            <div class="pt-left right-reverse  d-n">
                                                                                                <div class="player-name-img">
                                                                                                    <img src={player} alt="" />
                                                                                                    <span>Bowler Name <span class="all-bol-and-run">100 <span>(90)</span></span></span>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div class="partnarship-single">
                                                                                            <div class="pt-left d-n">
                                                                                                <div class="player-name-img">
                                                                                                    <img src={player} alt="" />
                                                                                                    <span>Bowler Name <span class="all-bol-and-run">100 <span>(90)</span></span></span>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div class="pt-middle">
                                                                                                <div class="player-dets-mb-wrp">
                                                                                                    <div class="player-dets-mb-wrp-inner">
                                                                                                        <div class="player-dets-mb">
                                                                                                            <img src={player} alt="" />
                                                                                                            <span>Bowler Name <span class="all-bol-and-run">100 <span>(90)</span></span></span>
                                                                                                        </div>
                                                                                                        <div class="player-dets-mb">
                                                                                                            <img src={player} alt="" />
                                                                                                            <span>Bowler Name <span class="all-bol-and-run">100 <span>(90)</span></span></span>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <span class="fall-run style-2">200 (220)</span>
                                                                                            </div>
                                                                                            <div class="pt-left right-reverse  d-n">
                                                                                                <div class="player-name-img">
                                                                                                    <img src={player} alt="" />
                                                                                                    <span>Bowler Name <span class="all-bol-and-run">100 <span>(90)</span></span></span>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div class="partnarship-single">
                                                                                            <div class="pt-left d-n">
                                                                                                <div class="player-name-img">
                                                                                                    <img src={player} alt="" />
                                                                                                    <span>Bowler Name <span class="all-bol-and-run">100 <span>(90)</span></span></span>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div class="pt-middle">
                                                                                                <div class="player-dets-mb-wrp">
                                                                                                    <div class="player-dets-mb-wrp-inner">
                                                                                                        <div class="player-dets-mb">
                                                                                                            <img src={player} alt="" />
                                                                                                            <span>Bowler Name <span class="all-bol-and-run">100 <span>(90)</span></span></span>
                                                                                                        </div>
                                                                                                        <div class="player-dets-mb">
                                                                                                            <img src={player} alt="" />
                                                                                                            <span>Bowler Name <span class="all-bol-and-run">100 <span>(90)</span></span></span>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <span class="fall-run style-2">200 (220)</span>
                                                                                            </div>
                                                                                            <div class="pt-left right-reverse  d-n">
                                                                                                <div class="player-name-img">
                                                                                                    <img src={player} alt="" />
                                                                                                    <span>Bowler Name <span class="all-bol-and-run">100 <span>(90)</span></span></span>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>

                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div class="tab-pane fade" id="tbTwo-card-2" role="tabpanel" aria-labelledby="tbTwo-inner-2-tab">
                                                                            <div class="tb-content-wrap inner-1">
                                                                                ...
                                                                </div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>
                                                        <div class="tab-pane fade" id="tbThree" role="tabpanel" aria-labelledby="tbThree-tab">
                                                            <div class="tb-content-wrap">
                                                                <div class="tabContainer-inner-1">
                                                                    <nav>
                                                                        <div class="nav nav-link-wrap inner-1" id="nav-tab" role="tablist">
                                                                            <a class="nav-item nav-link active" id="tbOne-inner-1-tab" data-toggle="tab" href="#tbOne-inner-1" role="tab" aria-controls="nav-home" aria-selected="true">India Innings

                                                                    <span class="extra-border"></span>
                                                                            </a>

                                                                            <a class="nav-item nav-link" id="tbTwo-inner-2-tab" data-toggle="tab" href="#tbTwo-inner-2" role="tab" aria-controls="nav-profile" aria-selected="false">West Indies Innings
                                                                    <span class="extra-border"></span>
                                                                            </a>
                                                                        </div>
                                                                    </nav>
                                                                    <div class="tab-content" id="nav-tabContent">

                                                                        <div class="tab-pane fade show active" id="tbOne-inner-1" role="tabpanel" aria-labelledby="tbOne-inner-1-tab">
                                                                            <div class="tb-content-wrap inner-1">
                                                                                <div class="similar-blk mb-20 mt-20">
                                                                                    <div class="commentry-common-wrap">
                                                                                        <div class="single-comentry gray-bgg">
                                                                                            <div class="left-trans-over">
                                                                                                44.4 <span class="over-oval">0</span>
                                                                                            </div>
                                                                                            <p>Tim Southee to Matthew Wade. Seaming away length ball, outside off stump on the front foot defending, missed
                                                                                    to wicketkeeper for no runs, fielded by Watling.</p>
                                                                                        </div>
                                                                                        <div class="single-comentry gray-bgg">
                                                                                            <div class="left-trans-over">
                                                                                                44.3 <span class="over-oval">2</span>
                                                                                            </div>
                                                                                            <p>Tim Southee to Matthew Wade. Length ball, outside off stump on the front foot pushing, outside edge to third
                                                                                    man for 2 runs, fielded by Latham.</p>
                                                                                        </div>
                                                                                        <div class="single-comentry gray-bgg">
                                                                                            <div class="left-trans-over">
                                                                                                44.2 <span class="over-oval">1</span>
                                                                                            </div>
                                                                                            <p>Tim Southee to Steven Smith. Out-swinging length ball, outside off stump on the front foot driving, to mid off for 1
                                                                                    run, shy attempt by Williamson.</p>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>

                                                                                <div class="similar-blk mb-20">
                                                                                    <div class="hybried-title mb-10">
                                                                                        <div class="h-left-roudn">
                                                                                            <span class="end-of-over">End of <br />
                                                                                                        over</span>
                                                                                            <span class="round-ou">
                                                                                                <span class="round-in">
                                                                                                    46
                                                                                    </span>
                                                                                            </span>
                                                                                        </div>

                                                                                        <div class="h-right-content">
                                                                                            <div class="top-bar">
                                                                                                <p>Sri Lanka 200/3</p>
                                                                                                <ul class="h-current-statistics">
                                                                                                    <li>Run: 13</li>
                                                                                                    <li>Wicket: 1</li>
                                                                                                    <li>Extra: 0</li>
                                                                                                </ul>
                                                                                            </div>
                                                                                            <div class="bottom-bar">
                                                                                                <div class="btm-bar-left-p">
                                                                                                    <p>Batsman Name : 100 (100)</p>
                                                                                                    <p>Batsman Name : 100 (100)</p>
                                                                                                </div>
                                                                                                <div class="btm-bar-right-p">
                                                                                                    <p>Shahid Afridi : 2-0-13-1</p>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div class="commentry-common-wrap">
                                                                                        <div class="single-comentry light-red-bgg">
                                                                                            <div class="left-trans-over">
                                                                                                44.6 <span class="over-oval red-bg">4</span>
                                                                                            </div>
                                                                                            <p>OUT! Caught. Colin de Grandhomme to Matthew Wade. Length ball, outside off stump on the front foot driving, outside edge to wicketkeeper, by Watling. De Grandhomme has deserved that, troubling Wade throughout. Finally, the left hander has a dash outside off stump and it was an easy take for Watling. If the Kiwis can strike again before the new ball this could still be a good day.</p>
                                                                                        </div>
                                                                                        <div class="single-comentry light-green-bgg">
                                                                                            <div class="left-trans-over">
                                                                                                44.5 <span class="over-oval green-bg">4</span>
                                                                                            </div>
                                                                                            <p>SIX! Mitchell Santner to Steven Smith. Stock length ball, outside off stump down the track driving, well timed in the air under control over long on for 6 runs. Fast hands and feet from Smith. Second six of the over and Santner under pressure now.</p>
                                                                                        </div>
                                                                                        <div class="single-comentry light-green-bgg">
                                                                                            <div class="left-trans-over">
                                                                                                44.4 <span class="over-oval">4</span>
                                                                                            </div>
                                                                                            <p>FOUR! Neil Wagner to Matthew Wade. Back of a length, outside off stump on the back foot defending, inside edge past third man for 4 runs.</p>
                                                                                        </div>
                                                                                        <div class="single-comentry gray-bgg">
                                                                                            <div class="left-trans-over">
                                                                                                44.3 <span class="over-oval">0</span>
                                                                                            </div>
                                                                                            <p>Tim Southee to Matthew Wade. Seaming away length ball, outside off stump on the front foot defending, missed
                                                                                    to wicketkeeper for no runs, fielded by Watling.</p>
                                                                                        </div>
                                                                                        <div class="single-comentry gray-bgg">
                                                                                            <div class="left-trans-over">
                                                                                                44.2 <span class="over-oval">2</span>
                                                                                            </div>
                                                                                            <p>Tim Southee to Matthew Wade. Length ball, outside off stump on the front foot pushing, outside edge to third
                                                                                    man for 2 runs, fielded by Latham.</p>
                                                                                        </div>
                                                                                        <div class="single-comentry gray-bgg">
                                                                                            <div class="left-trans-over">
                                                                                                44.1 <span class="over-oval">1</span>
                                                                                            </div>
                                                                                            <p>Tim Southee to Steven Smith. Out-swinging length ball, outside off stump on the front foot driving, to mid off for 1
                                                                                    run, shy attempt by Williamson.</p>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>

                                                                                <div class="similar-blk mb-20">
                                                                                    <div class="hybried-title mb-10">
                                                                                        <div class="h-left-roudn">
                                                                                            <span class="end-of-over">End of <br />
                                                                                                            over</span>
                                                                                            <span class="round-ou">
                                                                                                <span class="round-in">
                                                                                                    46
                                                                                    </span>
                                                                                            </span>
                                                                                        </div>

                                                                                        <div class="h-right-content">
                                                                                            <div class="top-bar">
                                                                                                <p>Sri Lanka 200/3</p>
                                                                                                <ul class="h-current-statistics">
                                                                                                    <li>Run: 13</li>
                                                                                                    <li>Wicket: 1</li>
                                                                                                    <li>Extra: 0</li>
                                                                                                </ul>
                                                                                            </div>
                                                                                            <div class="bottom-bar">
                                                                                                <div class="btm-bar-left-p">
                                                                                                    <p>Batsman Name : 100 (100)</p>
                                                                                                    <p>Batsman Name : 100 (100)</p>
                                                                                                </div>
                                                                                                <div class="btm-bar-right-p">
                                                                                                    <p>Shahid Afridi : 2-0-13-1</p>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div class="commentry-common-wrap">
                                                                                        <div class="single-comentry light-red-bgg">
                                                                                            <div class="left-trans-over">
                                                                                                44.6 <span class="over-oval red-bg">4</span>
                                                                                            </div>
                                                                                            <p>OUT! Caught. Colin de Grandhomme to Matthew Wade. Length ball, outside off stump on the front foot driving, outside edge to wicketkeeper, by Watling. De Grandhomme has deserved that, troubling Wade throughout. Finally, the left hander has a dash outside off stump and it was an easy take for Watling. If the Kiwis can strike again before the new ball this could still be a good day.</p>
                                                                                        </div>
                                                                                        <div class="single-comentry light-green-bgg">
                                                                                            <div class="left-trans-over">
                                                                                                44.5 <span class="over-oval green-bg">4</span>
                                                                                            </div>
                                                                                            <p>SIX! Mitchell Santner to Steven Smith. Stock length ball, outside off stump down the track driving, well timed in the air under control over long on for 6 runs. Fast hands and feet from Smith. Second six of the over and Santner under pressure now.</p>
                                                                                        </div>
                                                                                        <div class="single-comentry light-green-bgg">
                                                                                            <div class="left-trans-over">
                                                                                                44.4 <span class="over-oval">4</span>
                                                                                            </div>
                                                                                            <p>FOUR! Neil Wagner to Matthew Wade. Back of a length, outside off stump on the back foot defending, inside edge past third man for 4 runs.</p>
                                                                                        </div>
                                                                                        <div class="single-comentry gray-bgg">
                                                                                            <div class="left-trans-over">
                                                                                                44.3 <span class="over-oval">0</span>
                                                                                            </div>
                                                                                            <p>Tim Southee to Matthew Wade. Seaming away length ball, outside off stump on the front foot defending, missed
                                                                                    to wicketkeeper for no runs, fielded by Watling.</p>
                                                                                        </div>
                                                                                        <div class="single-comentry gray-bgg">
                                                                                            <div class="left-trans-over">
                                                                                                44.2 <span class="over-oval">2</span>
                                                                                            </div>
                                                                                            <p>Tim Southee to Matthew Wade. Length ball, outside off stump on the front foot pushing, outside edge to third
                                                                                    man for 2 runs, fielded by Latham.</p>
                                                                                        </div>
                                                                                        <div class="single-comentry gray-bgg">
                                                                                            <div class="left-trans-over">
                                                                                                44.1 <span class="over-oval">1</span>
                                                                                            </div>
                                                                                            <p>Tim Southee to Steven Smith. Out-swinging length ball, outside off stump on the front foot driving, to mid off for 1
                                                                                    run, shy attempt by Williamson.</p>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>

                                                                            </div>
                                                                        </div>

                                                                        <div class="tab-pane fade" id="tbTwo-inner-2" role="tabpanel" aria-labelledby="tbTwo-inner-2-tab">
                                                                            <div class="tb-content-wrap inner-1">
                                                                                ...
                                                                </div>
                                                                        </div>


                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="tab-pane fade" id="tbFour" role="tabpanel" aria-labelledby="tbFour-tab">
                                                            <div class="tb-content-wrap">
                                                                <div class="tabContainer-inner-1">
                                                                    <nav>
                                                                        <div class="nav nav-link-wrap inner-1" id="nav-tab" role="tablist">
                                                                            <a class="nav-item nav-link active" id="tbOne-inner-12-tab" data-toggle="tab" href="#tbOne-inner-11" role="tab" aria-controls="nav-home" aria-selected="true">India Innings

                                                                    <span class="extra-border"></span>
                                                                            </a>
                                                                            <a class="nav-item nav-link" id="tbTwo-inner-22-tab" data-toggle="tab" href="#tbTwo-inner-22" role="tab" aria-controls="nav-profile" aria-selected="false">West Indies Innings
                                                                    <span class="extra-border"></span>
                                                                            </a>
                                                                        </div>
                                                                    </nav>
                                                                    <div class="tab-content" id="nav-tabContent">
                                                                        <div class="tab-pane fade show active" id="tbOne-inner-11" role="tabpanel" aria-labelledby="tbOne-inner-11-tab">
                                                                            <div class="tb-content-wrap inner-1">
                                                                                <div class="tabContainer-inner-1">
                                                                                    <nav>
                                                                                        <div class="nav nav-link-wrap inner-2" id="nav-tab" role="tablist">

                                                                                            <a class="nav-item nav-link active" id="tbOne-11-tab" data-toggle="tab" href="#tbOne-11" role="tab" aria-controls="nav-home" aria-selected="true">Manhattan</a>

                                                                                            <a class="nav-item nav-link" id="tbTwo-22-tab" data-toggle="tab" href="#tbTwo-22" role="tab" aria-controls="nav-profile" aria-selected="false">Worm</a>

                                                                                            <a class="nav-item nav-link" id="tbTwo-23-tab" data-toggle="tab" href="#tbTwo-23" role="tab" aria-controls="nav-profile" aria-selected="false">Player vs Player</a>

                                                                                            <a class="nav-item nav-link" id="tbTwo-24-tab" data-toggle="tab" href="#tbTwo-24" role="tab" aria-controls="nav-profile" aria-selected="false">Runs</a>

                                                                                            <a class="nav-item nav-link" id="tbTwo-25-tab" data-toggle="tab" href="#tbTwo-25" role="tab" aria-controls="nav-profile" aria-selected="false">Wickets</a>

                                                                                            <a class="nav-item nav-link" id="tbTwo-26-tab" data-toggle="tab" href="#tbTwo-26" role="tab" aria-controls="nav-profile" aria-selected="false">Extras</a>

                                                                                            <a class="nav-item nav-link" id="tbTwo-27-tab" data-toggle="tab" href="#tbTwo-27" role="tab" aria-controls="nav-profile" aria-selected="false">Run Rates</a>
                                                                                        </div>
                                                                                    </nav>
                                                                                    <div class="tab-content" id="nav-tabContent">
                                                                                        <div class="tab-pane fade show active" id="tbOne-11" role="tabpanel" aria-labelledby="tbOne-11-tab">
                                                                                            <div class="tb-content-wrap inner-2">
                                                                                                <div class="chart-wrap mb-20 mt-20">
                                                                                                    <div class="chart-title">
                                                                                                        <h4>MANHATTAN</h4>

                                                                                                        <div class="chart-trans-info">
                                                                                                            <p>India Innings <span class="dott"></span></p>
                                                                                                            <p>West Indies Innings <span class="dott yellow"></span></p>
                                                                                                        </div>
                                                                                                    </div>

                                                                                                    <div class="cart-img">
                                                                                                        <img src="assets/img/graph-1.png" alt="" />
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div class="chart-wrap mb-20">
                                                                                                    <div class="chart-title">
                                                                                                        <h4>Worm</h4>

                                                                                                        <div class="chart-trans-info">
                                                                                                            <p>India Innings <span class="dott"></span></p>
                                                                                                            <p>West Indies Innings <span class="dott yellow"></span></p>
                                                                                                        </div>
                                                                                                    </div>

                                                                                                    <div class="cart-img">
                                                                                                        <img src="assets/img/graph-2.png" alt="" />
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div class="chart-wrap mb-20">
                                                                                                    <div class="chart-title">
                                                                                                        <h4>PLAYER VS PLAYER</h4>

                                                                                                        <div class="chart-trans-info">
                                                                                                            <p>India Innings <span class="dott"></span></p>
                                                                                                            <p>West Indies Innings <span class="dott yellow"></span></p>
                                                                                                        </div>
                                                                                                    </div>

                                                                                                    <div class="cart-img">
                                                                                                        <img src="assets/img/graph-3.png" alt="" />
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div class="chart-wrap mb-20">
                                                                                                    <div class="chart-title">
                                                                                                        <h4>Runs</h4>

                                                                                                        <div class="chart-trans-info">
                                                                                                            <p>India Innings <span class="dott"></span></p>
                                                                                                            <p>West Indies Innings <span class="dott yellow"></span></p>
                                                                                                        </div>
                                                                                                    </div>

                                                                                                    <div class="cart-img">
                                                                                                        <img src="assets/img/graph-4.png" alt="" />
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div class="chart-wrap mb-20">
                                                                                                    <div class="chart-title">
                                                                                                        <h4>Wickets</h4>

                                                                                                        <div class="chart-trans-info">
                                                                                                            <p>India Innings <span class="dott"></span></p>
                                                                                                            <p>West Indies Innings <span class="dott yellow"></span></p>
                                                                                                        </div>
                                                                                                    </div>

                                                                                                    <div class="cart-img">
                                                                                                        <img src="assets/img/graph-5.png" alt="" />
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div class="chart-wrap mb-20">
                                                                                                    <div class="chart-title">
                                                                                                        <h4>Extras</h4>

                                                                                                        <div class="chart-trans-info">
                                                                                                            <p>India Innings <span class="dott"></span></p>
                                                                                                            <p>West Indies Innings <span class="dott yellow"></span></p>
                                                                                                        </div>
                                                                                                    </div>

                                                                                                    <div class="cart-img">
                                                                                                        <img src="assets/img/graph-6.png" alt="" />
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div class="chart-wrap mb-20">
                                                                                                    <div class="chart-title">
                                                                                                        <h4>Run Rate</h4>

                                                                                                        <div class="chart-trans-info">
                                                                                                            <p>India Innings <span class="dott"></span></p>
                                                                                                            <p>West Indies Innings <span class="dott yellow"></span></p>
                                                                                                        </div>
                                                                                                    </div>

                                                                                                    <div class="cart-img">
                                                                                                        <img src="assets/img/graph-4.png" alt="" />
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div class="tab-pane fade" id="tbTwo-22" role="tabpanel" aria-labelledby="tbTwo-22-tab">
                                                                                            <div class="tb-content-wrap inner-2">
                                                                                                <p>2</p>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div class="tab-pane fade" id="tbTwo-23" role="tabpanel" aria-labelledby="tbOne-23-tab">
                                                                                            <div class="tb-content-wrap inner-2">
                                                                                                <p>3</p>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div class="tab-pane fade" id="tbTwo-24" role="tabpanel" aria-labelledby="tbOne-24-tab">
                                                                                            <div class="tb-content-wrap inner-2">
                                                                                                <p>4</p>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div class="tab-pane fade" id="tbTwo-25" role="tabpanel" aria-labelledby="tbOne-25-tab">
                                                                                            <div class="tb-content-wrap inner-2">
                                                                                                <p>5</p>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div class="tab-pane fade" id="tbTwo-26" role="tabpanel" aria-labelledby="tbOne-26-tab">
                                                                                            <div class="tb-content-wrap inner-2">
                                                                                                <p>6</p>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div class="tab-pane fade" id="tbTwo-27" role="tabpanel" aria-labelledby="tbOne-27-tab">
                                                                                            <div class="tb-content-wrap inner-2">
                                                                                                <p>7</p>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div class="tab-pane fade" id="tbTwo-inner-22" role="tabpanel" aria-labelledby="tbTwo-inner-22-tab">
                                                                            <div class="tb-content-wrap inner-1">
                                                                                ...
                                                                </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="tab-pane fade" id="tbFive" role="tabpanel" aria-labelledby="tbFive-tab">
                                                            <div class="tb-content-wrap">
                                                                <div class="tabContainer-inner-1">
                                                                    <nav>
                                                                        <div class="nav nav-link-wrap inner-1" id="nav-tab" role="tablist">
                                                                            <a class="nav-item nav-link active" id="tbOne-line-1-tab" data-toggle="tab" href="#tbOne-line-1" role="tab" aria-controls="nav-home" aria-selected="true">India Innings

                                                                    <span class="extra-border"></span>
                                                                            </a>
                                                                            <a class="nav-item nav-link" id="tbTwo-line-2-tab" data-toggle="tab" href="#tbTwo-line-2" role="tab" aria-controls="nav-profile" aria-selected="false">West Indies Innings
                                                                    <span class="extra-border"></span>
                                                                            </a>
                                                                        </div>
                                                                    </nav>
                                                                    <div class="tab-content" id="nav-tabContent">
                                                                        <div class="tab-pane fade show active" id="tbOne-line-1" role="tabpanel" aria-labelledby="tbOne-inner-1-tab">
                                                                            <div class="tb-content-wrap inner-1">
                                                                                <div class="innings-player">
                                                                                    <div class="row">
                                                                                        <div class="col-lg-3 col-md-6 col-sm-6">
                                                                                            <div class="single-player-blk">
                                                                                                <div class="palyer-blk-top-blk">
                                                                                                    <div class="main-player-img">
                                                                                                        <img src="assets/img/player-img-m.png" alt="" />
                                                                                                    </div>
                                                                                                    <span><img src="assets/img/ball-main.svg" alt="" /></span>
                                                                                                    <span><img src={batMian} alt="" /></span>
                                                                                                </div>
                                                                                                <div class="player-all-info">
                                                                                                    <div class="player-title">
                                                                                                        <h3>Shakib Al Hasan</h3>
                                                                                                        <p>Batting: LHB | Bowling: LB</p>
                                                                                                    </div>
                                                                                                    <div class="palyber-main-info">
                                                                                                        <table class="table">
                                                                                                            <tbody>
                                                                                                                <tr>
                                                                                                                    <td>Innings:</td>
                                                                                                                    <td class="text-right">250</td>
                                                                                                                </tr>
                                                                                                                <tr>
                                                                                                                    <td>Runs:</td>
                                                                                                                    <td class="text-right">25000</td>
                                                                                                                </tr>
                                                                                                                <tr>
                                                                                                                    <td>Wickets:</td>
                                                                                                                    <td class="text-right">2000</td>
                                                                                                                </tr>
                                                                                                                <tr>
                                                                                                                    <td>Strike Rate:</td>
                                                                                                                    <td class="text-right">300</td>
                                                                                                                </tr>
                                                                                                                <tr>
                                                                                                                    <td>Econ. Rate:</td>
                                                                                                                    <td class="text-right">300</td>
                                                                                                                </tr>

                                                                                                            </tbody>
                                                                                                        </table>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div class="col-lg-3 col-md-6 col-sm-6">
                                                                                            <div class="single-player-blk">
                                                                                                <div class="palyer-blk-top-blk">
                                                                                                    <div class="main-player-img">
                                                                                                        <img src="assets/img/player-img-m.png" alt="" />
                                                                                                    </div>
                                                                                                    <span><img src="assets/img/ball-main.svg" alt="" /></span>
                                                                                                    <span><img src={batMian} alt="" /></span>
                                                                                                </div>
                                                                                                <div class="player-all-info">
                                                                                                    <div class="player-title">
                                                                                                        <h3>Shakib Al Hasan</h3>
                                                                                                        <p>Batting: LHB | Bowling: LB</p>
                                                                                                    </div>
                                                                                                    <div class="palyber-main-info">
                                                                                                        <table class="table">
                                                                                                            <tbody>
                                                                                                                <tr>
                                                                                                                    <td>Innings:</td>
                                                                                                                    <td class="text-right">250</td>
                                                                                                                </tr>
                                                                                                                <tr>
                                                                                                                    <td>Runs:</td>
                                                                                                                    <td class="text-right">25000</td>
                                                                                                                </tr>
                                                                                                                <tr>
                                                                                                                    <td>Wickets:</td>
                                                                                                                    <td class="text-right">2000</td>
                                                                                                                </tr>
                                                                                                                <tr>
                                                                                                                    <td>Strike Rate:</td>
                                                                                                                    <td class="text-right">300</td>
                                                                                                                </tr>
                                                                                                                <tr>
                                                                                                                    <td>Econ. Rate:</td>
                                                                                                                    <td class="text-right">300</td>
                                                                                                                </tr>

                                                                                                            </tbody>
                                                                                                        </table>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div class="col-lg-3 col-md-6 col-sm-6">
                                                                                            <div class="single-player-blk">
                                                                                                <div class="palyer-blk-top-blk">
                                                                                                    <div class="main-player-img">
                                                                                                        <img src="assets/img/player-img-m.png" alt="" />
                                                                                                    </div>
                                                                                                    <span><img src="assets/img/ball-main.svg" alt="" /></span>
                                                                                                    <span><img src={batMian} alt="" /></span>
                                                                                                </div>
                                                                                                <div class="player-all-info">
                                                                                                    <div class="player-title">
                                                                                                        <h3>Shakib Al Hasan</h3>
                                                                                                        <p>Batting: LHB | Bowling: LB</p>
                                                                                                    </div>
                                                                                                    <div class="palyber-main-info">
                                                                                                        <table class="table">
                                                                                                            <tbody>
                                                                                                                <tr>
                                                                                                                    <td>Innings:</td>
                                                                                                                    <td class="text-right">250</td>
                                                                                                                </tr>
                                                                                                                <tr>
                                                                                                                    <td>Runs:</td>
                                                                                                                    <td class="text-right">25000</td>
                                                                                                                </tr>
                                                                                                                <tr>
                                                                                                                    <td>Wickets:</td>
                                                                                                                    <td class="text-right">2000</td>
                                                                                                                </tr>
                                                                                                                <tr>
                                                                                                                    <td>Strike Rate:</td>
                                                                                                                    <td class="text-right">300</td>
                                                                                                                </tr>
                                                                                                                <tr>
                                                                                                                    <td>Econ. Rate:</td>
                                                                                                                    <td class="text-right">300</td>
                                                                                                                </tr>

                                                                                                            </tbody>
                                                                                                        </table>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div class="col-lg-3 col-md-6 col-sm-6">
                                                                                            <div class="single-player-blk">
                                                                                                <div class="palyer-blk-top-blk">
                                                                                                    <div class="main-player-img">
                                                                                                        <img src="assets/img/player-img-m.png" alt="" />
                                                                                                    </div>
                                                                                                    <span><img src="assets/img/ball-main.svg" alt="" /></span>
                                                                                                    <span><img src={batMian} alt="" /></span>
                                                                                                </div>
                                                                                                <div class="player-all-info">
                                                                                                    <div class="player-title">
                                                                                                        <h3>Shakib Al Hasan</h3>
                                                                                                        <p>Batting: LHB | Bowling: LB</p>
                                                                                                    </div>
                                                                                                    <div class="palyber-main-info">
                                                                                                        <table class="table">
                                                                                                            <tbody>
                                                                                                                <tr>
                                                                                                                    <td>Innings:</td>
                                                                                                                    <td class="text-right">250</td>
                                                                                                                </tr>
                                                                                                                <tr>
                                                                                                                    <td>Runs:</td>
                                                                                                                    <td class="text-right">25000</td>
                                                                                                                </tr>
                                                                                                                <tr>
                                                                                                                    <td>Wickets:</td>
                                                                                                                    <td class="text-right">2000</td>
                                                                                                                </tr>
                                                                                                                <tr>
                                                                                                                    <td>Strike Rate:</td>
                                                                                                                    <td class="text-right">300</td>
                                                                                                                </tr>
                                                                                                                <tr>
                                                                                                                    <td>Econ. Rate:</td>
                                                                                                                    <td class="text-right">300</td>
                                                                                                                </tr>

                                                                                                            </tbody>
                                                                                                        </table>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>

                                                                            </div>
                                                                        </div>
                                                                        <div class="tab-pane fade" id="tbTwo-line-2" role="tabpanel" aria-labelledby="tbTwo-inner-2-tab">
                                                                            <div class="tb-content-wrap inner-1">
                                                                                ...
                                                                </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="tab-pane fade" id="tbSix" role="tabpanel" aria-labelledby="tbSix-tab">
                                                            <div class="tb-content-wrap">
                                                                <div class="innings-player">
                                                                    <div class="row">
                                                                        <div class="col-lg-3 col-md-6 col-sm-6">
                                                                            <div class="single-player-blk s-info">
                                                                                <div class="pl-img-wrap">
                                                                                    <img src="assets/img/k-1.png" alt="" />
                                                                                </div>
                                                                                <h4>Toss</h4>
                                                                                <p>West Indies won <br /> the toss
                                                                        & <br />
                                                                                                        choose to BOWL</p>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-lg-3 col-md-6 col-sm-6">
                                                                            <div class="single-player-blk s-info">
                                                                                <div class="pl-img-wrap">
                                                                                    <img src="assets/img/k-2.png" alt="" />
                                                                                </div>
                                                                                <h4>Venue</h4>
                                                                                <p>Supersport Park <br />
                                                                                                            Centurion</p>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-lg-3 col-md-6 col-sm-6">
                                                                            <div class="single-player-blk s-info">
                                                                                <div class="pl-img-wrap">
                                                                                    <img src="assets/img/k-3.png" alt="" />
                                                                                </div>
                                                                                <h4>Umpires</h4>
                                                                                <p>Paul Reiffel Christopher Gaffaney H. Dharmasena
                                                                        Adrian Holdstock</p>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-lg-3 col-md-6 col-sm-6">
                                                                            <div class="single-player-blk s-info">
                                                                                <div class="pl-img-wrap">
                                                                                    <img src="assets/img/k-3.png" alt="" />
                                                                                </div>
                                                                                <h4>Match Referee</h4>
                                                                                <p>Andrew Pycroft</p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div class="col-lg-3">
                                <div class="sidebar-widget-wrapper">



                                    <div class="sidebar-widget trend-widget">
                                        <h4 class="heading-title">Latest News</h4>

                                        <nav>
                                            <ul>
                                                <li>
                                                    <div class="img"><NavLink to="/"><img src={trending} alt="image" /></NavLink></div>
                                                    <div class="content"><NavLink to="/">
                                                        <p>Lorem Ipsum is simply dummy text to print ...</p>
                                                    </NavLink></div>
                                                </li>
                                                <li>
                                                    <div class="img"><NavLink to="/"><img src={trending} alt="image" /></NavLink></div>
                                                    <div class="content"><NavLink to="/">
                                                        <p>Lorem Ipsum is simply dummy text to print ...</p>
                                                    </NavLink></div>
                                                </li>
                                                <li>
                                                    <div class="img"><NavLink to="/"><img src={trending} alt="image" /></NavLink></div>
                                                    <div class="content"><NavLink to="/">
                                                        <p>Lorem Ipsum is simply dummy text to print ...</p>
                                                    </NavLink></div>
                                                </li>
                                                <li>
                                                    <div class="img"><NavLink to="/"><img src={trending} alt="image" /></NavLink></div>
                                                    <div class="content"><NavLink to="/">
                                                        <p>Lorem Ipsum is simply dummy text to print ...</p>
                                                    </NavLink></div>
                                                </li>
                                                <li>
                                                    <div class="img"><NavLink to="/"><img src={trending} alt="image" /></NavLink></div>
                                                    <div class="content"><NavLink to="/">
                                                        <p>Lorem Ipsum is simply dummy text to print ...</p>
                                                    </NavLink></div>
                                                </li>
                                            </ul>
                                        </nav>
                                        <NavLink to="/" class="ld-more-btn">Load More</NavLink>
                                    </div>

                                    <div class="sidebar-widget popular-widget">
                                        <h4 class="heading-title">Most Popular</h4>

                                        <div>
                                            <div class="nav nav-tabs" id="nav-tab" role="tablist">
                                                <NavLink class="nav-item nav-NavLink active" data-toggle="tab" to="/nav-7">Today</NavLink>
                                                <NavLink class="nav-item nav-NavLink" data-toggle="tab" to="/nav-8">Last 7 Days</NavLink>
                                                <NavLink class="nav-item nav-NavLink" data-toggle="tab" to="/nav-9">Last 30 Days</NavLink>
                                            </div>
                                        </div>
                                        <div class="tab-content" id="nav-tabContent">
                                            <div class="tab-pane fade show active" id="nav-7">
                                                <nav>
                                                    <ul>
                                                        <li>
                                                            <span>1</span>
                                                            <NavLink to="/">
                                                                <p>Lorem Ipsum is simply dummy ..</p>
                                                            </NavLink>
                                                        </li>
                                                        <li>
                                                            <span>2</span>
                                                            <NavLink to="/">
                                                                <p>Lorem int and typesetting int and typesetting int and typesetting int and typesetting...</p>
                                                            </NavLink>
                                                        </li>
                                                        <li>
                                                            <span>3</span>
                                                            <NavLink to="/">
                                                                <p>Lorem int and typesetting int and typesetting int and typesetting int and typesetting...</p>
                                                            </NavLink>
                                                        </li>
                                                        <li>
                                                            <span>4</span>
                                                            <NavLink to="/">
                                                                <p>Lorem int and typesetting int and typesetting int and typesetting int and typesetting...</p>
                                                            </NavLink>
                                                        </li>
                                                        <li>
                                                            <span>5</span>
                                                            <NavLink to="/">
                                                                <p>Lorem int and typesetting int and typesetting int and typesetting int and typesetting...</p>
                                                            </NavLink>
                                                        </li>
                                                    </ul>
                                                </nav>

                                            </div>
                                            <div class="tab-pane fade" id="nav-8">

                                            </div>
                                            <div class="tab-pane fade" id="nav-9">


                                            </div>
                                        </div>



                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}
export default Comentray