import React, { Component, Fragment } from 'react'
import { NavLink } from 'react-router-dom'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// images 
import post1 from '../../images/newsupdate-thumbnail.png'
import flag from '../../images/flag.svg'
import indiaFlag from '../../images/f-india.png'
import compare from '../../images/compare-div.svg'
import trending from '../../images/trending-thumbnail.png'
import recent from '../../images/recent-svg.svg'
import ball from '../../images/ball.png'
import post2 from '../../images/post-img2.png'
import post3 from '../../images/post-img3.png'
import thumb from '../../images/post-thubmnail.png'
import bg from '../../images/event-page.jpg'
import logo from '../../images/ev-logo.png'


class Fixture extends Component {
    render() {

        return (
            <Fragment>
                <div class="inner-page-hero-area  fx-padding">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-lg-12">
                                <div class="inner-page-hero-content" style={{ background: `url(${bg})no-repeat center center / cover` }}>
                                    <div class="ev-iiner">
                                        <img src={logo} alt="" />
                                        <h2>U-19 Cricket World Cup</h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="news-content-area fx-padding">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-lg-9">
                                <div class="page-inner-content mb-10">
                                    <div class="team-menu">
                                        <ul>
                                            <li><NavLink activeClassName="active" to="/">News</NavLink></li>
                                            <li><NavLink to="/">Groups</NavLink></li>
                                            <li><NavLink to="/">Fixture</NavLink></li>
                                            <li><NavLink to="/">Results</NavLink></li>
                                            <li><NavLink to="/">Standings</NavLink></li>
                                            <li><NavLink to="/">Stats</NavLink></li>
                                        </ul>
                                    </div>
                                </div>


                                <div class="news-main-content">
                                    <div class="news-widget">
                                        <div class="row">
                                            <div class="col-lg-12">
                                                <div class="single-fixture-blk">
                                                    <div class="fx-top-blk">
                                                        <h4>Ban<span>gladesh</span> VS Ind<span>ia</span></h4>
                                                        <div class="fx-top-right-info">
                                                            <span>1st ODI</span>
                                                            <span>6-DEC-2019</span>
                                                            <span>4:00 PM</span>
                                                        </div>
                                                    </div>
                                                    <div class="fx-inner-part">
                                                        <div class="fx-inner-top-info">
                                                            <span>Live</span>
                                                        </div>
                                                        <div class="fx-inner-main-part">
                                                            <div class="single-team-info">
                                                                <img class="fx-flag-img" src={flag} alt="" />
                                                                <h3><img src={ball} alt="" /> ban<span>gladesh</span></h3>
                                                                <p>300 / 5 /<br /> <span class="over-texts">(50 OVERS)</span></p>
                                                            </div>
                                                            <div class="vs">
                                                                <h3>-VS-</h3>
                                                            </div>
                                                            <div class="single-team-info rt-blk">
                                                                <img class="fx-flag-img" src={indiaFlag} alt="" />
                                                                <h3>IND<span>ia</span><img src={ball} alt="" /> </h3>
                                                                <p>290 / 5 /<br /> <span class="over-texts">(50 OVERS)</span></p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <p class="para-des">India needs 11 more runs to win from 8 balls with 5 wickets in hand</p>
                                                    <div class="fx-bottom-blk">
                                                        <a href="">View match details</a>
                                                    </div>
                                                </div>
                                                <div class="single-fixture-blk">
                                                    <div class="fx-top-blk">
                                                        <h4>Bangladesh VS India</h4>
                                                        <div class="fx-top-right-info">
                                                            <span>1st ODI</span>
                                                            <span>6-DEC-2019</span>
                                                            <span>4:00 PM</span>
                                                        </div>
                                                    </div>
                                                    <div class="fx-inner-part">
                                                        <div class="fx-inner-top-info up-c">
                                                            <span>Upcoming</span>
                                                        </div>
                                                        <div class="fx-inner-main-part">
                                                            <div class="single-team-info">
                                                                <img class="fx-flag-img" src={flag} alt="" />
                                                                <h3><img src={ball} alt="" /> ban<span>gladesh</span></h3>
                                                                <p>300 / 5 /<br /> (50 OVERS)</p>
                                                            </div>
                                                            <div class="vs">
                                                                <h3>-VS-</h3>
                                                            </div>
                                                            <div class="single-team-info rt-blk">
                                                                <img class="fx-flag-img" src={indiaFlag} alt="" />
                                                                <h3>IND<span>ia</span><img src={ball} alt="" /> </h3>
                                                                <p>290 / 5 /<br /> (48.4 OVERS)</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <p class="para-des">India needs 11 more runs to win from 8 balls with 5 wickets in hand</p>
                                                    <div class="fx-bottom-blk">
                                                        <a href="">View match details</a>
                                                    </div>
                                                </div>
                                                <div class="single-fixture-blk">
                                                    <div class="fx-top-blk">
                                                        <h4>Bangladesh VS India</h4>
                                                        <div class="fx-top-right-info">
                                                            <span>1st ODI</span>
                                                            <span>6-DEC-2019</span>
                                                            <span>4:00 PM</span>
                                                        </div>
                                                    </div>
                                                    <div class="fx-inner-part">
                                                        <div class="fx-inner-top-info up-c">
                                                            <span>Upcoming</span>
                                                        </div>
                                                        <div class="fx-inner-main-part">
                                                            <div class="single-team-info">
                                                                <img class="fx-flag-img" src={flag} alt="" />
                                                                <h3><img src={ball} alt="" /> ban<span>gladesh</span></h3>
                                                                <p>300 / 5 /<br /> (50 OVERS)</p>
                                                            </div>
                                                            <div class="vs">
                                                                <h3>-VS-</h3>
                                                            </div>
                                                            <div class="single-team-info rt-blk">
                                                                <img class="fx-flag-img" src={indiaFlag} alt="" />
                                                                <h3>IND<span>ia</span><img src={ball} alt="" /> </h3>
                                                                <p>290 / 5 /<br /> (48.4 OVERS)</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <p class="para-des">India needs 11 more runs to win from 8 balls with 5 wickets in hand</p>
                                                    <div class="fx-bottom-blk">
                                                        <a href="">View match details</a>
                                                    </div>
                                                </div>
                                                <div class="single-fixture-blk">
                                                    <div class="fx-top-blk">
                                                        <h4>Bangladesh VS India</h4>
                                                        <div class="fx-top-right-info">
                                                            <span>1st ODI</span>
                                                            <span>6-DEC-2019</span>
                                                            <span>4:00 PM</span>
                                                        </div>
                                                    </div>
                                                    <div class="fx-inner-part">
                                                        <div class="fx-inner-top-info up-c">
                                                            <span>Upcoming</span>
                                                        </div>
                                                        <div class="fx-inner-main-part">
                                                            <div class="single-team-info">
                                                                <img class="fx-flag-img" src={flag} alt="" />
                                                                <h3><img src={ball} alt="" /> ban<span>gladesh</span></h3>
                                                                <p>300 / 5 /<br /> (50 OVERS)</p>
                                                            </div>
                                                            <div class="vs">
                                                                <h3>-VS-</h3>
                                                            </div>
                                                            <div class="single-team-info rt-blk">
                                                                <img class="fx-flag-img" src={indiaFlag} alt="" />
                                                                <h3>IND<span>ia</span><img src={ball} alt="" /> </h3>
                                                                <p>290 / 5 /<br /> (48.4 OVERS)</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <p class="para-des">India needs 11 more runs to win from 8 balls with 5 wickets in hand</p>
                                                    <div class="fx-bottom-blk">
                                                        <a href="">View match details</a>
                                                    </div>
                                                </div>
                                                <div class="single-fixture-blk">
                                                    <div class="fx-top-blk">
                                                        <h4>Bangladesh VS India</h4>
                                                        <div class="fx-top-right-info">
                                                            <span>1st ODI</span>
                                                            <span>6-DEC-2019</span>
                                                            <span>4:00 PM</span>
                                                        </div>
                                                    </div>
                                                    <div class="fx-inner-part">
                                                        <div class="fx-inner-top-info up-c">
                                                            <span>Upcoming</span>
                                                        </div>
                                                        <div class="fx-inner-main-part">
                                                            <div class="single-team-info">
                                                                <img class="fx-flag-img" src={flag} alt="" />
                                                                <h3><img src={ball} alt="" /> ban<span>gladesh</span></h3>
                                                                <p>300 / 5 /<br /> (50 OVERS)</p>
                                                            </div>
                                                            <div class="vs">
                                                                <h3>-VS-</h3>
                                                            </div>
                                                            <div class="single-team-info rt-blk">
                                                                <img class="fx-flag-img" src={indiaFlag} alt="" />
                                                                <h3>IND<span>ia</span><img src={ball} alt="" /> </h3>
                                                                <p>290 / 5 /<br /> (48.4 OVERS)</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <p class="para-des">India needs 11 more runs to win from 8 balls with 5 wickets in hand</p>
                                                    <div class="fx-bottom-blk">
                                                        <a href="">View match details</a>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-3">
                                <div class="sidebar-widget-wrapper">



                                    <div class="sidebar-widget trend-widget">
                                        <h4 class="heading-title">Latest News</h4>

                                        <nav>
                                            <ul>
                                                <li>
                                                    <div class="img"><NavLink to="/"><img src={trending} alt="image" /></NavLink></div>
                                                    <div class="content"><NavLink to="/">
                                                        <p>Lorem Ipsum is simply dummy text to print ...</p>
                                                    </NavLink></div>
                                                </li>
                                                <li>
                                                    <div class="img"><NavLink to="/"><img src={trending} alt="image" /></NavLink></div>
                                                    <div class="content"><NavLink to="/">
                                                        <p>Lorem Ipsum is simply dummy text to print ...</p>
                                                    </NavLink></div>
                                                </li>
                                                <li>
                                                    <div class="img"><NavLink to="/"><img src={trending} alt="image" /></NavLink></div>
                                                    <div class="content"><NavLink to="/">
                                                        <p>Lorem Ipsum is simply dummy text to print ...</p>
                                                    </NavLink></div>
                                                </li>
                                                <li>
                                                    <div class="img"><NavLink to="/"><img src={trending} alt="image" /></NavLink></div>
                                                    <div class="content"><NavLink to="/">
                                                        <p>Lorem Ipsum is simply dummy text to print ...</p>
                                                    </NavLink></div>
                                                </li>
                                                <li>
                                                    <div class="img"><NavLink to="/"><img src={trending} alt="image" /></NavLink></div>
                                                    <div class="content"><NavLink to="/">
                                                        <p>Lorem Ipsum is simply dummy text to print ...</p>
                                                    </NavLink></div>
                                                </li>
                                            </ul>
                                        </nav>
                                        <NavLink to="/" class="ld-more-btn">Load More</NavLink>
                                    </div>

                                    <div class="sidebar-widget popular-widget">
                                        <h4 class="heading-title">Most Popular</h4>

                                        <div>
                                            <div class="nav nav-tabs" id="nav-tab" role="tablist">
                                                <NavLink class="nav-item nav-NavLink active" data-toggle="tab" to="/nav-7">Today</NavLink>
                                                <NavLink class="nav-item nav-NavLink" data-toggle="tab" to="/nav-8">Last 7 Days</NavLink>
                                                <NavLink class="nav-item nav-NavLink" data-toggle="tab" to="/nav-9">Last 30 Days</NavLink>
                                            </div>
                                        </div>
                                        <div class="tab-content" id="nav-tabContent">
                                            <div class="tab-pane fade show active" id="nav-7">
                                                <nav>
                                                    <ul>
                                                        <li>
                                                            <span>1</span>
                                                            <NavLink to="/">
                                                                <p>Lorem Ipsum is simply dummy ..</p>
                                                            </NavLink>
                                                        </li>
                                                        <li>
                                                            <span>2</span>
                                                            <NavLink to="/">
                                                                <p>Lorem int and typesetting int and typesetting int and typesetting int and typesetting...</p>
                                                            </NavLink>
                                                        </li>
                                                        <li>
                                                            <span>3</span>
                                                            <NavLink to="/">
                                                                <p>Lorem int and typesetting int and typesetting int and typesetting int and typesetting...</p>
                                                            </NavLink>
                                                        </li>
                                                        <li>
                                                            <span>4</span>
                                                            <NavLink to="/">
                                                                <p>Lorem int and typesetting int and typesetting int and typesetting int and typesetting...</p>
                                                            </NavLink>
                                                        </li>
                                                        <li>
                                                            <span>5</span>
                                                            <NavLink to="/">
                                                                <p>Lorem int and typesetting int and typesetting int and typesetting int and typesetting...</p>
                                                            </NavLink>
                                                        </li>
                                                    </ul>
                                                </nav>

                                            </div>
                                            <div class="tab-pane fade" id="nav-8">

                                            </div>
                                            <div class="tab-pane fade" id="nav-9">


                                            </div>
                                        </div>



                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}
export default Fixture