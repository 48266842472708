import React, { Fragment } from 'react';
import { Route, BrowserRouter, Switch } from 'react-router-dom'
import HomePage from '../HomePage';
import LiveScores from '../LiveScores'
import International from '../International'
import Fixture from '../Fixture'
import Status from '../Status'
import Ranking from '../Ranking'
import Comentray from '../Comentray'
import Group from '../Group'
import LineUps from '../LineUps'
import PostDetails from '../PostDetails'
import Profile from '../Profile'
import Seris from '../Seris'
import Standing from '../Standing'
import Team from '../Team'
import TeamFixture from '../TeamFixture'
import TeamPlayer from '../TeamPlayer'
import TeamResult from '../TeamResult'
import Header from '../../components/Header'
import Footer from '../../components/Footer'
function App() {
    return (
        <Fragment>
            <Header />
            <Switch>
                <Route exact path="/" component={HomePage} />
                <Route exact path="/live-score" component={LiveScores} />
                <Route exact path="/international" component={International} />
                <Route exact path="/fixtures" component={Fixture} />
                <Route exact path="/ranking" component={Ranking} />
                <Route exact path="/comentray" component={Comentray} />
                <Route exact path="/group" component={Group} />
                <Route exact path="/line-ups" component={LineUps} />
                <Route exact path="/post-details" component={PostDetails} />
                <Route exact path="/profile" component={Profile} />
                <Route exact path="/seris" component={Seris} />
                <Route exact path="/standing" component={Standing} />
                <Route exact path="/team" component={Team} />
                <Route exact path="/team-fixture" component={TeamFixture} />
                <Route exact path="/team-player" component={TeamPlayer} />
                <Route exact path="/team-result" component={TeamResult} />
                <Route exact path="/stats" component={Status} />
            </Switch>
            <Footer />
        </Fragment>
    );
}

export default App;
