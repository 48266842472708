import React, { Fragment } from 'react'
import { Link, NavLink } from 'react-router-dom'
import logo from '../../images/site-main-logo.svg'
import { ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

const Header = () => {
    return (
        <Fragment>
            <header class="header-bar-section">
                <div class="header-bar-wrapper fx-padding">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-6 d-flex align-items-center">
                                <div class="header-top-left-text">
                                    <p>05, NOVEMBER 2019<span>|</span>11:00:00 PM</p>
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="header-top-right-text">
                                    <div class="lang-select-box">
                                        <div class="dropdown">
                                            <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                English
                                    </button>
                                            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                                <a class="dropdown-item" to="#">Bangla</a>

                                            </div>
                                        </div>
                                    </div>
                                    <div class="login-btn">
                                        <a to="/">LOGIN</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>


            <div class="site-logo-area">
                <div class="site-logo-area fx-padding">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-lg-12">
                                <div class="site-main-logo">
                                    <Link to="/"><img src={logo} alt="" /></Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="header-top-main-menu" id="sticker">
                <div class="header-top-main-wrapper fx-padding">
                    <div class="container-fluid">
                        <div class="main-menu-bar">

                            <nav class="navbar navbar-expand-lg">
                                <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarText">
                                    <span class="navbar-toggler-icon"></span>
                                </button>
                                <div class="collapse navbar-collapse" id="navbarText">
                                    <div class="stkey-logo">
                                        <img src="assets/img/logo-whitetext-02.png" alt="" />
                                    </div>
                                    <ul class="site-main-menu navbar-nav mr-auto">
                                        <li><NavLink exact to="/">HOME</NavLink></li>
                                        <li><NavLink exact to="/live-score">LIVE SCORES</NavLink></li>
                                        <li><NavLink exact to="/international">INTERNATIONAL</NavLink></li>
                                        <li><NavLink exact to="/international">PHOTOS</NavLink></li>
                                        <li><NavLink exact to="fixtures">FIXTURES</NavLink></li>
                                        <li><NavLink exact to="/stats">STATS</NavLink></li>
                                        <li><NavLink exact to="/ranking">RANKING</NavLink></li>
                                    </ul>
                                </div>
                                <div class="header-top-search-bar">
                                    <div class="header-top-search-bar-main">
                                        <button type="submit"><i class="fa fa-search"></i></button>
                                        <input type="text" placeholder="Search..." />
                                    </div>
                                </div>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>

            <div class="mobile-hs-menu fx-padding">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-lg-12">
                            <ul>
                                <li><NavLink exact to="/">HOME</NavLink></li>
                                <li><NavLink exact to="/live-score">LIVE SCORES</NavLink></li>
                                <li><NavLink exact to="/international">INTERNATIONAL</NavLink></li>
                                <li><NavLink exact to="/international">PHOTOS</NavLink></li>
                                <li><NavLink exact to="fixtures">FIXTURES</NavLink></li>
                                <li><NavLink exact to="/stats">STATS</NavLink></li>
                                <li><NavLink exact to="/ranking">RANKING</NavLink></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}
export default Header