
import React, { Fragment } from 'react'
import { Link, NavLink } from 'react-router-dom'


// images 
import banner from '../../images/add-banner.svg'
import logo from '../../images/footer-main-logo.svg'
import facebook from '../../images/facebook.svg'
import linkedin from '../../images/linkedin.svg'
import twitter from '../../images/facebook.svg'
import pinterest from '../../images/pinterest.svg'

const Footer = () => {
    return (
        <Fragment>

            <div class="footer-top-area fx-padding">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="top-app-banner">
                                <Link to="/"><img src={banner} alt="" /></Link>
                            </div>
                            <div class="subs-area">
                                <div class="row">
                                    <div class="col-md-6 d-flex align-items-center justify-content-end">
                                        <div class="subs-area-left-text">
                                            <h3>Subscribe to our NEWSLETTER</h3>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="subs-form">
                                            <div class="subs-form-main">
                                                <form action="">
                                                    <input type="text" placeholder="Enter Your Email" />
                                                    <button type="submit">Subscribe</button>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="footer-area ">
                <div class="footer-wrapper fx-padding ">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-lg-4">
                                <div class="footer-single-widget">
                                    <div class="footer-logo">
                                        <Link to="/"><img src={logo} alt="" /></Link>
                                    </div>
                                    <div class="footer-widget-texts">
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. </p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-3">
                                <div class="footer-single-widget">

                                    <div class="footer-widget-texts d-flex justify-content-start">
                                        <div class="footer-menu-single-blk">
                                            <ul>
                                                <li><Link to="/">HOME</Link></li>
                                                <li><Link to="/">LIVE SCORES</Link></li>
                                                <li><Link to="/">FIXTURES</Link></li>
                                                <li><Link to="/">INTERNATIONAL</Link></li>
                                                <li><Link to="/">BANGLADESH</Link></li>
                                            </ul>
                                        </div>
                                        <div class="footer-menu-single-blk">
                                            <ul>
                                                <li><Link to="/">HOME</Link></li>
                                                <li><Link to="/">LIVE SCORES</Link></li>
                                                <li><Link to="/">FIXTURES</Link></li>
                                                <li><Link to="/">INTERNATIONAL</Link></li>
                                                <li><Link to="/">BANGLADESH</Link></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-3 offset-lg-2">
                                <div class="footer-single-widget">

                                    <div class="footer-widget-texts">
                                        <div class="site-social-icons-links">
                                            <a href="#"><img src={facebook} alt="" /></a>
                                            <a href="#"><img src={twitter} alt="" /></a>
                                            <a href="#"><img src={linkedin} alt="" /></a>
                                            <a href="#"><img src={pinterest} alt="" /></a>
                                        </div>
                                        <div class="foote-action-buttons">
                                            <a href="#">CONTACT FOR ADVERTISING</a>
                                            <a href="#">BECOME A WRITER</a>
                                        </div>
                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>
                </div>
            </div>
            <div class="footer-cp-text">
                <p>© Copyright bdcrictime 2019</p>
            </div>
        </Fragment>
    )
}
export default Footer