import React, { Component, Fragment } from 'react'
import { Link, NavLink } from 'react-router-dom'
import Slider from 'react-slick'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// images 
import post1 from '../../images/newsupdate-thumbnail.png'
import country from '../../images/Bangladesh.svg'
import compare from '../../images/compare-div.svg'
import trending from '../../images/trending-thumbnail.png'
import recent from '../../images/recent-svg.svg'
import player from '../../images/player-img.png'
import post2 from '../../images/post-img2.png'
import post3 from '../../images/post-img3.png'
import thumb from '../../images/post-thubmnail.png'
let settings = {
    dots: false,
    centerMode: true,
    arrows: true,
    centerPadding: '50px',
    slidesToShow: 5,
    className: "live-match-slider",
    responsive: [
        {
            breakpoint: 1600,
            settings: {
                centerMode: true,
                centerPadding: '50px',
                slidesToShow: 5
            }
        },
        {
            breakpoint: 1400,
            settings: {
                centerMode: true,
                centerPadding: '50px',
                slidesToShow: 3
            }
        },
        {
            breakpoint: 992,
            settings: {
                centerMode: true,
                centerPadding: '50px',
                slidesToShow: 2
            }
        },
        {
            breakpoint: 768,
            settings: {
                centerMode: true,
                centerPadding: '50px',
                slidesToShow: 1
            }
        }
    ]
};
let settings2 = {
    dots: false,
    arrows: true,
    slidesToShow: 3,
    className: "post-slilder-main row",
    responsive: [
        {
            breakpoint: 1600,
            settings: {
                slidesToShow: 3
            }
        },
        {
            breakpoint: 1400,
            settings: {
                slidesToShow: 3
            }
        },
        {
            breakpoint: 992,
            settings: {
                slidesToShow: 2
            }
        },
        {
            breakpoint: 768,
            settings: {
                slidesToShow: 1
            }
        }
    ]
};
class HomePage extends Component {
    render() {

        return (
            <Fragment>

                <div class="live-match-area">
                    <Slider {...settings}>

                        <div class="item">
                            <div class="live-match-item">
                                <div class="title">
                                    <h5>WORLD CUP MATCH 14</h5>
                                </div>
                                <div class="compare-country">
                                    <span class="country-logo"><img src={country} alt="country img" /></span>
                                    <span class="country-vs">
                                        <p>VS</p>
                                    </span>
                                    <span class="country-logo"><img src={country} alt="country img" /></span>
                                </div>
                                <div class="compare-run">
                                    <div class="run">
                                        <h4>BAN 300 / 5 <span>50 OVERS</span></h4>
                                    </div>
                                    <div class="div"> <img src={compare} alt="svg" /> </div>
                                    <div class="run">
                                        <h4>BAN 300 / 5 <span>50 OVERS</span> </h4>
                                    </div>

                                </div>
                                <div class="compare-match">
                                    <p>Starts in 4 hours</p>
                                </div>
                            </div>
                        </div>
                        <div class="item">
                            <div class="live-match-item">
                                <div class="title">
                                    <h5>WORLD CUP MATCH 14</h5>
                                </div>
                                <div class="compare-country">
                                    <span class="country-logo"><img src={country} alt="country img" /></span>
                                    <span class="country-vs">
                                        <p>VS</p>
                                    </span>
                                    <span class="country-logo"><img src={country} alt="country img" /></span>
                                </div>
                                <div class="compare-run">
                                    <div class="run">
                                        <h4>BAN 300 / 5 <span>50 OVERS</span></h4>
                                    </div>
                                    <div class="div"> <img src={compare} alt="svg" /> </div>
                                    <div class="run">
                                        <h4>BAN 300 / 5 <span>50 OVERS</span> </h4>
                                    </div>

                                </div>
                                <div class="compare-match">
                                    <p>Starts in 4 hours</p>
                                </div>
                            </div>
                        </div>
                        <div class="item">
                            <div class="live-match-item">
                                <div class="title">
                                    <h5>WORLD CUP MATCH 14</h5>
                                </div>
                                <div class="compare-country">
                                    <span class="country-logo"><img src={country} alt="country img" /></span>
                                    <span class="country-vs">
                                        <p>VS</p>
                                    </span>
                                    <span class="country-logo"><img src={country} alt="country img" /></span>
                                </div>
                                <div class="compare-run">
                                    <div class="run">
                                        <h4>BAN 300 / 5 <span>50 OVERS</span></h4>
                                    </div>
                                    <div class="div"> <img src={compare} alt="svg" /> </div>
                                    <div class="run">
                                        <h4>BAN 300 / 5 <span>50 OVERS</span> </h4>
                                    </div>

                                </div>
                                <div class="compare-match">
                                    <p>Starts in 4 hours</p>
                                </div>
                            </div>
                        </div>
                        <div class="item">
                            <div class="live-match-item">
                                <div class="title">
                                    <h5>WORLD CUP MATCH 14</h5>
                                </div>
                                <div class="compare-country">
                                    <span class="country-logo"><img src={country} alt="country img" /></span>
                                    <span class="country-vs">
                                        <p>VS</p>
                                    </span>
                                    <span class="country-logo"><img src={country} alt="country img" /></span>
                                </div>
                                <div class="compare-run">
                                    <div class="run">
                                        <h4>BAN 300 / 5 <span>50 OVERS</span></h4>
                                    </div>
                                    <div class="div"> <img src={compare} alt="svg" /> </div>
                                    <div class="run">
                                        <h4>BAN 300 / 5 <span>50 OVERS</span> </h4>
                                    </div>

                                </div>
                                <div class="compare-match">
                                    <p>Starts in 4 hours</p>
                                </div>
                            </div>
                        </div>
                        <div class="item">
                            <div class="live-match-item">
                                <div class="title">
                                    <h5>WORLD CUP MATCH 14</h5>
                                </div>
                                <div class="compare-country">
                                    <span class="country-logo"><img src={country} alt="country img" /></span>
                                    <span class="country-vs">
                                        <p>VS</p>
                                    </span>
                                    <span class="country-logo"><img src={country} alt="country img" /></span>
                                </div>
                                <div class="compare-run">
                                    <div class="run">
                                        <h4>BAN 300 / 5 <span>50 OVERS</span></h4>
                                    </div>
                                    <div class="div"> <img src={compare} alt="svg" /> </div>
                                    <div class="run">
                                        <h4>BAN 300 / 5 <span>50 OVERS</span> </h4>
                                    </div>

                                </div>
                                <div class="compare-match">
                                    <p>Starts in 4 hours</p>
                                </div>
                            </div>
                        </div>
                        <div class="item">
                            <div class="live-match-item">
                                <div class="title">
                                    <h5>WORLD CUP MATCH 14</h5>
                                </div>
                                <div class="compare-country">
                                    <span class="country-logo"><img src={country} alt="country img" /></span>
                                    <span class="country-vs">
                                        <p>VS</p>
                                    </span>
                                    <span class="country-logo"><img src={country} alt="country img" /></span>
                                </div>
                                <div class="compare-run">
                                    <div class="run">
                                        <h4>BAN 300 / 5 <span>50 OVERS</span></h4>
                                    </div>
                                    <div class="div"> <img src={compare} alt="svg" /> </div>
                                    <div class="run">
                                        <h4>BAN 300 / 5 <span>50 OVERS</span> </h4>
                                    </div>

                                </div>
                                <div class="compare-match">
                                    <p>Starts in 4 hours</p>
                                </div>
                            </div>
                        </div>
                        <div class="item">
                            <div class="live-match-item">
                                <div class="title">
                                    <h5>WORLD CUP MATCH 14</h5>
                                </div>
                                <div class="compare-country">
                                    <span class="country-logo"><img src={country} alt="country img" /></span>
                                    <span class="country-vs">
                                        <p>VS</p>
                                    </span>
                                    <span class="country-logo"><img src={country} alt="country img" /></span>
                                </div>
                                <div class="compare-run">
                                    <div class="run">
                                        <h4>BAN 300 / 5 <span>50 OVERS</span></h4>
                                    </div>
                                    <div class="div"> <img src={compare} alt="svg" /> </div>
                                    <div class="run">
                                        <h4>BAN 300 / 5 <span>50 OVERS</span> </h4>
                                    </div>

                                </div>
                                <div class="compare-match">
                                    <p>Starts in 4 hours</p>
                                </div>
                            </div>
                        </div>
                        <div class="item">
                            <div class="live-match-item">
                                <div class="title">
                                    <h5>WORLD CUP MATCH 14</h5>
                                </div>
                                <div class="compare-country">
                                    <span class="country-logo"><img src={country} alt="country img" /></span>
                                    <span class="country-vs">
                                        <p>VS</p>
                                    </span>
                                    <span class="country-logo"><img src={country} alt="country img" /></span>
                                </div>
                                <div class="compare-run">
                                    <div class="run">
                                        <h4>BAN 300 / 5 <span>50 OVERS</span></h4>
                                    </div>
                                    <div class="div"> <img src={compare} alt="svg" /> </div>
                                    <div class="run">
                                        <h4>BAN 300 / 5 <span>50 OVERS</span> </h4>
                                    </div>

                                </div>
                                <div class="compare-match">
                                    <p>Starts in 4 hours</p>
                                </div>
                            </div>
                        </div>
                        <div class="item">
                            <div class="live-match-item">
                                <div class="title">
                                    <h5>WORLD CUP MATCH 14</h5>
                                </div>
                                <div class="compare-country">
                                    <span class="country-logo"><img src={country} alt="country img" /></span>
                                    <span class="country-vs">
                                        <p>VS</p>
                                    </span>
                                    <span class="country-logo"><img src={country} alt="country img" /></span>
                                </div>
                                <div class="compare-run">
                                    <div class="run">
                                        <h4>BAN 300 / 5 <span>50 OVERS</span></h4>
                                    </div>
                                    <div class="div"> <img src={compare} alt="svg" /> </div>
                                    <div class="run">
                                        <h4>BAN 300 / 5 <span>50 OVERS</span> </h4>
                                    </div>

                                </div>
                                <div class="compare-match">
                                    <p>Starts in 4 hours</p>
                                </div>
                            </div>
                        </div>
                        <div class="item">
                            <div class="live-match-item">
                                <div class="title">
                                    <h5>WORLD CUP MATCH 14</h5>
                                </div>
                                <div class="compare-country">
                                    <span class="country-logo"><img src={country} alt="country img" /></span>
                                    <span class="country-vs">
                                        <p>VS</p>
                                    </span>
                                    <span class="country-logo"><img src={country} alt="country img" /></span>
                                </div>
                                <div class="compare-run">
                                    <div class="run">
                                        <h4>BAN 300 / 5 <span>50 OVERS</span></h4>
                                    </div>
                                    <div class="div"> <img src={compare} alt="svg" /> </div>
                                    <div class="run">
                                        <h4>BAN 300 / 5 <span>50 OVERS</span> </h4>
                                    </div>

                                </div>
                                <div class="compare-match">
                                    <p>Starts in 4 hours</p>
                                </div>
                            </div>
                        </div>
                        <div class="item">
                            <div class="live-match-item">
                                <div class="title">
                                    <h5>WORLD CUP MATCH 14</h5>
                                </div>
                                <div class="compare-country">
                                    <span class="country-logo"><img src={country} alt="country img" /></span>
                                    <span class="country-vs">
                                        <p>VS</p>
                                    </span>
                                    <span class="country-logo"><img src={country} alt="country img" /></span>
                                </div>
                                <div class="compare-run">
                                    <div class="run">
                                        <h4>BAN 300 / 5 <span>50 OVERS</span></h4>
                                    </div>
                                    <div class="div"> <img src={compare} alt="svg" /> </div>
                                    <div class="run">
                                        <h4>BAN 300 / 5 <span>50 OVERS</span> </h4>
                                    </div>

                                </div>
                                <div class="compare-match">
                                    <p>Starts in 4 hours</p>
                                </div>
                            </div>
                        </div>
                    </Slider>
                </div>

                <div class="news-content-area fx-padding">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-lg-3 order-2 order-lg-1">
                                <div class="sidebar-widget-wrapper">
                                    <div class="sidebar-widget trend-widget">
                                        <h4 class="heading-title">Trending Now</h4>

                                        <nav>
                                            <ul>
                                                <li>
                                                    <div class="img"><a href="#"><img src={trending} alt="image" /></a></div>
                                                    <div class="content"><a href="#">
                                                        <p>Lorem Ipsum is simply dummy text to print ...</p>
                                                    </a></div>
                                                </li>
                                                <li>
                                                    <div class="img"><a href="#"><img src={trending} alt="image" /></a></div>
                                                    <div class="content"><a href="#">
                                                        <p>Lorem Ipsum is simply dummy text to print ...</p>
                                                    </a></div>
                                                </li>
                                            </ul>
                                        </nav>
                                    </div>
                                    <div class="sidebar-widget recend-widget">
                                        <h4 class="heading-title">Recent Series</h4>
                                        <nav>
                                            <ul>
                                                <li><span><img src={recent} alt="" /></span><a href="#">
                                                    <p>India v Bangladesh</p>
                                                </a></li>
                                                <li><span><img src={recent} alt="" /></span><a href="#">
                                                    <p>India v Bangladesh</p>
                                                </a></li>
                                                <li><span><img src={recent} alt="" /></span><a href="#">
                                                    <p>India v Bangladesh</p>
                                                </a></li>
                                                <li><span><img src={recent} alt="" /></span><a href="#">
                                                    <p>India v Bangladesh</p>
                                                </a></li>
                                            </ul>
                                        </nav>
                                    </div>
                                    <div class="sidebar-widget player-widget">
                                        <h4 class="heading-title">Players Data</h4>

                                        <nav>
                                            <div class="top-search-bar">
                                                <button type="submit"><i class="fa fa-search"></i></button>
                                                <input type="text" placeholder="Search player" />
                                            </div>
                                            <ul>
                                                <li><span><img src={player} alt="" /></span><a href="#">
                                                    <p>Shakib Al Hasan</p>
                                                </a></li>
                                                <li><span><img src={player} alt="" /></span><a href="#">
                                                    <p>Shakib Al Hasan</p>
                                                </a></li>
                                                <li><span><img src={player} alt="" /></span><a href="#">
                                                    <p>Shakib Al Hasan</p>
                                                </a></li>
                                            </ul>
                                        </nav>
                                    </div>

                                </div>
                            </div>
                            <div class="col-lg-6 order-1 order-lg-2">
                                <div class="news-main-content">


                                    <div class="news-tabs">
                                        <nav>
                                            <div class="nav nav-tabs" id="nav-tab">
                                                <a class="nav-item nav-link active" id="nav-home-tab" data-toggle="tab" href="#nav-1">Headline</a>
                                                <a class="nav-item nav-link" id="nav-profile-tab" data-toggle="tab" href="#nav-2">Latest News</a>
                                                <a class="nav-item nav-link" id="nav-contact-tab" data-toggle="tab" href="#nav-3">Popular News</a>

                                            </div>
                                        </nav>
                                        <div class="tab-content">
                                            <div class="tab-pane fade show active" id="nav-1">
                                                <div class="post">
                                                    <div class="img"><Link to="/"><img src={thumb} alt="img" /></Link></div>
                                                    <div class="content">
                                                        <Link to="/post-details">
                                                            <h4>LOREM IPSUM DOLOR SIT AMET, CONSE</h4>
                                                        </Link>
                                                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniamuis nostrud. Duis aute irure dolor in reprehenderit in voluptate velit esse pariatur... <Link to="/post-details" class="read-more">Read Ful Article</Link></p>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>

                                    </div>
                                    <div class="news-widget">
                                        <div class="title">
                                            <div class="left">
                                                <h6>News Updates</h6><span>10 November 2019</span>
                                            </div>
                                            <div class="right"><a href="#" class="see-all">See All</a></div>
                                        </div>
                                        <div class="row">
                                            <div class="col-lg-6 col-md-6">
                                                <div class="post2">
                                                    <div class="img"><Link to="/"><img src={post1} alt="img" /></Link></div>
                                                    <div class="content">
                                                        <Link to="/">
                                                            <h5>LOREM IPSUM DOLOR SIT AMET CONSECTETUR</h5>
                                                        </Link>
                                                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, q uis nostrud exercitationminim veniam, q uis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat...</p>
                                                        <Link to="/" class="read-more">READ MORE</Link>
                                                    </div>
                                                </div>

                                            </div>
                                            <div class="col-lg-6 col-md-6">
                                                <div class="post2">
                                                    <div class="img"><Link to="/"><img src={post1} alt="img" /></Link></div>
                                                    <div class="content">
                                                        <Link to="/">
                                                            <h5>LOREM IPSUM DOLOR SIT AMET CONSECTETUR</h5>
                                                        </Link>
                                                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, q uis nostrud exercitationminim veniam, q uis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat...</p>
                                                        <Link to="/" class="read-more">READ MORE</Link>
                                                    </div>
                                                </div>

                                            </div>

                                        </div>
                                        <div class="post3 ">
                                            <div class="row">
                                                <div class=" col-sm-4">
                                                    <div class="img">
                                                        <a href="#"><img src={post2} alt="img" /></a>
                                                    </div>
                                                </div>
                                                <div class="col-sm-8">
                                                    <div class="content">
                                                        <a href="#">
                                                            <h5>LOREM IPSUM DOLOR SIT AMET CONSECTETUR</h5>
                                                        </a>
                                                        <p>Lorem ipsum dolor sit amet, consectetur dipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. consectetur dipisicing elit, sed do eiusmod tempo consectetur Ut enim ad veniam...</p>
                                                        <a href="#" class="read-more">READ MORE</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="post3 mb-0">
                                            <div class="row">
                                                <div class=" col-sm-4">
                                                    <div class="img">
                                                        <a href="#"><img src={post2} alt="img" /></a>
                                                    </div>
                                                </div>
                                                <div class="col-sm-8">
                                                    <div class="content">
                                                        <a href="#">
                                                            <h5>LOREM IPSUM DOLOR SIT AMET CONSECTETUR</h5>
                                                        </a>
                                                        <p>Lorem ipsum dolor sit amet, consectetur dipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. consectetur dipisicing elit, sed do eiusmod tempo consectetur Ut enim ad veniam...</p>
                                                        <a href="#" class="read-more">READ MORE</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="news-widget">
                                        <div class="title">
                                            <div class="left">
                                                <h6>Bangladesh vs India</h6><span>10 November 2019</span>
                                            </div>
                                            <div class="right"><a href="#" class="see-all">See All</a></div>
                                        </div>
                                        <div class="post3 ">
                                            <div class="row">
                                                <div class=" col-sm-4">
                                                    <div class="img">
                                                        <a href="#"><img src={post2} alt="img" /></a>
                                                    </div>
                                                </div>
                                                <div class="col-sm-8">
                                                    <div class="content">
                                                        <a href="#">
                                                            <h5>LOREM IPSUM DOLOR SIT AMET CONSECTETUR</h5>
                                                        </a>
                                                        <p>Lorem ipsum dolor sit amet, consectetur dipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. consectetur dipisicing elit, sed do eiusmod tempo consectetur Ut enim ad veniam...</p>
                                                        <a href="#" class="read-more">READ MORE</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="post3 mb-0">
                                            <div class="row">
                                                <div class=" col-sm-4">
                                                    <div class="img">
                                                        <a href="#"><img src={post2} alt="img" /></a>
                                                    </div>
                                                </div>
                                                <div class="col-sm-8">
                                                    <div class="content">
                                                        <a href="#">
                                                            <h5>LOREM IPSUM DOLOR SIT AMET CONSECTETUR</h5>
                                                        </a>
                                                        <p>Lorem ipsum dolor sit amet, consectetur dipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. consectetur dipisicing elit, sed do eiusmod tempo consectetur Ut enim ad veniam...</p>
                                                        <a href="#" class="read-more">READ MORE</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="news-widget pb-0">
                                        <div class="title">
                                            <div class="left">
                                                <h6>Related News</h6>
                                            </div>

                                        </div>

                                        <div class="row">
                                            <div class="col-lg-12">
                                                <Slider {...settings2}>
                                                    <div className="col-12">
                                                        <div class="post4">
                                                            <div class="img"><a href="#"><img src={post3} alt="img" /></a></div>
                                                            <div class="content">
                                                                <a href="#">
                                                                    <h6>LOREM IPSUM DOLOR SIT AMET</h6>
                                                                </a>
                                                                <span>10 NOV 2019</span>
                                                            </div>

                                                        </div>
                                                    </div>
                                                    <div className="col-12">
                                                        <div class="post4">
                                                            <div class="img"><a href="#"><img src={post3} alt="img" /></a></div>
                                                            <div class="content">
                                                                <a href="#">
                                                                    <h6>LOREM IPSUM DOLOR SIT AMET</h6>
                                                                </a>
                                                                <span>10 NOV 2019</span>
                                                            </div>

                                                        </div>
                                                    </div>
                                                    <div className="col-12">
                                                        <div class="post4">
                                                            <div class="img"><a href="#"><img src={post3} alt="img" /></a></div>
                                                            <div class="content">
                                                                <a href="#">
                                                                    <h6>LOREM IPSUM DOLOR SIT AMET</h6>
                                                                </a>
                                                                <span>10 NOV 2019</span>
                                                            </div>

                                                        </div>
                                                    </div>
                                                    <div className="col-12">
                                                        <div class="post4">
                                                            <div class="img"><a href="#"><img src={post3} alt="img" /></a></div>
                                                            <div class="content">
                                                                <a href="#">
                                                                    <h6>LOREM IPSUM DOLOR SIT AMET</h6>
                                                                </a>
                                                                <span>10 NOV 2019</span>
                                                            </div>

                                                        </div>
                                                    </div>
                                                    <div className="col-12">
                                                        <div class="post4">
                                                            <div class="img"><a href="#"><img src={post3} alt="img" /></a></div>
                                                            <div class="content">
                                                                <a href="#">
                                                                    <h6>LOREM IPSUM DOLOR SIT AMET</h6>
                                                                </a>
                                                                <span>10 NOV 2019</span>
                                                            </div>

                                                        </div>
                                                    </div>
                                                    <div className="col-12">
                                                        <div class="post4">
                                                            <div class="img"><a href="#"><img src={post3} alt="img" /></a></div>
                                                            <div class="content">
                                                                <a href="#">
                                                                    <h6>LOREM IPSUM DOLOR SIT AMET</h6>
                                                                </a>
                                                                <span>10 NOV 2019</span>
                                                            </div>

                                                        </div>
                                                    </div>
                                                    <div className="col-12">
                                                        <div class="post4">
                                                            <div class="img"><a href="#"><img src={post3} alt="img" /></a></div>
                                                            <div class="content">
                                                                <a href="#">
                                                                    <h6>LOREM IPSUM DOLOR SIT AMET</h6>
                                                                </a>
                                                                <span>10 NOV 2019</span>
                                                            </div>

                                                        </div>
                                                    </div>
                                                    <div className="col-12">
                                                        <div class="post4">
                                                            <div class="img"><a href="#"><img src={post3} alt="img" /></a></div>
                                                            <div class="content">
                                                                <a href="#">
                                                                    <h6>LOREM IPSUM DOLOR SIT AMET</h6>
                                                                </a>
                                                                <span>10 NOV 2019</span>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </Slider>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-3 order-3 order-lg-3">
                                <div class="sidebar-widget-wrapper">


                                    <div class="sidebar-widget rank-widget">
                                        <h4 class="heading-title">ICC TEAM RANKING</h4>

                                        <div>
                                            <div class="nav nav-tabs" id="nav-tab" role="tablist">
                                                <a class="nav-item nav-link active" data-toggle="tab" href="#nav-4">ODI</a>
                                                <a class="nav-item nav-link" data-toggle="tab" href="#nav-5">T20</a>
                                                <a class="nav-item nav-link" data-toggle="tab" href="#nav-6">TEST</a>
                                            </div>
                                        </div>
                                        <div class="tab-content" id="nav-tabContent">
                                            <div class="tab-pane fade show active" id="nav-4">
                                                <nav>
                                                    <ul>
                                                        <li>
                                                            <ol>
                                                                <li>
                                                                    <p>Pos</p>
                                                                </li>
                                                                <li>
                                                                    <p>Team</p>
                                                                </li>
                                                                <li>
                                                                    <p>Rating</p>
                                                                </li>
                                                            </ol>

                                                        </li>
                                                        <li>
                                                            <ol>
                                                                <li>
                                                                    <p>1</p>
                                                                </li>
                                                                <li>
                                                                    <p>Pakistan</p>
                                                                </li>
                                                                <li>
                                                                    <p>270</p>
                                                                </li>
                                                            </ol>

                                                        </li>
                                                        <li>
                                                            <ol>
                                                                <li>
                                                                    <p>1</p>
                                                                </li>
                                                                <li>
                                                                    <p>Pakistan</p>
                                                                </li>
                                                                <li>
                                                                    <p>270</p>
                                                                </li>
                                                            </ol>

                                                        </li>
                                                        <li>
                                                            <ol>
                                                                <li>
                                                                    <p>1</p>
                                                                </li>
                                                                <li>
                                                                    <p>Pakistan</p>
                                                                </li>
                                                                <li>
                                                                    <p>270</p>
                                                                </li>
                                                            </ol>

                                                        </li>
                                                        <li>
                                                            <ol>
                                                                <li>
                                                                    <p>1</p>
                                                                </li>
                                                                <li>
                                                                    <p>Pakistan</p>
                                                                </li>
                                                                <li>
                                                                    <p>270</p>
                                                                </li>
                                                            </ol>

                                                        </li>
                                                        <li><a href="#">watch full ranking</a></li>
                                                    </ul>
                                                </nav>

                                            </div>
                                            <div class="tab-pane fade" id="nav-5">

                                            </div>
                                            <div class="tab-pane fade" id="nav-6">


                                            </div>
                                        </div>





                                    </div>
                                    <div class="sidebar-widget popular-widget">
                                        <h4 class="heading-title">Most Popular</h4>

                                        <div>
                                            <div class="nav nav-tabs" id="nav-tab" role="tablist">
                                                <a class="nav-item nav-link active" data-toggle="tab" href="#nav-7">Today</a>
                                                <a class="nav-item nav-link" data-toggle="tab" href="#nav-8">Last 7 Days</a>
                                                <a class="nav-item nav-link" data-toggle="tab" href="#nav-9">Last 30 Days</a>
                                            </div>
                                        </div>
                                        <div class="tab-content" id="nav-tabContent">
                                            <div class="tab-pane fade show active" id="nav-7">
                                                <nav>
                                                    <ul>
                                                        <li>
                                                            <span>1</span>
                                                            <a href="#">
                                                                <p>Lorem Ipsum is simply dummy ..</p>
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <span>3</span>
                                                            <a href="#">
                                                                <p>Lorem int and typesetting int and typesetting int and typesetting int and typesetting...</p>
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </nav>

                                            </div>
                                            <div class="tab-pane fade" id="nav-8">

                                            </div>
                                            <div class="tab-pane fade" id="nav-9">


                                            </div>
                                        </div>



                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}
export default HomePage