import React, { Component, Fragment } from 'react'
import { NavLink } from 'react-router-dom'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// images 
import post1 from '../../images/newsupdate-thumbnail.png'
import flag from '../../images/flag.svg'
import indiaFlag from '../../images/f-india.png'
import compare from '../../images/compare-div.svg'
import trending from '../../images/trending-thumbnail.png'
import recent from '../../images/recent-svg.svg'
import ball from '../../images/ball.png'
import post2 from '../../images/post-img2.png'
import post3 from '../../images/post-img3.png'
import thumb from '../../images/post-thubmnail.png'
import bg from '../../images/event-page.jpg'
import logo from '../../images/ev-logo.png'
import player from '../../images/player-1.png'


class Status extends Component {
    render() {

        return (
            <Fragment>
                <div class="inner-page-hero-area  fx-padding">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-lg-12">
                                <div class="inner-page-hero-content" style={{ background: `url(${bg})no-repeat center center / cover` }}>
                                    <div class="ev-iiner">
                                        <img src={logo} alt="" />
                                        <h2>U-19 Cricket World Cup</h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="news-content-area fx-padding">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-lg-9">
                                <div class="page-inner-content mb-10">
                                    <div class="team-menu">
                                        <ul>
                                            <li><NavLink activeClassName="active" to="/">News</NavLink></li>
                                            <li><NavLink to="/">Groups</NavLink></li>
                                            <li><NavLink to="/">Fixture</NavLink></li>
                                            <li><NavLink to="/">Results</NavLink></li>
                                            <li><NavLink to="/">Standings</NavLink></li>
                                            <li><NavLink to="/">Stats</NavLink></li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="news-main-content">



                                    <div class="news-widget tbl-responsive-purpose">

                                        <div class="single-group-list stats">
                                            <div class="cat-select">
                                                <select name="" id="">
                                                    <option value="">Most Runs</option>
                                                    <option value="">Most Wickets</option>
                                                    <option value="">Most Sixes</option>
                                                    <option value="">Most 100s</option>
                                                    <option value="">Most 50s</option>
                                                    <option value="">Most Runs</option>
                                                    <option value="">Fastest 100s</option>
                                                </select>
                                            </div>
                                            <div class="grp-table">
                                                <table class="table table-striped">
                                                    <thead>
                                                        <tr>
                                                            <th>Position</th>
                                                            <th>Team</th>
                                                            <th>Matches</th>
                                                            <th class="d-n">Innings</th>
                                                            <th class="d-n">H/S</th>
                                                            <th class="d-n">Avg</th>
                                                            <th class="d-n">50s/100s</th>
                                                            <th class="d-n">4s/6s</th>
                                                            <th>Runs</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td>1</td>
                                                            <td><img src={player} alt="" /><strong>Batsman Name</strong></td>
                                                            <td>3</td>
                                                            <td class="d-n">3</td>
                                                            <td class="d-n">0</td>
                                                            <td class="d-n">0</td>
                                                            <td class="d-n">0</td>
                                                            <td class="d-n">+3.598</td>
                                                            <td>6</td>
                                                        </tr>
                                                        <tr>
                                                            <td>1</td>
                                                            <td><img src={player} alt="" /><strong>Batsman Name</strong></td>
                                                            <td>3</td>
                                                            <td class="d-n">3</td>
                                                            <td class="d-n">0</td>
                                                            <td class="d-n">0</td>
                                                            <td class="d-n">0</td>
                                                            <td class="d-n">+3.598</td>
                                                            <td>6</td>
                                                        </tr>
                                                        <tr>
                                                            <td>1</td>
                                                            <td><img src={player} alt="" /><strong>Batsman Name</strong></td>
                                                            <td>3</td>
                                                            <td class="d-n">3</td>
                                                            <td class="d-n">0</td>
                                                            <td class="d-n">0</td>
                                                            <td class="d-n">0</td>
                                                            <td class="d-n">+3.598</td>
                                                            <td>6</td>
                                                        </tr>
                                                        <tr>
                                                            <td>1</td>
                                                            <td><img src={player} alt="" /><strong>Batsman Name</strong></td>
                                                            <td>3</td>
                                                            <td class="d-n">3</td>
                                                            <td class="d-n">0</td>
                                                            <td class="d-n">0</td>
                                                            <td class="d-n">0</td>
                                                            <td class="d-n">+3.598</td>
                                                            <td>6</td>
                                                        </tr>
                                                        <tr>
                                                            <td>1</td>
                                                            <td><img src={player} alt="" /><strong>Batsman Name</strong></td>
                                                            <td>3</td>
                                                            <td class="d-n">3</td>
                                                            <td class="d-n">0</td>
                                                            <td class="d-n">0</td>
                                                            <td class="d-n">0</td>
                                                            <td class="d-n">+3.598</td>
                                                            <td>6</td>
                                                        </tr>
                                                        <tr>
                                                            <td>1</td>
                                                            <td><img src={player} alt="" /><strong>Batsman Name</strong></td>
                                                            <td>3</td>
                                                            <td class="d-n">3</td>
                                                            <td class="d-n">0</td>
                                                            <td class="d-n">0</td>
                                                            <td class="d-n">0</td>
                                                            <td class="d-n">+3.598</td>
                                                            <td>6</td>
                                                        </tr>
                                                        <tr>
                                                            <td>1</td>
                                                            <td><img src={player} alt="" /><strong>Batsman Name</strong></td>
                                                            <td>3</td>
                                                            <td class="d-n">3</td>
                                                            <td class="d-n">0</td>
                                                            <td class="d-n">0</td>
                                                            <td class="d-n">0</td>
                                                            <td class="d-n">+3.598</td>
                                                            <td>6</td>
                                                        </tr>

                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        <div class="single-group-list stats">
                                            <div class="cat-select">
                                                <select name="" id="">
                                                    <option value="">Most Runs</option>
                                                    <option value="">Most Wickets</option>
                                                    <option value="">Most Sixes</option>
                                                    <option value="">Most 100s</option>
                                                    <option value="">Most 50s</option>
                                                    <option value="">Most Runs</option>
                                                    <option value="">Fastest 100s</option>
                                                </select>
                                            </div>
                                            <div class="grp-table">
                                                <table class="table table-striped">
                                                    <thead>
                                                        <tr>
                                                            <th>Position</th>
                                                            <th>Team</th>
                                                            <th>Matches</th>
                                                            <th class="d-n">Innings</th>
                                                            <th class="d-n">H/S</th>
                                                            <th class="d-n">Avg</th>
                                                            <th class="d-n">50s/100s</th>
                                                            <th class="d-n">4s/6s</th>
                                                            <th>Runs</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td>1</td>
                                                            <td><img src={player} alt="" /><strong>Batsman Name</strong></td>
                                                            <td>3</td>
                                                            <td class="d-n">3</td>
                                                            <td class="d-n">0</td>
                                                            <td class="d-n">0</td>
                                                            <td class="d-n">0</td>
                                                            <td class="d-n">+3.598</td>
                                                            <td>6</td>
                                                        </tr>
                                                        <tr>
                                                            <td>1</td>
                                                            <td><img src={player} alt="" /><strong>Batsman Name</strong></td>
                                                            <td>3</td>
                                                            <td class="d-n">3</td>
                                                            <td class="d-n">0</td>
                                                            <td class="d-n">0</td>
                                                            <td class="d-n">0</td>
                                                            <td class="d-n">+3.598</td>
                                                            <td>6</td>
                                                        </tr>
                                                        <tr>
                                                            <td>1</td>
                                                            <td><img src={player} alt="" /><strong>Batsman Name</strong></td>
                                                            <td>3</td>
                                                            <td class="d-n">3</td>
                                                            <td class="d-n">0</td>
                                                            <td class="d-n">0</td>
                                                            <td class="d-n">0</td>
                                                            <td class="d-n">+3.598</td>
                                                            <td>6</td>
                                                        </tr>
                                                        <tr>
                                                            <td>1</td>
                                                            <td><img src={player} alt="" /><strong>Batsman Name</strong></td>
                                                            <td>3</td>
                                                            <td class="d-n">3</td>
                                                            <td class="d-n">0</td>
                                                            <td class="d-n">0</td>
                                                            <td class="d-n">0</td>
                                                            <td class="d-n">+3.598</td>
                                                            <td>6</td>
                                                        </tr>
                                                        <tr>
                                                            <td>1</td>
                                                            <td><img src={player} alt="" /><strong>Batsman Name</strong></td>
                                                            <td>3</td>
                                                            <td class="d-n">3</td>
                                                            <td class="d-n">0</td>
                                                            <td class="d-n">0</td>
                                                            <td class="d-n">0</td>
                                                            <td class="d-n">+3.598</td>
                                                            <td>6</td>
                                                        </tr>
                                                        <tr>
                                                            <td>1</td>
                                                            <td><img src={player} alt="" /><strong>Batsman Name</strong></td>
                                                            <td>3</td>
                                                            <td class="d-n">3</td>
                                                            <td class="d-n">0</td>
                                                            <td class="d-n">0</td>
                                                            <td class="d-n">0</td>
                                                            <td class="d-n">+3.598</td>
                                                            <td>6</td>
                                                        </tr>
                                                        <tr>
                                                            <td>1</td>
                                                            <td><img src={player} alt="" /><strong>Batsman Name</strong></td>
                                                            <td>3</td>
                                                            <td class="d-n">3</td>
                                                            <td class="d-n">0</td>
                                                            <td class="d-n">0</td>
                                                            <td class="d-n">0</td>
                                                            <td class="d-n">+3.598</td>
                                                            <td>6</td>
                                                        </tr>

                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>


                                    </div>







                                </div>
                            </div>
                            <div class="col-lg-3">
                                <div class="sidebar-widget-wrapper">



                                    <div class="sidebar-widget trend-widget">
                                        <h4 class="heading-title">Latest News</h4>

                                        <nav>
                                            <ul>
                                                <li>
                                                    <div class="img"><NavLink to="/"><img src={trending} alt="image" /></NavLink></div>
                                                    <div class="content"><NavLink to="/">
                                                        <p>Lorem Ipsum is simply dummy text to print ...</p>
                                                    </NavLink></div>
                                                </li>
                                                <li>
                                                    <div class="img"><NavLink to="/"><img src={trending} alt="image" /></NavLink></div>
                                                    <div class="content"><NavLink to="/">
                                                        <p>Lorem Ipsum is simply dummy text to print ...</p>
                                                    </NavLink></div>
                                                </li>
                                                <li>
                                                    <div class="img"><NavLink to="/"><img src={trending} alt="image" /></NavLink></div>
                                                    <div class="content"><NavLink to="/">
                                                        <p>Lorem Ipsum is simply dummy text to print ...</p>
                                                    </NavLink></div>
                                                </li>
                                                <li>
                                                    <div class="img"><NavLink to="/"><img src={trending} alt="image" /></NavLink></div>
                                                    <div class="content"><NavLink to="/">
                                                        <p>Lorem Ipsum is simply dummy text to print ...</p>
                                                    </NavLink></div>
                                                </li>
                                                <li>
                                                    <div class="img"><NavLink to="/"><img src={trending} alt="image" /></NavLink></div>
                                                    <div class="content"><NavLink to="/">
                                                        <p>Lorem Ipsum is simply dummy text to print ...</p>
                                                    </NavLink></div>
                                                </li>
                                            </ul>
                                        </nav>
                                        <NavLink to="/" class="ld-more-btn">Load More</NavLink>
                                    </div>

                                    <div class="sidebar-widget popular-widget">
                                        <h4 class="heading-title">Most Popular</h4>

                                        <div>
                                            <div class="nav nav-tabs" id="nav-tab" role="tablist">
                                                <NavLink class="nav-item nav-NavLink active" data-toggle="tab" to="/nav-7">Today</NavLink>
                                                <NavLink class="nav-item nav-NavLink" data-toggle="tab" to="/nav-8">Last 7 Days</NavLink>
                                                <NavLink class="nav-item nav-NavLink" data-toggle="tab" to="/nav-9">Last 30 Days</NavLink>
                                            </div>
                                        </div>
                                        <div class="tab-content" id="nav-tabContent">
                                            <div class="tab-pane fade show active" id="nav-7">
                                                <nav>
                                                    <ul>
                                                        <li>
                                                            <span>1</span>
                                                            <NavLink to="/">
                                                                <p>Lorem Ipsum is simply dummy ..</p>
                                                            </NavLink>
                                                        </li>
                                                        <li>
                                                            <span>2</span>
                                                            <NavLink to="/">
                                                                <p>Lorem int and typesetting int and typesetting int and typesetting int and typesetting...</p>
                                                            </NavLink>
                                                        </li>
                                                        <li>
                                                            <span>3</span>
                                                            <NavLink to="/">
                                                                <p>Lorem int and typesetting int and typesetting int and typesetting int and typesetting...</p>
                                                            </NavLink>
                                                        </li>
                                                        <li>
                                                            <span>4</span>
                                                            <NavLink to="/">
                                                                <p>Lorem int and typesetting int and typesetting int and typesetting int and typesetting...</p>
                                                            </NavLink>
                                                        </li>
                                                        <li>
                                                            <span>5</span>
                                                            <NavLink to="/">
                                                                <p>Lorem int and typesetting int and typesetting int and typesetting int and typesetting...</p>
                                                            </NavLink>
                                                        </li>
                                                    </ul>
                                                </nav>

                                            </div>
                                            <div class="tab-pane fade" id="nav-8">

                                            </div>
                                            <div class="tab-pane fade" id="nav-9">


                                            </div>
                                        </div>



                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}
export default Status