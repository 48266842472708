import React, { Component, Fragment } from 'react'
import { Link } from 'react-router-dom'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// images 
import flag from '../../images/flag.svg'
import trending from '../../images/trending-thumbnail.png'
import bg from '../../images/team-header-banner.png'
import logo from '../../images/ev-logo.png'
import post2 from '../../images/post-img2.png'
import facebook from '../../images/facebook.svg'
import dribble from '../../images/dribble.svg'
import twitter from '../../images/twitter.svg'
import insta from '../../images/insta.svg'
import post1 from '../../images/newsupdate-thumbnail.png'
import tiger from '../../images/tiger-img.svg'

class Team extends Component {
    render() {

        return (
            <Fragment>

                <div class="inner-page-hero-area  fx-padding">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-lg-12">
                                <div class="inner-page-hero-content" style={{ background: `url(${bg})no-repeat center center / cover` }}>
                                    <div class="hero-page-inner-top-texts">
                                        <div class="left-hr-flag">
                                            <img src={flag} alt="" />
                                        </div>
                                        <div class="hr-rt-dets">
                                            <h2>Bangladesh</h2>
                                            <div class="social-link-list">
                                                <Link to="/"><img src={dribble} alt="" /></Link>
                                                <Link to="/"><img src={facebook} alt="" /></Link>
                                                <Link to="/"><img src={twitter} alt="" /></Link>
                                                <Link to="/"><img src={insta} alt="" /></Link>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="bottom-buttons-list">
                                        <Link to="/">TEST <span>9</span></Link>
                                        <Link to="/">TEST <span>9</span></Link>
                                        <Link to="/">TEST <span>9</span></Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="news-content-area fx-padding">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-lg-9">
                                <div class="page-inner-content">
                                    <div class="team-menu">
                                        <ul>
                                            <li><Link tos="active-menu" href="">News</Link></li>
                                            <li><Link to="/">Fixture</Link></li>
                                            <li><Link to="/">Results</Link></li>
                                            <li><Link to="/">Players</Link></li>
                                            <li><Link to="/">Gallery</Link></li>
                                        </ul>
                                    </div>
                                </div>


                                <div class="news-main-content">



                                    <div class="news-widget">

                                        <div class="row">
                                            <div class="col-lg-4 col-md-6">
                                                <div class="post2">
                                                    <div class="img"><Link to="/"><img src={post1} alt="img" /></Link></div>
                                                    <div class="content">
                                                        <Link to="/">
                                                            <h5>LOREM IPSUM DOLOR SIT AMET CONSECTETUR</h5>
                                                        </Link>
                                                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, q uis nostrud exercitationminim veniam, q uis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat...</p>
                                                        <Link to="/" class="read-more">READ MORE</Link>
                                                    </div>
                                                </div>

                                            </div>
                                            <div class="col-lg-4 col-md-6">
                                                <div class="post2">
                                                    <div class="img"><Link to="/"><img src={post1} alt="img" /></Link></div>
                                                    <div class="content">
                                                        <Link to="/">
                                                            <h5>LOREM IPSUM DOLOR SIT AMET CONSECTETUR</h5>
                                                        </Link>
                                                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, q uis nostrud exercitationminim veniam, q uis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat...</p>
                                                        <Link to="/" class="read-more">READ MORE</Link>
                                                    </div>
                                                </div>

                                            </div>
                                            <div class="col-lg-4 col-md-6">
                                                <div class="post2">
                                                    <div class="img"><Link to="/"><img src={post1} alt="img" /></Link></div>
                                                    <div class="content">
                                                        <Link to="/">
                                                            <h5>LOREM IPSUM DOLOR SIT AMET CONSECTETUR</h5>
                                                        </Link>
                                                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, q uis nostrud exercitationminim veniam, q uis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat...</p>
                                                        <Link to="/" class="read-more">READ MORE</Link>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                        <div class="post3 ">
                                            <div class="row">
                                                <div class=" col-sm-4">
                                                    <div class="img">
                                                        <Link to="/"><img src={post2} alt="img" /></Link>
                                                    </div>
                                                </div>
                                                <div class="col-sm-8">
                                                    <div class="content">
                                                        <Link to="/">
                                                            <h5>LOREM IPSUM DOLOR SIT AMET CONSECTETUR</h5>
                                                        </Link>
                                                        <p>Lorem ipsum dolor sit amet, consectetur dipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. consectetur dipisicing elit, sed do eiusmod tempo consectetur Ut enim ad veniam...</p>
                                                        <Link to="/" class="read-more">READ MORE</Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="post3 ">
                                            <div class="row">
                                                <div class=" col-sm-4">
                                                    <div class="img">
                                                        <Link to="/"><img src={post2} alt="img" /></Link>
                                                    </div>
                                                </div>
                                                <div class="col-sm-8">
                                                    <div class="content">
                                                        <Link to="/">
                                                            <h5>LOREM IPSUM DOLOR SIT AMET CONSECTETUR</h5>
                                                        </Link>
                                                        <p>Lorem ipsum dolor sit amet, consectetur dipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. consectetur dipisicing elit, sed do eiusmod tempo consectetur Ut enim ad veniam...</p>
                                                        <Link to="/" class="read-more">READ MORE</Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="post3 ">
                                            <div class="row">
                                                <div class=" col-sm-4">
                                                    <div class="img">
                                                        <Link to="/"><img src={post2} alt="img" /></Link>
                                                    </div>
                                                </div>
                                                <div class="col-sm-8">
                                                    <div class="content">
                                                        <Link to="/">
                                                            <h5>LOREM IPSUM DOLOR SIT AMET CONSECTETUR</h5>
                                                        </Link>
                                                        <p>Lorem ipsum dolor sit amet, consectetur dipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. consectetur dipisicing elit, sed do eiusmod tempo consectetur Ut enim ad veniam...</p>
                                                        <Link to="/" class="read-more">READ MORE</Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="post3 mb-0">
                                            <div class="row">
                                                <div class=" col-sm-4">
                                                    <div class="img">
                                                        <Link to="/"><img src={post2} alt="img" /></Link>
                                                    </div>
                                                </div>
                                                <div class="col-sm-8">
                                                    <div class="content">
                                                        <Link to="/">
                                                            <h5>LOREM IPSUM DOLOR SIT AMET CONSECTETUR</h5>
                                                        </Link>
                                                        <p>Lorem ipsum dolor sit amet, consectetur dipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. consectetur dipisicing elit, sed do eiusmod tempo consectetur Ut enim ad veniam...</p>
                                                        <Link to="/" class="read-more">READ MORE</Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="seemore-btn-inner">
                                            <Link to="/" class="ld-more-btn">Load More</Link>
                                        </div>
                                    </div>







                                </div>
                            </div>
                            <div class="col-lg-3">
                                <div class="sidebar-widget-wrapper">


                                    <div class="sidebar-widget rank-widget fct-wd">
                                        <h4 class="heading-title">Quick Facts</h4>

                                        <div class="facts-info">
                                            <h5>Association</h5>
                                            <div class="inner-facts-texts">
                                                <img src={tiger} alt="" />
                                                <p>Bangladesh Cricket Board</p>
                                            </div>
                                            <div class="nk-name">
                                                <h5>Nickname</h5>
                                                <p>The Tigers</p>
                                            </div>
                                            <div class="captine">
                                                <h5>Captain</h5>
                                                <div class="cpt-list-items">
                                                    <p><span>Test</span>Mominul Haque</p>
                                                    <p><span>ODI</span>Mashrafe Bin Mortaza</p>
                                                    <p><span>T20</span>Mahmudullah</p>
                                                    <p><span>Coach</span>Russell Domingo</p>
                                                </div>
                                            </div>
                                            <div class="nk-name">
                                                <h5>History</h5>
                                                <p>Test Status 2020</p>
                                            </div>
                                            <div class="nk-name">
                                                <h5>Matches</h5>
                                                <div class="match-table">
                                                    <table class="table">
                                                        <thead>
                                                            <tr>
                                                                <th><span class="gp"></span></th>
                                                                <th>P</th>
                                                                <th>W</th>
                                                                <th>L</th>
                                                                <th>T</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td><span class="gp">Mark</span></td>
                                                                <td>117</td>
                                                                <td>13</td>
                                                                <td>88</td>
                                                                <td>16</td>
                                                            </tr>
                                                            <tr>
                                                                <td><span class="gp">Mark</span></td>
                                                                <td>373</td>
                                                                <td>125</td>
                                                                <td>241</td>
                                                                <td>0</td>
                                                            </tr>
                                                            <tr>
                                                                <td><span class="gp">Mark</span></td>
                                                                <td>94</td>
                                                                <td>30</td>
                                                                <td>62</td>
                                                                <td>0</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                    <div class="sidebar-widget trend-widget">
                                        <h4 class="heading-title">Trending Now</h4>

                                        <nav>
                                            <ul>
                                                <li>
                                                    <div class="img"><Link to="/"><img src={trending} alt="image" /></Link></div>
                                                    <div class="content"><Link to="/">
                                                        <p>Lorem Ipsum is simply dummy text to print ...</p>
                                                    </Link></div>
                                                </li>
                                                <li>
                                                    <div class="img"><Link to="/"><img src={trending} alt="image" /></Link></div>
                                                    <div class="content"><Link to="/">
                                                        <p>Lorem Ipsum is simply dummy text to print ...</p>
                                                    </Link></div>
                                                </li>
                                                <li>
                                                    <div class="img"><Link to="/"><img src={trending} alt="image" /></Link></div>
                                                    <div class="content"><Link to="/">
                                                        <p>Lorem Ipsum is simply dummy text to print ...</p>
                                                    </Link></div>
                                                </li>
                                                <li>
                                                    <div class="img"><Link to="/"><img src={trending} alt="image" /></Link></div>
                                                    <div class="content"><Link to="/">
                                                        <p>Lorem Ipsum is simply dummy text to print ...</p>
                                                    </Link></div>
                                                </li>
                                                <li>
                                                    <div class="img"><Link to="/"><img src={trending} alt="image" /></Link></div>
                                                    <div class="content"><Link to="/">
                                                        <p>Lorem Ipsum is simply dummy text to print ...</p>
                                                    </Link></div>
                                                </li>
                                            </ul>
                                        </nav>
                                        <Link to="/" class="ld-more-btn">Load More</Link>
                                    </div>

                                    <div class="sidebar-widget popular-widget">
                                        <h4 class="heading-title">Most Popular</h4>

                                        <div>
                                            <div class="nav nav-tabs" id="nav-tab" role="tablist">
                                                <Link tos="nav-item nav-link active" data-toggle="tab" href="#nav-7">Today</Link>
                                                <Link tos="nav-item nav-link" data-toggle="tab" href="#nav-8">Last 7 Days</Link>
                                                <Link tos="nav-item nav-link" data-toggle="tab" href="#nav-9">Last 30 Days</Link>
                                            </div>
                                        </div>
                                        <div class="tab-content" id="nav-tabContent">
                                            <div class="tab-pane fade show active" id="nav-7">
                                                <nav>
                                                    <ul>
                                                        <li>
                                                            <span>1</span>
                                                            <Link to="/">
                                                                <p>Lorem Ipsum is simply dummy ..</p>
                                                            </Link>
                                                        </li>
                                                        <li>
                                                            <span>2</span>
                                                            <Link to="/">
                                                                <p>Lorem int and typesetting int and typesetting int and typesetting int and typesetting...</p>
                                                            </Link>
                                                        </li>
                                                        <li>
                                                            <span>3</span>
                                                            <Link to="/">
                                                                <p>Lorem int and typesetting int and typesetting int and typesetting int and typesetting...</p>
                                                            </Link>
                                                        </li>
                                                        <li>
                                                            <span>4</span>
                                                            <Link to="/">
                                                                <p>Lorem int and typesetting int and typesetting int and typesetting int and typesetting...</p>
                                                            </Link>
                                                        </li>
                                                        <li>
                                                            <span>5</span>
                                                            <Link to="/">
                                                                <p>Lorem int and typesetting int and typesetting int and typesetting int and typesetting...</p>
                                                            </Link>
                                                        </li>
                                                    </ul>
                                                </nav>

                                            </div>
                                            <div class="tab-pane fade" id="nav-8">

                                            </div>
                                            <div class="tab-pane fade" id="nav-9">


                                            </div>
                                        </div>



                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}
export default Team